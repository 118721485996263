import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'gk-debug-responsive',
    templateUrl: './debug-responsive.component.html',
    styleUrls: ['./debug-responsive.component.scss'],
})
export class DebugResponsiveComponent implements OnInit {

    constructor() {
        // foo
    }

    ngOnInit(): void {
        // foo
    }

}
