import { Component, OnInit } from '@angular/core';
import { ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MatIconModule, MatDialog, MatDialogRef } from '@angular/material';

import { NGXLogger } from 'ngx-logger';

import { L10nService } from '../l10n/l10n.service';
import { AuthService } from '../auth/auth.service';
import { ProfileService, ProfileSingleton } from '../auth/profile.service';
import { AboutDialogComponent } from '../dialogs/about-dialog.component';

@Component({
    selector: 'gk-main',
    templateUrl: './url.component.html',
    styleUrls: ['./url.component.scss'],
    providers: [AuthService, L10nService],
})
export class UrlComponent implements OnInit {
    urlList={
        "abc":"http://localhost:4100/#/broadcast/screen_ba6ed290-5b27-4994-94ca-0b412d3e8577?t=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0eXBlIjoidXNlciIsImlkIjoidXNlcl8wMDAwMDAwMC0wMDAwLTQwMDAtYjAwMC0wMDAwMDAwMDAwMDAifQ.IvRKfAjhXQpMoQMc_cOuVMhNyIZGMzqYbZnZ5jRFuMg?i=preview:user_00000000-0000-4000-b000-000000000000"
    }
    url:string=""
    constructor(private router:Router,private route: ActivatedRoute){

    }
    ngOnInit(): void {
     const url=   this.urlList[this.route.snapshot.paramMap.get('key')]
     console.log(this.route.snapshot.params['key']);
     this.url=url;
      
    }

}