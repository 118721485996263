import { Component, OnInit, AfterViewInit, ChangeDetectorRef, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { MatDialog, MatDialogRef, MatButtonToggleGroup, MatButtonToggleChange, MatSnackBar } from '@angular/material';

import { TdDialogService } from '@covalent/core';

import { NGXLogger } from 'ngx-logger';

import {
    getMonth,
    startOfMonth,
    startOfWeek,
    startOfDay,
    endOfMonth,
    endOfWeek,
    endOfDay,
    addHours,
    addMinutes,
} from 'date-fns';
import * as RR from 'rrule';

import { CalendarEvent, CalendarEventTimesChangedEvent, CalendarDateFormatter, CalendarMomentDateFormatter, MOMENT } from 'angular-calendar';
import { MonthViewDay } from 'calendar-utils';

import * as moment from 'moment-timezone';

import { L10nService } from '../l10n/l10n.service';
import { IPlaylist } from '../models/playlist.model';
import { PlaylistService } from '../services';
import { IChannel } from '../models/channel.model';
import { ChannelService } from '../services';
import { IScheduledContent } from '../models/scheduled-content.model';
import { ScheduledContentService } from '../services';
import { WhichRecurringEventsDialogComponent } from '../dialogs/which-recurring-events-dialog.component';
import { ScheduleDialogComponent } from '../dialogs/schedule/schedule-dialog.component';
import { IDataArray, IRemoteReference } from '../models/data-array.model';
import { CustomCalMomentDateFormatter } from './custom-date.formatter';
import { ColorsService, IColorInfo } from '../services/colors.service';

@Component({
    selector: 'gk-schedule',
    // changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './schedule.component.html',
    styleUrls: [
        './schedule.component.scss',
    ],
    viewProviders: [PlaylistService, ChannelService],
    providers: [
        L10nService,
        {
            provide: MOMENT,
            useValue: moment,
        },
        {
            provide: CalendarDateFormatter,
            useClass: CustomCalMomentDateFormatter,
        },
    ],
})
export class ScheduleComponent implements OnInit, AfterViewInit {

    private _scheduledContent: IScheduledContent[] = [];
    private lastStartDate: Date;
    private lastEndDate: Date;
    private lastChannelID: String;
    @ViewChild('toggleGroup') toggleGroup: MatButtonToggleGroup;

    // scratch space for assembling and associating various data

    playlists: IPlaylist[] = [];
    channels: IChannel[] = [];
    channelsLoaded: boolean = false;
    playlistsLoaded: boolean = false;
    scheduledContentLoaded: boolean = false;

    // the type of calendar shown.
    calendarType: string = 'day'; // or week, or month

    // list of events shown on the calendar (in calendar's format)
    calendarEvents: CalendarEvent[] = [];

    // date on the calendar
    viewDate: Date = new Date();

    selectedIndex: number = 0;

    refresh: Subject<any> = new Subject();

    defaultState: string = '';

    get scheduledContent(): IScheduledContent[] {
        return this._scheduledContent;
    }
    set scheduledContent(content: IScheduledContent[]) {
        this._scheduledContent = content;
    }

    constructor(
        private channelService: ChannelService,
        private playlistService: PlaylistService,
        private scheduledContentService: ScheduledContentService,
        private cdr: ChangeDetectorRef,
        private dialog: MatDialog,
        private snackBarService: MatSnackBar,
        private dialogService: TdDialogService,
        private logger: NGXLogger,
        private colorService: ColorsService,
    ) { }

    ngOnInit(): void {
        this.loadChannels();
        this.loadPlaylists();
        this.loadScheduledContent();
    }

    ngAfterViewInit(): void {
        // this.toggleGroup.value = 'day';
    }

    loadChannels(): void {
        this.channelsLoaded = false;
        this.channelService.getAll().subscribe(
            (dataArray: IDataArray<IChannel>) => {
                this.logger.log('loading channels', dataArray.data);
                this.channels = dataArray.data;
                this.channelsLoaded = true;
            },
            (err: any) => {
                this.logger.error(err);
            },
            () => {
                this.logger.log('loaded channels', this.channels);
                this.recalculateCalendarEvents();
            },
        );
    }

    loadPlaylists(): void {
        this.playlistsLoaded = false;
        this.playlistService.getAll().subscribe(
            (dataArray: IDataArray<IPlaylist>) => {
                this.playlists = dataArray.data;
                this.playlistsLoaded = true;
            },
            (err: any) => {
                this.logger.error(err);
            },
            () => {
                this.logger.log('loaded playlists', this.playlists);
                this.recalculateCalendarEvents();
            },
        );
    }

    loadScheduledContent(): void {
        this.playlistsLoaded = false;
        this.scheduledContentService.getAll().subscribe(
            (dataArray: IDataArray<IScheduledContent>) => {
                this.scheduledContent = dataArray.data;
                this.scheduledContentLoaded = true;
            },
            (err: any) => {
                this.logger.error(err);
            },
            () => {
                this.logger.log('loaded schedules', this.scheduledContent);
                this.recalculateCalendarEvents();
            },
        );
    }

    onDefaultDrop($event: any): void {
        this.logger.log('onDefaultDrop()', $event);
        // just dragged and dropped a playlist onto a channel
        let currentChannel: IChannel = this.channels[this.selectedIndex];
        currentChannel.defaultPlaylistId = $event.dropData.event.meta.id;
        currentChannel.defaultPlaylist = this.playlists.find((value: IPlaylist) => {
            return value.id === currentChannel.defaultPlaylistId;
        });
        this.channelService.update(currentChannel).subscribe(
            (success: void) => {
                this.logger.log('updated channel', currentChannel);
                this.cdr.detectChanges();
            },
            (err: any) => {
                this.logger.error(err);
            },
        );
    }

    onDefaultDelete($event: any): void {
        this.logger.log('onDefaultDelete()', $event);
        let currentChannel: IChannel = this.channels[this.selectedIndex];
        currentChannel.defaultPlaylistId = undefined;
        currentChannel.defaultPlaylist = undefined;
        this.channelService.update(currentChannel).subscribe(
            (success: void) => {
                this.logger.log('updated channel', currentChannel);
                this.cdr.detectChanges();
            },
            (err: any) => {
                this.logger.error(err);
            },
        );
    }

    onAccountChangeEvent($event: any): void {
        this.repaint();
    }

    onChannelChanged(): void {
        this.recalculateCalendarEvents();
    }

    // calendar radio buttons
    onCalendarTypeChange($event: MatButtonToggleChange): void {
        this.calendarType = $event.value;
        this.recalculateCalendarEvents();
    }

    // date back button
    onRegress(): void {
        const caltype: string = this.calendarType;
        if (caltype === 'day') {
            this.viewDate = moment(this.viewDate).subtract(1, 'day').toDate();
        } else if (caltype === 'week') {
            this.viewDate = moment(this.viewDate).subtract(1, 'week').toDate();
        } else if (caltype === 'month') {
            this.viewDate = moment(this.viewDate).subtract(1, 'month').toDate();
        }
        this.recalculateCalendarEvents();
    }

    // date forward button
    onAdvance(): void {
        const caltype: string = this.calendarType;
        if (caltype === 'day') {
            this.viewDate = moment(this.viewDate).add(1, 'day').toDate();
        } else if (caltype === 'week') {
            this.viewDate = moment(this.viewDate).add(1, 'week').toDate();
        } else if (caltype === 'month') {
            this.viewDate = moment(this.viewDate).add(1, 'month').toDate();
        }
        this.recalculateCalendarEvents();
    }

    onToday(): void {
        this.viewDate = new Date();
        this.recalculateCalendarEvents();
    }

    onDayClicked($event: { day: MonthViewDay }): void {
        const date: Date = $event.day.date;
        if (!!date) {
            this.toggleGroup.value = 'day'; // seems redundant
            this.calendarType = 'day';
            this.viewDate = $event.day.date;
        }
    }

    onEventClicked($event: { event: CalendarEvent<IScheduledContent> }): void {
        this.logger.log('onEventClicked', $event.event);
        if (!!$event.event.meta.playlistId) {
            this.editValidatedEvent($event.event);
        } else {
            this.verifyDeleteEvent($event.event);
        }
    }

    editValidatedEvent(event: CalendarEvent<IScheduledContent>): void {

        let dialogRef: MatDialogRef<ScheduleDialogComponent> = this.dialog.open(ScheduleDialogComponent);
        dialogRef.componentInstance.playlist = this.playlists.find((item: IPlaylist) => {
            return item.id === event.meta.playlistId;
        });
        dialogRef.componentInstance.channel = this.channels[this.selectedIndex];
        dialogRef.componentInstance.scheduledContent = event.meta;
        dialogRef.componentInstance.isCreateNewSchedule = false;
        dialogRef.afterClosed().subscribe((editedEvent: any) => {
            if (!editedEvent) { // cancel
                return;
            } else if (typeof editedEvent === 'string' && editedEvent === 'delete') {
                this.verifyDeleteEvent(event);
            } else {
                this.verifyEditsOnEvent(event, editedEvent);
            }
            this.logger.log('result', editedEvent);
        });
    }

    verifyDeleteEvent(event: CalendarEvent<IScheduledContent>): void {
        // if non-repeating show a simple confirmation
        if (!event.meta.recurranceRule || event.meta.recurranceRule === '') {
            this.dialogService
                .openConfirm({ title: 'Confirmation Required', message: 'Are you sure you want to delete this event?' })
                .afterClosed().subscribe((confirm: boolean) => {
                    if (confirm) {
                        this.deleteScheduledContentByID(event.meta.id);
                    }
                });
        } else {
            // if repeating, ask if for this instance, all subsequent, or all
            let dialogRef: MatDialogRef<WhichRecurringEventsDialogComponent> = this.dialog.open(WhichRecurringEventsDialogComponent);
            dialogRef.componentInstance.isEditing = false;
            dialogRef.componentInstance.setTargetDate(event.start);
            const rrule: RR.RRule = RR.RRule.fromString(event.meta.recurranceRule);
            dialogRef.componentInstance.setStartingDate(rrule.options.dtstart);
            dialogRef.afterClosed().subscribe((result: string) => {
                if (!result || result === '' || result === 'cancel') {
                    return;
                } else if (result === 'one') {
                    this.deleteDateFromScheduledContent(event, false);
                } else if (result === 'following') {
                    this.deleteDateFromScheduledContent(event, true);
                } else if (result === 'all') {
                    this.deleteScheduledContentByID(event.meta.id);
                } else {
                    this.logger.error('Unexpected value ', result);
                }
            });
        }
    }

    verifyEditsOnEvent(event: CalendarEvent<IScheduledContent>, editedEvent: IScheduledContent): void {
        // if non-repeating, just make the change.
        if (!event.meta.recurranceRule || event.meta.recurranceRule === '') {
            this.scheduledContentService.update(editedEvent).subscribe(
                (data: any) => {
                    const index: number = this._scheduledContent.findIndex((schedule: IScheduledContent) => {
                        return schedule.id === event.meta.id;
                    });
                    this._scheduledContent[index] = editedEvent;
                    this.recalculateCalendarEvents(true);
                },
                (error: Error) => {
                    this.dialogService.openAlert({ message: 'There was an error=' + error });
                },
            );
        } else {
            // if repeating, ask if for this instance, all subsequent, or all
            let dialogRef: MatDialogRef<WhichRecurringEventsDialogComponent> = this.dialog.open(WhichRecurringEventsDialogComponent);
            dialogRef.componentInstance.isEditing = false;
            dialogRef.componentInstance.setTargetDate(event.start);
            const rrule: RR.RRule = RR.RRule.fromString(event.meta.recurranceRule);
            dialogRef.componentInstance.setStartingDate(rrule.options.dtstart);
            dialogRef.afterClosed().subscribe((result: string) => {
                if (!result || result === '' || result === 'cancel') {
                    return;
                } else if (result === 'one') {
                    this.modifyDateFromScheduledContent(event, editedEvent, false);
                } else if (result === 'following') {
                    this.modifyDateFromScheduledContent(event, editedEvent, true);
                } else if (result === 'all') {
                    this.modifyScheduledContentByID(event.meta.id, editedEvent);
                } else {
                    this.logger.error('Unexpected value ', result);
                }
            });
        }
    }

    onDateChanged($event: any): void {
        this.recalculateCalendarEvents();
    }

    onEventTimesChanged($event: any): void {
        let event: any = $event.event;
        let newStart: Date = $event.newStart;
        let newEnd: Date = $event.newEnd;
        // { event, newStart, newEnd }: CalendarEventTimesChangedEvent
        this.logger.log('onEventTimesChanged', event);
        if (newEnd) {
            this.handleEventMove(event, newStart, newEnd);
        } else {
            this.handleEventDrop(event, newStart);
        }
        this.refresh.next();
    }

    private handleEventMove(event: CalendarEvent<any>, newStart: Date, newEnd: Date): void {
        let idx: number = this.calendarEvents.findIndex((item: CalendarEvent<any>): boolean => {
            return (item.start === event.start) &&
                (item.end === event.end) &&
                (item.meta.id === event.meta.id);
        });
        if (idx >= 0) {
            this.calendarEvents[idx].start = newStart;
            this.calendarEvents[idx].end = newEnd;
        }
    }

    private handleEventDrop(event: CalendarEvent<any>, newStart: Date): void {
        // 1. Create a local scheduledControl instance
        let playlist: IPlaylist = <IPlaylist>event.meta;
        let scheduledContent: IScheduledContent = <IScheduledContent>{
            id: undefined,
            channelId: this.channels[this.selectedIndex].id,
            playlistId: playlist.id,
            colorName: 'yellow', // default starting color
            startDatetime: newStart.toISOString(),
            duration: moment.duration(playlist.durationSecs, 'seconds').toISOString(),
            recurranceRule: undefined, // iCal
        };

        // 2. Throw up a scheduleDialogComponent to fine-tune.
        let dialogRef: MatDialogRef<ScheduleDialogComponent> = this.dialog.open(ScheduleDialogComponent);
        dialogRef.componentInstance.playlist = playlist;
        dialogRef.componentInstance.channel = this.channels[this.selectedIndex];
        dialogRef.componentInstance.scheduledContent = scheduledContent;
        dialogRef.componentInstance.isCreateNewSchedule = true;

        // 3. Iff "save", store new scheduleDialogComponent & refresh
        dialogRef.afterClosed().subscribe((newlyScheduled: IScheduledContent) => {
            this.logger.log('result', newlyScheduled);
            this.logger.log('length', this.scheduledContent.length);
            if (!newlyScheduled) { return; }
            this.scheduledContentService.create(newlyScheduled).subscribe(
                (success: IRemoteReference<IScheduledContent>) => {
                    newlyScheduled.id = success.id;
                    this.scheduledContent.push(newlyScheduled);
                    this.recalculateCalendarEvents();
                },
                // (error: any) => { }
            );
        });
    }

    private scheduledContent2CalendarEvent(scheduledContent: IScheduledContent): CalendarEvent<IScheduledContent> {
        const playlist: IPlaylist = this.playlists.find((item: IPlaylist) => item.id === scheduledContent.playlistId);
        let title: string;
        let color: IColorInfo;
        if (!!playlist) {
            title = playlist.title;
            color = this.colorService.colorByName(scheduledContent.colorName);
        } else {
            title = 'Playlist Deleted';
            color = this.colorService.colorByName('grey');
        }
        const start: Date = moment(scheduledContent.startDatetime).toDate();
        const duration: moment.Duration = moment.duration(scheduledContent.duration);
        const result: CalendarEvent<IScheduledContent> = {
            title: title,
            color: { primary: color.dark, secondary: color.light },
            start: start,
            end: moment(start).add(duration).toDate(),
            resizable: {
                beforeStart: false, // this allows you to configure the sides the event is resizable from
                afterEnd: false,
            },
            meta: scheduledContent,
        };
        return result;
    }

    private editEvent(): void {
        // foo
    }

    private recalculateCalendarEvents(force?: boolean): void {
        // don't recalculate unless everything is loaded.
        if (!this.playlistsLoaded || !this.channelsLoaded || this.channels.length === 0 || !this.scheduledContentLoaded) {
            return;
        }

        // update this.events with relevant scheduled events
        let newEvents: CalendarEvent<IScheduledContent>[] = [];

        const startOfPeriod: any = {
            month: startOfMonth,
            week: startOfWeek,
            day: startOfDay,
        };

        const endOfPeriod: any = {
            month: endOfMonth,
            week: endOfWeek,
            day: endOfDay,
        };

        const startDate: Date = moment(startOfPeriod[this.calendarType](this.viewDate)).toDate();
        const endDate: Date = moment(endOfPeriod[this.calendarType](this.viewDate)).toDate();
        // this.logger.log('Render all events from ', startDate.toLocaleDateString(), ' to ', endDate.toLocaleDateString());
        this.scheduledContent.forEach((scheduledContent: IScheduledContent) => {
            // this.logger.debug('Scheduled Item ', scheduledContent.id, ' for channel ', scheduledContent.channelId);
            if (scheduledContent.channelId === this.channels[this.selectedIndex].id) {
                if (scheduledContent.recurranceRule) {
                    const rrule: RR.RRule = RR.RRule.fromString(scheduledContent.recurranceRule);
                    // this.logger.debug('recurrance rule:', rrule.toText());
                    rrule.between(startDate, endDate).forEach((date: Date) => {
                        // build a new event with a particular recurrance date.
                        // this.logger.debug('concrete date ', date.toLocaleDateString());
                        const singleInstance: IScheduledContent = Object.assign({}, scheduledContent, { startDatetime: date.toISOString() });
                        const calendarEvent: CalendarEvent<IScheduledContent> = this.scheduledContent2CalendarEvent(singleInstance);
                        newEvents.push(calendarEvent);
                    });
                } else {
                    const singleInstance: IScheduledContent = Object.assign({}, scheduledContent);
                    const calendarEvent: CalendarEvent<IScheduledContent> = this.scheduledContent2CalendarEvent(singleInstance);
                    newEvents.push(calendarEvent);
                }
            }
        });

        // CAUTION: angular-calendar can trigger this method three-times for a single refresh
        // something has to be different to justify changing this.calendarEvents
        if (force ||
            !this.lastStartDate || this.lastStartDate.valueOf() !== startDate.valueOf() ||
            !this.lastEndDate || this.lastEndDate.valueOf() !== endDate.valueOf() ||
            !this.lastChannelID || this.lastChannelID !== this.channels[this.selectedIndex].id ||
            this.calendarEvents.length !== newEvents.length) {
            // this.logger.log('Render all events from ', startDate.toLocaleDateString(), ' to ', endDate.toLocaleDateString());
            this.lastStartDate = startDate;
            this.lastEndDate = endDate;
            this.lastChannelID = this.channels[this.selectedIndex].id;
            this.calendarEvents = newEvents; // this triggers a re-rendering
            // this.cdr.markForCheck();
            this.refresh.next();
        }

    }

    private deleteScheduledContentByID(id: string): void {
        this.scheduledContentService.delete(id).subscribe(
            (data: void) => {
                this._scheduledContent = this._scheduledContent.filter((schedule: IScheduledContent) => {
                    return schedule.id !== id;
                });
                this.recalculateCalendarEvents();
                this.snackBarService.open('Event deleted', 'Ok', { duration: 3000 });
            },
            (error: Error) => {
                this.dialogService.openAlert({ message: 'There was an error=' + error });
            },
        );
    }

    private modifyScheduledContentByID(id: string, editedEvent: IScheduledContent): void {
        editedEvent.id = id;
        this.scheduledContentService.update(editedEvent).subscribe(
            (data: void) => {
                const index: number = this._scheduledContent.findIndex((schedule: IScheduledContent) => {
                    return schedule.id === id;
                });
                this._scheduledContent[index] = editedEvent;
                this.recalculateCalendarEvents(true);
                this.snackBarService.open('Event modified', 'Ok', { duration: 3000 });
            },
            (error: Error) => {
                this.dialogService.openAlert({ message: 'There was an error=' + error });
            },
        );
    }

    private deleteDateFromScheduledContent(event: CalendarEvent<IScheduledContent>, deleteFollowing: boolean): void {
        const srcRRule: RR.RRule = RR.RRule.fromString(event.meta.recurranceRule);
        srcRRule.options.byhour = [];   // workaround bug in rrule?
        srcRRule.options.byminute = []; // workaround bug in rrule?
        srcRRule.options.bysecond = []; // workaround bug in rrule?
        // tslint:disable-next-line no-null-keyword
        srcRRule.options.wkst = null;   // workaround bug in rrule?
        const beforeDate: Date = srcRRule.before(event.start);
        const afterDate: Date = srcRRule.after(event.start);
        let before: IScheduledContent = Object.assign({}, event.meta);
        let after: IScheduledContent = Object.assign({}, event.meta);
        // calculate the RRule for before the deletion
        let beforeCount: number = 0;
        if (beforeDate) { // if there is at least one date before the deletion
            let beforeOptions: RR.Options = Object.assign({}, srcRRule.options);
            beforeOptions.until = beforeDate; // the before rule runs until the beforeDate
            let beforeRRule: RR.RRule = new RR.RRule(beforeOptions);
            if (srcRRule.options.count) {
                beforeCount = srcRRule.between(srcRRule.options.dtstart, event.start, true).length; // count number of dates in before rule
                beforeRRule.options.count = beforeCount;
            }
            // modify
            before.recurranceRule = beforeRRule.toString();
            this.scheduledContentService.update(before).subscribe(
                (data: any) => {
                    const index: number = this._scheduledContent.findIndex((schedule: IScheduledContent) => {
                        return schedule.id === event.meta.id;
                    });
                    this._scheduledContent[index] = before;
                    this.recalculateCalendarEvents();
                },
                (error: Error) => {
                    this.dialogService.openAlert({ message: 'There was an error=' + error });
                },
            );
        } else { // deleted the first event in the sequence
            this.scheduledContentService.delete(event.meta.id).subscribe(
                (data: void) => {
                    this._scheduledContent = this._scheduledContent.filter((schedule: IScheduledContent) => {
                        return schedule.id !== event.meta.id;
                    });
                    this.recalculateCalendarEvents();
                },
                (error: Error) => {
                    this.dialogService.openAlert({ message: 'There was an error=' + error });
                },
            );
        }
        if (afterDate && !deleteFollowing) {
            // create
            let afterOptions: RR.Options = Object.assign({}, srcRRule.options);
            afterOptions.dtstart = afterDate;
            if (srcRRule.options.count) {
                // tweek the counts
                afterOptions.count = srcRRule.options.count - beforeCount - 1;
            }
            let afterRRule: RR.RRule = new RR.RRule(afterOptions);
            after.recurranceRule = afterRRule.toString();
            after.startDatetime = moment(afterDate).format('YYYY-MM-DD[T]HH:mm:ss');
            after.id = undefined; // before already has this id
            this.scheduledContentService.create(after).subscribe(
                (data: IRemoteReference<IScheduledContent>) => {
                    after.id = data.id;
                    this._scheduledContent.push(after);
                    this.recalculateCalendarEvents();
                },
                (error: Error) => {
                    this.dialogService.openAlert({ message: 'There was an error=' + error });
                },
            );
        }
    }

    private modifyDateFromScheduledContent(event: CalendarEvent<IScheduledContent>, editedEvent: IScheduledContent, modifyFollowing: boolean): void {
        // 1 delete the day, or range following.
        this.deleteDateFromScheduledContent(event, modifyFollowing);

        // 2. add the new date, or range
        editedEvent.id = '';
        if (!!editedEvent.recurranceRule && editedEvent.recurranceRule !== '') {
            let rrule: RR.RRule = RR.RRule.fromString(editedEvent.recurranceRule);
            rrule.options.byhour = [];   // workaround bug in rrule?
            rrule.options.byminute = []; // workaround bug in rrule?
            rrule.options.bysecond = []; // workaround bug in rrule?
            // tslint:disable-next-line no-null-keyword
            rrule.options.wkst = null;   // workaround bug in rrule?
            let afterOptions: RR.Options = Object.assign({}, rrule.options);
            afterOptions.dtstart = event.start;
            let afterRRule: RR.RRule = new RR.RRule(afterOptions);
            editedEvent.recurranceRule = afterRRule.toString();
        }
        this.scheduledContentService.create(editedEvent).subscribe(
            (data: IRemoteReference<IScheduledContent>) => {
                editedEvent.id = data.id;
                this._scheduledContent.push(editedEvent);
                this.recalculateCalendarEvents(true);
            },
            (error: Error) => {
                this.dialogService.openAlert({ message: 'There was an error=' + error });
            },
        );
    }

    private repaint(): void {
        this.channelsLoaded = false;
        this.playlistsLoaded = false;
        this.scheduledContentLoaded = false;
        this.selectedIndex = 0;
        this.channels = [];
        this.playlists = [];
        this.scheduledContent = [];
        this.loadChannels();
        this.loadPlaylists();
        this.loadScheduledContent();
    }
}
