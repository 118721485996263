import { Component, OnInit, Injectable, ViewChild, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router, CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';

import { MatSnackBar, MatDialog, MatDialogRef } from '@angular/material';

import { TdMediaService, TdLoadingService, LoadingType, LoadingMode } from '@covalent/core';

import { NGXLogger } from 'ngx-logger';

import { IPlaylist, IPlaylistItem, playlistItemMediaFactory } from '../../models/playlist.model';
import { IRemoteReference } from '../../models/data-array.model';
import { PlaylistService } from '../../services';
import { PlaylistItemEditorComponent } from './playlistitem.editor.component';
import { L10nService } from '../../l10n/l10n.service';
import { LeaveWithoutSaveDialogComponent } from '../../dialogs/leave-without-save-dialog.component';

@Injectable()
export class CanDeactivatePlaylistForm implements CanDeactivate<PlaylistFormComponent> {
    constructor(private dialog: MatDialog) { }

    canDeactivate(
        component: PlaylistFormComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot,
    ): Observable<boolean> | Promise<boolean> | boolean {

        if (component.dirty) {
            let dialogRef: MatDialogRef<LeaveWithoutSaveDialogComponent> = this.dialog.open(LeaveWithoutSaveDialogComponent);
            return dialogRef.afterClosed();
        } else {
            return true;
        }
    }
}

@Component({
    selector: 'gk-playlist-form',
    templateUrl: './playlist.form.component.html',
    styleUrls: ['./playlist.form.component.scss'],
    viewProviders: [PlaylistService],
    providers: [L10nService],
})

export class PlaylistFormComponent implements OnInit, AfterViewInit {

    private _isSaving: boolean = false; // used to short-circuit leave-without-save

    private readonly _spinnerName: string = 'saving';

    @ViewChild('myForm') myForm: NgForm;
    @ViewChildren('playlistItemEditors') playlistItemEditors: QueryList<PlaylistItemEditorComponent>;
   
    selectedLayout: string = 'Layout1';
    layouts: string[] = ['Layout1', 'Layout2', 'Layout3'];
    id: string;
    title: string;
    description: string;
    thumbnailUrl: string;
    durationSecs: number;
    playlistItems: IPlaylistItem[] = [];
    playlistChanged: boolean = false;

    selectedIndex: number = 0;

    imgZone : number = 1;

    action: string;
    cardTitle: string;

    // used for dnd tests
    array1: string[] = ['hi', 'there', 'bub'];
    array2: string[] = [];
    zone1:boolean =true
    zone2:boolean =false
    zone3:boolean =false
    dataLoaded:boolean =false


    transferData: Object = { id: 1, msg: 'Hello' };

    constructor(
        private playlistService: PlaylistService,
        private route: ActivatedRoute,
        private router: Router,
        public media: TdMediaService,
        private loadingService: TdLoadingService,
        private logger: NGXLogger,
    ) {
        this.loadingService.create({
            name: this._spinnerName,
            type: LoadingType.Circular,
            mode: LoadingMode.Indeterminate,
            color: 'accent',
        });
    }

    get dirty(): boolean {
        return ((this.myForm.touched && this.myForm.dirty) || this.anyPlaylistItemDirty || this.playlistChanged) && !this._isSaving;
    }

    convert(s: string): string {
        return '@' + s + '@';
    }

    goBack(): void {
        this.loadingService.resolve(this._spinnerName);
        this.router.navigate(['/playlists']);
    }

    ngOnInit(): void {
        this.route.url.subscribe((url: any) => {
            this.logger.log('PlaylistFormComponent.ngOnInit: url=', url);
            this.action = (url.length > 1 ? url[1].path : 'add');
            if (this.action === 'add') {
                this.cardTitle = 'PlaylistEditor.CardTitleCreate';
                this.dataLoaded = true;
            } else {
                this.loadData();
                this.cardTitle = 'PlaylistEditor.CardTitleModify';
            }
        });
    }

    layoutChange(){
        console.log("changed called");
        // this.playlistItems =[]
    }
    ngAfterViewInit(): void {
        // broadcast to all listener observables when loading the page
        this.media.broadcast();
    }

    convertItemToPlaylistItem(item: any, types: any[]): IPlaylistItem {
       const playListItem = playlistItemMediaFactory(item);
       console.log(playListItem.height)
       if(playListItem.height==1080){
        this.zone1 = true
        this.zone3 = false
       }
      else  if(playListItem.height==540){

        this.zone3 = true
        console.log(  this.zone3)

        this.zone1 = false
       }
       return playListItem
    }

    get anyPlaylistItemDirty(): boolean {
        return this.playlistItemEditors.reduce((accumulator: boolean, item: PlaylistItemEditorComponent) => {
            return accumulator || item.dirty;
        }, false);
    }

    onDragModify($event: any): void {
        this.playlistChanged = true;
    }

    onDropSuccess($event: any): void {
        this.playlistChanged = true;
        console.log("dropped")
        this.logger.debug('onDropSuccess ' + this.playlistItems.map((element: IPlaylistItem) => (
            element.item ? element.item.title : element.itemId
        )));
    }

    onSortSuccess($event: any): void {
        this.playlistChanged = true;
        this.logger.debug('onSortSuccess ' + this.playlistItems.map((element: IPlaylistItem) => (
            element.item ? element.item.title : element.itemId
        )));
    }

    save(): void {
        console.log(this.playlistItems)
        this.logger.log('name=', this.title);
        this._isSaving = true;
        this.loadingService.register(this._spinnerName);

        if (!this.playlistItems) {
            this.playlistItems = [];
        }

        const len: number = this.playlistItems.length;
        let cumulativeDuration: number = 0;
        for (let i: number = 0; i < len; ++i) {
            this.playlistItems[i].position = i; // set the position
            cumulativeDuration += this.playlistItems[i].durationSecs; // calculate duration
        }

        const playlist: IPlaylist = <IPlaylist>{
            id: this.id,
            title: this.title,
            description: this.description,
            thumbnailUrl: this.thumbnailUrl,
            durationSecs: cumulativeDuration,
            items: this.playlistItems,
        };

        this.logger.log('save button--' + this.action, playlist);
        // this.logger.log('save button--' + this.action, JSON.stringify(playlist));
        if (this.action === 'add') {
            this.playlistService.create(playlist).subscribe(
                (data: IRemoteReference<IPlaylist>) => this.goBack(),
                (err: any) => this.reportSaveError(err),
            );
        } else {
            this.playlistService.update(playlist).subscribe(
                (data: void) => this.goBack(),
                (err: any) => this.reportSaveError(err),
            );
        }
    }

    private reportSaveError(err: any): void {
        this.logger.error('Error: PlaylistEditor', err);
        this.loadingService.resolve(this._spinnerName);
    }

    deleteItem(i:number): void {
        this.playlistChanged = true;

        this.playlistItems.splice(i, 1);
        console.log(this.playlistItems)
    }

    private loadData(): void {
        this.route.params.subscribe(
            (params: { id: string }) => {
                this.logger.log('PlaylistFormComponent.ngOnInit: params=', params);
                let playlistID: string = params.id;
                this.playlistService.getById(playlistID).subscribe(
                    (playlist: IPlaylist) => {
                        this.logger.log('service returned=', playlist);
                        this.id = playlist.id;
                        console.log(this.id)
                        if(this.id == 'playlist_ff06af7f-b5a7-4d3d-a8f3-bc1e34c27bf8'){
                            this.selectedLayout='Layout1';
                        }
                        else if(this.id == 'playlist_80105f09-0c5c-4459-baac-730e8cede83f'){
                            this.selectedLayout='Layout2';
                        }
                        console.log(this.selectedLayout)
                        this.dataLoaded = true;
                        this.title = playlist.title;
                        this.description = playlist.description;
                        this.durationSecs = playlist.durationSecs;
                        this.playlistItems = playlist.items;
                        this.logger.log('playlistItems', this.playlistItems);
                    },
                    (err: any) => {
                        this.logger.error('Error: PlaylistService ', err.msg);
                        this.goBack();
                    },
                );
            },
            (err: any) => {
                this.logger.error('Error: PlaylistEditor params ', err.msg);
                this.goBack();
            },
        );
    }
}
