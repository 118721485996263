import { Component, AfterViewInit } from '@angular/core';

import { Title } from '@angular/platform-browser';

import { TdLoadingService, ITdDataTableColumn } from '@covalent/core';

import { L10nService } from '../l10n/l10n.service';

import { UserService } from '../services';

import { multi, impressions_per_second, campaigns_by_age, age_demographics, gender_demographics } from './data';

import { palettes, ColorUtils } from '../utils/brewer.palettes';

export class PaletteOpts {
    static readonly QUALITATIVE: string = 'qualitative';
    static readonly SEQUENTIAL: string = 'sequential';
    static readonly ADDULATE: string = 'addulate';
}

@Component({
    selector: 'gk-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    viewProviders: [UserService, L10nService],
})
export class DashboardComponent implements AfterViewInit {

    readonly PALETTE_FAMILY: string = PaletteOpts.ADDULATE;

    revenue: any = {
        week_to_date: 4783,
        week_delta: 238,
        month_to_date: 12480,
        month_delta: -23,
        year_to_date: 69040,
        year_delta: 4000,
    };

    impressionsPerSecond: any;
    averageCpm: any;
    campaignsByAge: any;
    ageDemographics: any;
    genderDemographics: any;

    items: any[];
    users: any[];
    products: any[];
    alerts: any[];

    // Chart
    single: any[];
    multi: any[];

    view: any[] = [700, 400];

    // options
    showXAxis: boolean = true;
    showYAxis: boolean = true;
    gradient: boolean = false;
    showLegend: boolean = false;
    showXAxisLabel: boolean = true;
    xAxisLabel: string = '';
    showYAxisLabel: boolean = true;
    yAxisLabel: string = 'Sales';

    colorScheme: any = {
        domain: palettes[PaletteOpts.SEQUENTIAL][this.PALETTE_FAMILY]['5'],
    };

    // line, area
    autoScale: boolean = true;

    constructor(private _titleService: Title,
        /*
        private _itemsService: ItemsService,
        private _usersService: UserService,
        private _alertsService: AlertsService,
        private _productsService: ProductsService,
        */
        private _loadingService: TdLoadingService) {
        // Chart
        this.multi = multi.map((group: any) => {
            group.series = group.series.map((dataItem: any) => {
                dataItem.name = new Date(dataItem.name);
                return dataItem;
            });
            return group;
        });

        this.impressionsPerSecond = {
            results: impressions_per_second,
            showXAxis: true,
            showYAxis: true,
            gradient: true,
            colorScheme: { domain: palettes[PaletteOpts.QUALITATIVE][this.PALETTE_FAMILY]['3'] },
            showLegend: false,
            showXAxisLabel: false,
            xAxisLabel: 'iteration',
            showYAxisLabel: false,
            yAxisLabel: 'foo',
        };

        let columns: ITdDataTableColumn[] = [
            { name: 'span', label: 'Span', numeric: false },
            { name: 'cpm', label: 'CPM', numeric: true },
            { name: 'delta', label: 'Trend', numeric: true },
        ];
        this.averageCpm = {
            data: [
                { span: 'Dashboard.AvgCPM.LastHour', cpm: 2.34, delta: -0.10 },
                { span: 'Dashboard.AvgCPM.LastDay', cpm: 3.42, delta: 0.2 },
                { span: 'Dashboard.AvgCPM.LastWeek', cpm: 2.67, delta: 0 },
                { span: 'Dashboard.AvgCPM.LastQuarter', cpm: 2.75, delta: 0.31 },
            ],
            columns: columns,
        };

        this.campaignsByAge = {
            results: campaigns_by_age,
            showXAxis: true,
            showYAxis: true,
            gradient: false,
            colorScheme: { domain: palettes[PaletteOpts.SEQUENTIAL][this.PALETTE_FAMILY]['7'] },
            showLegend: true,
            showXAxisLabel: false,
            xAxisLabel: 'iteration',
            showYAxisLabel: false,
            yAxisLabel: 'foo',
        };

        this.genderDemographics = {
            results: gender_demographics,
            showXAxis: true,
            showYAxis: true,
            gradient: false,
            // tslint:disable-next-line:no-string-literal
            colorScheme: { domain: palettes[PaletteOpts.QUALITATIVE][this.PALETTE_FAMILY]['3'] },
            showLegend: true,
            showLabels: true,
            showXAxisLabel: false,
            xAxisLabel: 'iteration',
            showYAxisLabel: false,
            yAxisLabel: 'foo',
        };

        this.ageDemographics = {
            results: age_demographics,
            showXAxis: true,
            showYAxis: true,
            gradient: false,
            colorScheme: { domain: palettes[PaletteOpts.QUALITATIVE][this.PALETTE_FAMILY]['7'] },
            showLegend: true,
            showLabels: true,
            showXAxisLabel: false,
            xAxisLabel: 'iteration',
            showYAxisLabel: false,
            yAxisLabel: 'foo',
        };
    }

    ngAfterViewInit(): void {
        this._titleService.setTitle('ShotPro Console');
    }
}
