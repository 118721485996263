import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'gk-ad-campaigns',
    templateUrl: './ad-campaigns.component.html',
    styleUrls: ['./ad-campaigns.component.scss'],
})
export class AdCampaignsComponent implements OnInit {

    constructor() {
        //
    }

    ngOnInit(): void {
        //
    }

}
