import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl, ValidationErrors } from '@angular/forms';
import * as moment from 'moment-timezone';

@Directive({
    selector: '[qsIANATimezone]',
    providers: [{ provide: NG_VALIDATORS, useExisting: IANATimezoneValidatorDirective, multi: true }],
})
export class IANATimezoneValidatorDirective implements Validator {

    timezones: string[] = moment.tz.names();

    validate(c: FormControl): ValidationErrors {
        const result: string = this.timezones.find((val: string) => val === c.value);
        const message: any = {
            'qsIANATimezone': {
                'message': 'matching string not found',
            },
        };
        return (!!result) ? undefined : message;
    }
}
