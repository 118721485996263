import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { MatDialog, MatDialogRef } from '@angular/material';

import {
    TdDataTableComponent,
    TdDataTableService,
    TdDataTableSortingOrder,
    ITdDataTableSortChangeEvent,
    ITdDataTableColumn,
    TdDialogService,
    IPageChangeEvent,
} from '@covalent/core';

import { NGXLogger } from 'ngx-logger';

import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

// import { L10nService } from '../l10n/l10n.service';
import { UrlService, AwsS3Service } from '../services';
import { IUrl } from '../models/url.model';
import { IDataArray } from '../models/data-array.model';
import { PreviewDialogComponent } from '../dialogs/preview-dialog/preview-dialog.component';
import { environment } from '../../environments/environment';
import { ProfileService } from '../auth/profile.service';
import { AuthService } from '../auth/auth.service';

@Component({
    selector: 'gk-url-list',
    templateUrl: './url.list.component.html',
    styleUrls: ['./url.list.component.scss'],
})
export class UrlListComponent implements OnInit, OnChanges, OnDestroy {

    private columnsWithEdit: ITdDataTableColumn[] = [
        { name: 'title', label: 'Title', tooltip: 'Url Title' },
        { name: 'src', label: 'Link' },
        { name: 'description', label: 'Description' },
        { name: 'id', label: '' },
    ];

    private columnsWithoutEdit: ITdDataTableColumn[] = [
        { name: 'title', label: 'Title', tooltip: 'Url Title' },
        { name: 'src', label: 'Thumbnail' },
        { name: 'description', label: 'Description' },
    ];

    private subscription: any;

    @Input()
    isEditable: boolean = true;

    @ViewChild(TdDataTableComponent) dataTable: TdDataTableComponent;

    data: IUrl[] = [];

    lookup: Object = {
        title: 'UrlList.ColHeader.Title',
        src: 'UrlList.ColHeader.Link',
        description: 'UrlList.ColHeader.Description',
    };

    columns: ITdDataTableColumn[] = this.columnsWithEdit;

    filteredData: IUrl[] = this.data;
    filteredTotal: number = this.data.length;

    selectedRows: IUrl[] = [];

    searchTerm: string = '';
    fromRow: number = 1;
    currentPage: number = 1;
    pageSize: number = 50;
    sortBy: string = 'title';
    sortOrder: TdDataTableSortingOrder = TdDataTableSortingOrder.Descending;

    selectedIndexIntoTabGroup: number = 0;

    constructor(private _dataTableService: TdDataTableService,
        private _dialogService: TdDialogService,
        private _urlService: UrlService,
        private _s3: AwsS3Service,
        private _snackBarService: MatSnackBar,
        private _translate: TranslateService,
        private logger: NGXLogger,
        private dialog: MatDialog,
        private auth: AuthService,
    ) { }

    ngOnInit(): void {
        this.subscription = this._translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.retranslateColumnHeaders();
        });
        this.retranslateColumnHeaders();
        this.filter();
        this.loadUrls();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.isEditable) {
            if (changes.isEditable.currentValue) {
                this.columns = this.columnsWithEdit;
            } else {
                this.columns = this.columnsWithoutEdit;
            }
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    onAccountChangeEvent(event: any): void {
        this.logger.log('video.list: onAccountChangeEvent()');
        this.data = [];
        this.loadUrls();
    }

    onSortEvent(sortEvent: ITdDataTableSortChangeEvent): void {
        this.sortBy = sortEvent.name;
        this.sortOrder = sortEvent.order;
        this.filter();
    }

    onSearchTermEvent(searchTerm: string): void {
        this.searchTerm = searchTerm;
        this.filter();
    }

    onPageChangeEvent(pagingEvent: IPageChangeEvent): void {
        this.fromRow = pagingEvent.fromRow;
        this.currentPage = pagingEvent.page;
        this.pageSize = pagingEvent.pageSize;
        this.filter();
    }

    onPreviewClick(id: string): void {
        let url: string = environment.screenUrl + '/#/urls/' + id + '?' +
            't=' + this.auth.getToken();

        const effectiveAccount: string = ProfileService.profileSingleton().effectiveAccountID;
        if (effectiveAccount) {
            url += '&a=' + effectiveAccount;
        }
        const dialogRef: MatDialogRef<PreviewDialogComponent> = this.dialog.open(PreviewDialogComponent, {
            data: { url: url },
        });
        dialogRef.afterClosed().subscribe();
    }

    filter(): void {
        let newData: any[] = this.data;
        newData = this._dataTableService.filterData(newData, this.searchTerm, true);
        this.filteredTotal = newData.length;
        newData = this._dataTableService.sortData(newData, this.sortBy, this.sortOrder);
        newData = this._dataTableService.pageData(newData, this.fromRow, this.currentPage * this.pageSize);
        this.filteredData = newData;
    }

    loadUrls(): void {
        this._urlService.getAll().subscribe(
            (dataArray: IDataArray<IUrl>) => {
                this.data = dataArray.data;
            },
            (err: any) => {
                this.logger.error(err);
            },
            () => {
                this.logger.log('loaded urls', this.data);
                this.filter();
            },
        );
    }

    reload(): void {
        this.data = [];
        this.filter();
        this.loadUrls();
    }

    onDeleteUrlEvent(id: string): void {

        let urlTitle: string = this.data.find((x: IUrl) => x.id === id).title;

        this._dialogService
            .openConfirm({ title: 'Confirmation Required', message: 'Are you sure you want to delete ' + urlTitle + '?' })
            .afterClosed().subscribe((confirm: boolean) => {
                if (confirm) {
                    this._urlService.delete(id).subscribe(
                        (data: void) => {
                            this.logger.log(data);
                            this.data = this.data.filter((url: IUrl) => {
                                return url.id !== id;
                            });
                            this.filteredData = this.filteredData.filter((url: IUrl) => {
                                return url.id !== id;
                            });
                            this._snackBarService.open('Url deleted', 'Ok', { duration: 3000 });
                        },
                        (error: Error) => {
                            this._dialogService.openAlert({ message: 'There was an error=' + error });
                        },
                    );
                }
            });
    }

    retranslateColumnHeaders(): void {
        this.columns.forEach(async (col: ITdDataTableColumn) => {
            if (col.label) {
                col.label = await this._translate.get(this.lookup[col.name]).toPromise();
            }
        });
        this.dataTable.refresh();
    }
}
