import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Observer, Observable } from 'rxjs';

import { NGXLogger } from 'ngx-logger';

import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import * as AWS from 'aws-sdk';
import { DeviceOptions, device } from 'aws-iot-device-sdk';

import { AwsCognitoService } from '../services';
import { IUserAuthenticationResult, IUserForgotPasswordResult, ForgotPasswordState } from '../services/amazon/aws-cognito.service';
import { MatSnackBar } from '@angular/material';
import { environment } from '../../environments/environment.test';

import * as moment from 'moment-timezone';

interface IReceivedMessage {
    dateString: string;
    topic: string;
    payload: any;
}

@Component({
    selector: 'gk-deus-modus',
    templateUrl: './deus-modus.component.html',
    styleUrls: ['./deus-modus.component.scss'],
})
export class DeusModusComponent implements OnInit {

    selectedTab: number = 0;
    rfc5322: string = '^(([^<>()\[\]\\.,;:\s@"]+' +
        '(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))' + '@' +
        '((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$';
    username: string;
    email: string;
    phone: string;
    verification: string;
    password: string;
    password2: string;
    deviceId: string;
    mqttTopic: string;
    messageLog: IReceivedMessage[] = [];

    code: string;

    _session: CognitoUserSession;
    cognitoUser: CognitoUser;
    awsCredentials: AWS.CognitoIdentityCredentials;

    iotDevice: device; // extends MQTT.Channel
    subscribedTopic: string; // the topic we're subscribed to

    publishTopic: string;
    publishPayload: string;

    forgot2disabled: boolean = true;

    public get session(): CognitoUserSession { return this._session; }

    public set session(session: CognitoUserSession) {
        if (!!session) {
            this._session = session;
            this.cognitoUser = this.cognito.getCurrentUser();
            this.cognito.getAWSCredentials(session).subscribe(
                (next: AWS.CognitoIdentityCredentials) => { this.awsCredentials = next; },
                (err: any) => { this.logger.error(err.message); },
                () => { this.logger.info('aws creds established'); },
            );
            if (this.selectedTab === 0) { this.selectedTab = 2; }
        } else {
            this._session = undefined;
            this.cognitoUser = undefined;
            this.awsCredentials = undefined;
            this.selectedTab = 0;
        }
    }

    constructor(
        private snackBar: MatSnackBar,
        private cognito: AwsCognitoService,
        private logger: NGXLogger,
    ) { }

    public ngOnInit(): void {
        this.onLoadSessionClick(undefined);
        if (!!this.session) { this.selectedTab = 1; }
    }

    public onAccountChangeEvent(evt: object): void {
        this.logger.info('DeusModusComponent.onAccountChangeEvent()');
    }

    onCreateClick(form: NgForm): void {
        this.logger.info('DeusModusComponent.onCreateClick()');
        this.cognito.createUser(this.username, this.email, this.password, this.phone)
            .subscribe(
                (next: CognitoUser) => {
                    this.cognitoUser = next;
                    this.snackBar.open('User created as:', next.getUsername(), { duration: 2000 });
                    this.logger.info('onCreateClick() result: user = ', next.getUsername());
                },
                (error: any) => {
                    alert('onCreateClick():' + error.message);
                    this.logger.error('onCreateClick() error: ' + error.message);
                },
                () => {
                    form.resetForm();
                    this.logger.info('onCreateClick() done');
                },
            );
    }

    onConfirmClick(form: NgForm): void {
        this.logger.info('DeusModusComponent.onConfirmClick()');
        const usernameOrEmail: string = !!this.username ? this.username : this.email;
        this.cognito.confirmNewUser(usernameOrEmail, this.code.toString())
            .subscribe(
                (next: boolean) => {
                    this.snackBar.open('User confirmed', undefined, { duration: 2000 });
                    this.logger.info('onConfirmClick() result: ' + next ? 'success!' : 'fail');
                },
                (error: any) => {
                    alert(error.message);
                    this.logger.error('onConfirmClick()' + error.message);
                },
                () => {
                    form.resetForm();
                    this.logger.info('onConfirmClick() done');
                },
            );
    }

    onResendCodeClick(): void {

        const usernameOrEmail: string = !!this.username ? this.username : this.email;
        this.cognito.resendConfirmationCode(usernameOrEmail)
            .subscribe(
                (next: boolean) => {
                    this.snackBar.open('Confirmation Requested', undefined, { duration: 2000 });
                    this.logger.info('onResendCodeClick() result: ' + next ? 'success!' : 'fail');
                },
                (error: any) => {
                    alert('onResendCodeClick():' + error.message);
                    this.logger.error('onResendCodeClick()' + error.message);
                },
                () => {
                    this.logger.info('onResendCodeClick() done');
                },
            );
    }

    onLoginClick(form: NgForm): void {

        const usernameOrEmail: string = !!this.username ? this.username : this.email;
        this.cognito.authenticateUser(usernameOrEmail, this.password)
            .subscribe(
                (next: IUserAuthenticationResult) => {
                    if (!!next.session) {
                        this.session = next.session;
                    } else {
                        this.session = undefined;
                    }
                    this.snackBar.open('Confirmation Requested', next.state.toLocaleString(), { duration: 2000 });
                    this.logger.info('onLogin() result: ' + next.state);
                },
                (error: any) => {
                    alert('onLoginClick():' + error.message);
                    this.logger.info('onLogin() error ' + error.message);
                },
                () => {
                    form.resetForm();
                    this.logger.info('onLogin() done');
                },
            );
    }

    onForgotPasswordClick(form: NgForm): void {
        const usernameOrEmail: string = !!this.username ? this.username : this.email;
        this.cognito.forgotPassword(usernameOrEmail)
            .subscribe(
                (next: IUserForgotPasswordResult): void => {

                    if (next.state === ForgotPasswordState.INPUT_VERIFICATION_CODE) {
                        // go to confirm password.
                        this.forgot2disabled = false;
                        this.snackBar.open('Forgot Password. Sending code to verified email or phone...', undefined, { duration: 2000 });
                        this.logger.info('Forgot Password. Sending code to verified email or phone...');
                    } else if (next.state === ForgotPasswordState.SUCCESS) {
                        this.snackBar.open('Forgot Password. Success!', undefined, { duration: 2000 });
                        this.logger.info('Forgot Password. Success!');
                        // done
                    }

                },
                (error: any) => {
                    alert('onForgotPasswordClick():' + error.message);
                    this.logger.info('onForgotPasswordClick() error ' + error.message);
                },
            );
    }

    onChangingForgotPasswordClick(form: NgForm): void {
        const usernameOrEmail: string = !!form.value.username ? form.value.username : form.value.email;
        const verificationCode: string = form.value.verification;
        const newPassword: string = form.value.password;

        this.cognito.confirmPassword(usernameOrEmail, verificationCode, newPassword)
            .subscribe(
                (next: boolean): void => {
                    this.snackBar.open('Password Change = ' + next, '', { duration: 2000 });
                    this.logger.info('onConfirmPasswordClick() result: ' + next);
                },
                (error: any) => {
                    alert('onLoginClick():' + error.message);
                    this.logger.info('onLogin() error ' + error.message);
                },
            );
    }

    onLogoutClick(evt: object): void {
        this.cognitoUser.signOut();
        this.session = undefined;
    }

    onGlobalLogoutClick(evt: object): void {
        this.cognito.globalSignOut(this.email);
        this.session = undefined;
    }

    onLoadSessionClick(evt: object): void {
        this.cognito.loadSession().subscribe(

            (next: CognitoUserSession) => {
                this.session = next;
                this.snackBar.open('Session loaded', undefined, { duration: 2000 });
                this.logger.info('onLoadSessionClick() result ' + next);
            },
            (error: any) => {
                this.session = undefined;
                if (!!evt) { alert('onLoadSessionClick():' + error.message); } // dont show alert on initial load
                this.logger.info('onLoadSessionClick() error ' + error.message);
            },
            () => {
                this.logger.info('onLoadSessionClick() done');
            },
        );
    }

    onRestoreSessionClick(evt: object): void {
        this.cognito.loadSession().subscribe(

            (next: CognitoUserSession) => {
                this.logger.info('onRestoreSessionClick() result ' + next.isValid());
            },
            (error: any) => {
                this.logger.info('onRestoreSessionClick() error ' + error.message);
            },
            () => {
                this.logger.info('onRestoreSessionClick() done');
            },
        );
    }

    onMQTTConnectClick(): void {

        let opts: DeviceOptions = {
            region: environment.cognitoRegion,
            host: environment.iotEndpoints.default,
            clientId: `${Math.floor((Math.random() * 1000000) + 1)}`,
            protocol: 'wss',
            accessKeyId: this.awsCredentials.accessKeyId,
            secretKey: this.awsCredentials.secretAccessKey,
            sessionToken: this.awsCredentials.sessionToken,
        };
        this.iotDevice = new device(opts);
    }

    onMQTTDisconnectClick(): void {
        this.iotDevice.end();
        this.iotDevice = undefined;
    }

    onTopicPublish(form: NgForm): void {
        this.logger.debug(`onTopicPublish() \"${this.publishTopic}\", \"${this.publishPayload}\"`);
        this.iotDevice.publish(this.publishTopic, this.publishPayload);
    }

    onSubscribeTopicClick(): void {
        if (!!this.subscribedTopic) { // can't subscribe twice
            return;
        }
        if (!this.mqttTopic) { // need a legit topic
            return;
        }
        this.iotDevice.subscribe(this.mqttTopic);
        this.subscribedTopic = this.mqttTopic;
        this.iotDevice.on('connect', () => this.logger.info('device.onConnect'));
        this.iotDevice.on('close', () => this.logger.info('device.onClose'));
        this.iotDevice.on('reconnect', () => this.logger.info('device.onReconnect'));
        this.iotDevice.on('offline', () => this.logger.info('device.onOffline'));
        this.iotDevice.on('error', (error: string | Error) => this.logger.info('device.onError - ', error));
        this.iotDevice.on('message', (topic: string, payload: any) => this.processMQTTMessage(topic, payload));

    }

    onUnsubscribeTopicClick(): void {
        if (!!this.subscribedTopic) {
            this.iotDevice.unsubscribe(this.subscribedTopic);
            this.subscribedTopic = undefined;
        }
    }

    onClearLogClick(): void {
        this.messageLog = [];
    }

    setPayloadTemplate(templateName: string): void {
        const payload: any = {
            ping: {
                action: 'ping',
            },
            toast: {
                action: 'toast',
                message: '<MESSAGE>',
            },
            reload: {
                action: 'reload',
            },
            download: {
                action: 'download',
                url: '<URL>',
            },
            upload: {
                action: 'upload',
                path: '<PATH>',
            },
            demo: {
                action: 'demo',
                captureOnly: false,
            },
            camera: {
                action: 'demo',
                captureOnly: true,
            },
            su: {
                action: 'su',
                commands: [
                    'am kill com.addulate.screen',
                    'pm install -r <LOCAL/PATH/TO/APK.apk>',
                    'am start -n com.addulate.screen/com.addulate.screen.ui.main.MainActivity',
                ],
            },
            displayLog: {
                action: 'displayLog',
                enable: true,
                egrep: '<regex or null>',
                filter: '<regex or null>',
            },
        };
        const template: any = payload[templateName];
        if (!!template) {
            this.publishPayload = JSON.stringify(template, undefined, 4);
        } else {
            this.publishPayload = '{}';
        }
    }

    private processMQTTMessage(topic: string, payload: any): void {
        const msg: IReceivedMessage = {
            dateString: moment().format(),
            topic: topic,
            payload: payload,
        };
        this.messageLog.unshift(msg);
    }
}
