import { Component, ViewChild, OnInit } from '@angular/core';

import { L10nService } from '../l10n/l10n.service';
import { UserListComponent } from '../lists/user.list.component';

import { ProfileService, ProfileSingleton } from '../auth/profile.service';

@Component({
    selector: 'gk-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
    providers: [L10nService],
})
export class UsersComponent implements OnInit {

    @ViewChild(UserListComponent)
    private userList: UserListComponent;

    public hasAdminRole: boolean;

    ngOnInit(): void {
        this.hasAdminRole = ProfileService.profileSingleton().hasAdminRole;
    }

    onAccountChangeEvent(event: any): void {
        this.userList.onAccountChangeEvent(event);
    }

}
