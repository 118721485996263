import { Injectable, Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router, CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { MatSnackBar, MatDialog, MatDialogRef } from '@angular/material';
import { Observable, Subscription } from 'rxjs';

import { TdMediaService, TdDialogService, TdLoadingService, LoadingType, LoadingMode } from '@covalent/core';

import { NGXLogger } from 'ngx-logger';

import { UserDetailComponent } from '../user-detail/user-detail.component';

import { IUser } from '../../models/user.model';
import { UserService } from '../../services';
import { L10nService } from '../../l10n/l10n.service';
import { PasswordDialogService } from '../../dialogs/password-dialog.service';
import { LeaveWithoutSaveDialogComponent } from '../../dialogs/leave-without-save-dialog.component';
import { environment } from '../../environment';
import { isThisQuarter } from 'date-fns';

@Injectable()
export class CanDeactivateUserEditor implements CanDeactivate<UserEditorComponent> {
    constructor(private dialog: MatDialog) { }

    canDeactivate(
        component: UserEditorComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot,
    ): Observable<boolean> | Promise<boolean> | boolean {

        component.updateStatus();
        if (component.dirty) {
            let dialogRef: MatDialogRef<LeaveWithoutSaveDialogComponent> = this.dialog.open(LeaveWithoutSaveDialogComponent);
            return dialogRef.afterClosed();
        } else {
            return true;
        }
    }
}

@Component({
    selector: 'gk-user-editor',
    templateUrl: './user-editor.component.html',
    styleUrls: ['./user-editor.component.scss'],
})
export class UserEditorComponent implements OnInit, AfterViewInit {

    private isSaving: boolean = false; // used to short-circuit leave-without-save

    private readonly spinnerName: string = 'overlayLoadingSpinner';

    @ViewChild(UserDetailComponent)
    private userDetails: UserDetailComponent;

    private userId: string;
    private action: string;
    private changeSubscription: Subscription;

    private user: IUser = <IUser>{
        name: '',
        email: '',
        isActive: false,
        isAdmin: false,
        hasSignatoryRole: false,
        hasAuditorRole: false,
        hasAdminRole: false,
        hasPublisherRole: false,
        hasSubaccountRole: false,
    };

    cardTitle: string;
    overlayLoadingSpinner: boolean = false;
    dirty: boolean = false;
    valid: boolean = false;

    constructor(
        private users: UserService,
        private route: ActivatedRoute,
        // public media: TdMediaService,
        // private _dialogService: TdDialogService,
        // private _viewContainerRef: ViewContainerRef,
        // private _passwordService: PasswordDialogService,
        private snackBarService: MatSnackBar,
        private loadingService: TdLoadingService,
        private logger: NGXLogger,
    ) {
    }

    ngOnInit(): void {
        this.route.url.subscribe((url: any) => {
            this.action = (url.length > 1 ? url[1].path : 'add');
            this.logger.debug('this.action == ', this.action);
            if (this.action === 'add') {
                this.cardTitle = 'UserEditor.CardTitle.Create';
            } else {
                this.userId = url[0].path;
                this.cardTitle = 'UserEditor.CardTitle.Modify';
                this.loadData();
            }
        });
    }

    ngAfterViewInit(): void {
        this.logger.log('ngAfterViewInit()');
        this.changeSubscription = this.userDetails.userForm.statusChanges.subscribe(
            (next: any): void => {
                this.updateStatus();
            });
    }

    goBack(): void {
        this.loadingService.resolve(this.spinnerName);
        if (!!this.changeSubscription) {
            this.changeSubscription.unsubscribe();
        }
        window.history.back();
    }

    restore(): void {
        this.userDetails.restore();
        this.updateStatus();
    }

    save(): void {
        this.loadingService.register(this.spinnerName);
        let user: IUser = this.userDetails.exportUser();
        if (this.action === 'add') {
            delete user.id;
            this.users.create(user).subscribe(
                () => {
                    this.snackBarService.open('User created.', '', { duration: 1000 });
                    this.userDetails.userForm.markAsPristine();
                    this.updateStatus();
                    this.goBack();
                },
                (error: any) => {
                    // TODO: error handling
                    this.loadingService.resolve(this.spinnerName);
                    this.snackBarService.open('Error saving data.', '', { duration: 1000 });
                },
            );
        } else {
            this.users.update(user).subscribe(
                () => {
                    this.snackBarService.open('User changes saved.', '', { duration: 1000 });
                    this.userDetails.userForm.markAsPristine();
                    this.updateStatus();
                    this.goBack();
                },
                (error: any) => {
                    this.snackBarService.open('Error saving data.', '', { duration: 1000 });
                    this.loadingService.resolve(this.spinnerName);
                },
            );
        }
    }

    public updateStatus(): void {
        this.dirty = this.userDetails.userForm.dirty;
        this.valid = this.userDetails.userForm.valid;
    }

    private loadData(): void {
        this.loadingService.register(this.spinnerName);
        this.users.getById(this.userId).subscribe(
            (user: IUser) => {
                this.user = user;
                this.logger.debug('user == ', user);
                this.loadingService.resolve(this.spinnerName);
            },
            (err: any) => {
                this.logger.log('Error: UserForm.loadData: user ID not found.');
                this.goBack();
            },
        );
    }

}
