import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { IUrl } from '../models/url.model';
import { AuthService } from '../auth/auth.service';
import { ProfileService } from '../auth/profile.service';
import { IDataArray, IRemoteReference } from '../models/data-array.model';
import { environment } from '../../environments/environment';

@Injectable()
export class UrlService {

    get headers(): HttpHeaders {
        let headers: HttpHeaders = new HttpHeaders();
        headers = this.auth.addAuthHttpHeaders(headers);
        headers = this.profile.addProfileHttpHeaders(headers);
        return headers;
    }

    constructor(
        private http: HttpClient,
        private auth: AuthService,
        private profile: ProfileService,
    ) { }

    getAll(): Observable<IDataArray<IUrl>> {
        let url: string = environment.apiEndpoint + '/urls';
        return this.http.get<IDataArray<IUrl>>(url, { headers: this.headers });
    }

    getById(id: string): Observable<IUrl> {
        let url: string = environment.apiEndpoint + '/urls/' + id;
        return this.http.get<IUrl>(url, { headers: this.headers });
    }

    create(url: IUrl): Observable<IRemoteReference<IUrl>> {
        let endpoint: string = environment.apiEndpoint + '/urls';
        return this.http.post<IRemoteReference<IUrl>>(endpoint, url, { headers: this.headers });
    }

    update(url: IUrl): Observable<void> {
        let endpoint: string = environment.apiEndpoint + '/urls/' + url.id;
        return this.http.put<void>(endpoint, url, { headers: this.headers });
    }

    delete(id: string): Observable<void> {
        let endpoint: string = environment.apiEndpoint + '/urls/' + id;
        return this.http.delete<void>(endpoint, { headers: this.headers });
    }
}
