import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'gk-sys-ops',
    templateUrl: './sys-ops.component.html',
    styleUrls: ['./sys-ops.component.scss'],
})
export class SysOpsComponent {

}
