/**
 * image.model.ts
 */

export interface IImage {
    id?: string;
    title: string;
    description: string;
    thumbnailUrl: string;
    imageUrl: string;
    mimeType: string;
    width: number;
    height: number;
    fileSizeBytes: number;
}

export class ImageUtils {

    static isLandscape(img: IImage): boolean {
        return img.width > img.height;
    }

    static isPortrait(img: IImage): boolean {
        return img.width < img.height;
    }

    static aspectRatio(img: IImage): string {
        if (ImageUtils.isPortrait(img) || ImageUtils.isLandscape(img)) {
            const g: number = ImageUtils.gcd(img.width, img.height);
            return '' + (img.width / g) + ':' + (img.height / g);
        } else {
            return 'unknown';
        }
    }

    static gcd(a: number, b: number): number {
        a = Math.floor(a);
        b = Math.floor(b);
        while (b !== 0) {
            const t: number = b;
            b = a % b;
            a = t;
        }
        return a;
    }
}
