import { MatDialogRef } from '@angular/material';
import { Component } from '@angular/core';

import * as moment from 'moment-timezone';

@Component({
    selector: 'gk-which-recurring-events-dialog',
    templateUrl: './which-recurring-events-dialog.component.html',
    styleUrls: ['./which-recurring-events-dialog.component.scss'],
})
export class WhichRecurringEventsDialogComponent {

    private _targetDateString: string = '(unspecified)';
    private _startingDateString: string = '(unspecified)';

    isEditing: boolean = true;

    get title(): string {
        return this.isEditing ? 'Modify Recurring Event' : 'Delete Recurring Event';
    }

    get message(): string {
        return 'You have selected the ' + this._targetDateString +
            ' instance of a recurring series starting on ' + this._startingDateString +
            '. Please specify which instances of this series you wish to ' +
            (this.isEditing ? 'modify.' : 'delete.');
    }

    constructor(public dialogRef: MatDialogRef<WhichRecurringEventsDialogComponent>) {
    }

    setTargetDate(date: Date): void {
        this._targetDateString = moment(date).format('LL');
    }

    setStartingDate(date: Date): void {
        this._startingDateString = moment(date).format('LL');
    }

}
