import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';

// import { VersionInfo } from '../../version_info';

@Component({
    selector: 'gk-leave-without-save-dialog',
    templateUrl: './leave-without-save-dialog.component.html',
    styleUrls: ['./leave-without-save-dialog.component.scss'],
})
export class LeaveWithoutSaveDialogComponent {

    itemName: string;

    constructor(public dialogRef: MatDialogRef<LeaveWithoutSaveDialogComponent>) {

    }

}
