import { Component, ViewChild } from '@angular/core';

import { NGXLogger } from 'ngx-logger';

import { PlaylistListComponent } from '../lists/playlist.list.component';
import { L10nService } from '../l10n/l10n.service';

@Component({
    selector: 'gk-playlists',
    templateUrl: './playlists.component.html',
    styleUrls: ['./playlists.component.scss'],
    providers: [L10nService],
})
export class PlaylistsComponent {

    @ViewChild(PlaylistListComponent)
    private playlistList: PlaylistListComponent;

    constructor(
        private logger: NGXLogger,
    ) { }

    onAccountChangeEvent(event: any): void {
        this.logger.log('media.component: reloading images and videos');
        this.playlistList.onAccountChangeEvent(event);
    }
}
