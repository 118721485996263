import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { ILocation } from '../models/location.model';

@Component({
    selector: 'gk-location-formview',
    templateUrl: './location.formview.component.html',
    styleUrls: ['./location.formview.component.scss'],
})
export class LocationFormViewComponent {

    @Input()
    location: ILocation;

}
