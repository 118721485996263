import { IImage } from './image.model';
import { IVideo } from './video.model';
import { IUrl } from './url.model';

/**
 * playlist.model.ts
 */

export interface IPlaylistItem {
    id?: string;
    position: number;
    itemType: string; // video or image
    itemId: string;   // video_UUID or image_UUID
    startTime: number;
    durationSecs: number;
    item?: IVideo | IImage;
    width: number;
    height: number;
    // thumbnailUrl:string;
    // title:string;
}

export interface IPlaylist {
    id: string;
    title: string;
    description: string;
    thumbnailUrl: string;
    durationSecs: number;
    items?: IPlaylistItem[];
}

export function playlistItemMediaFactory(media: any): IPlaylistItem {
    console.log("media dragged",media)
    if (!media || !media.id) {
        return undefined;
    } else if (/^image_/.test(media.id)) {
        return <IPlaylistItem>{
            id: '',
            position: 0,
            itemType: 'image',
            itemId: media.id,
            startTime: 0,
            durationSecs: 30.0,
            height:media.height,
            width: media.width
            // thumbnailUrl : media.thumbnailUrl
        };

    } else if (/^video_/.test(media.id)) {
        return <IPlaylistItem>{
            id: '',
            position: 0,
            itemType: 'video',
            itemId: media.id,
            startTime: 0,
            durationSecs: media.durationSecs,  
            height:media.height,
            width: media.width
        };

    } else if (/^url_/.test(media.id)) {
        return <IPlaylistItem>{
            id: '',
            position: 0,
            itemType: 'url',
            itemId: media.id,
            startTime: 0,
            durationSecs: 30.0,
        };
    } else {
        return undefined;
    }
}
