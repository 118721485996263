import { Injectable, Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router, CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NgForm } from '@angular/forms';

import { MatSnackBar, MatDialog, MatDialogRef } from '@angular/material';

import { Observable } from 'rxjs';

import { TdMediaService, TdLoadingService, LoadingType, LoadingMode } from '@covalent/core';

import { NGXLogger } from 'ngx-logger';

import { IAccount } from '../../models/account.model';
import { AccountService } from '../../services';
import { L10nService } from '../../l10n/l10n.service';
import { LeaveWithoutSaveDialogComponent } from '../../dialogs/leave-without-save-dialog.component';
import { IRemoteReference } from '../../models/data-array.model';

@Injectable()
export class CanDeactivateAccountForm implements CanDeactivate<AccountFormComponent> {

    constructor(
        private dialog: MatDialog,
    ) { }

    canDeactivate(
        component: AccountFormComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot,
    ): Observable<boolean> | Promise<boolean> | boolean {

        if (component.dirty) {
            let dialogRef: MatDialogRef<LeaveWithoutSaveDialogComponent> = this.dialog.open(LeaveWithoutSaveDialogComponent);
            return dialogRef.afterClosed();
        } else {
            return true;
        }
    }
}

@Component({
    selector: 'gk-account-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    viewProviders: [AccountService],
    providers: [L10nService],
})

export class AccountFormComponent implements OnInit, AfterViewInit {

    private _isSaving: boolean = false; // used to short-circuit leave-without-save
    private readonly _spinnerName: string = 'saving';

    @ViewChild('myForm') myForm: NgForm;

    id: string;
    name: string;
    isActive: boolean;

    action: string;
    cardTitle: string;

    constructor(
        private acctService: AccountService,
        private route: ActivatedRoute,
        private router: Router,
        private media: TdMediaService,
        private loadingService: TdLoadingService,
        private logger: NGXLogger,
    ) {
        this.loadingService.create({
            name: this._spinnerName,
            type: LoadingType.Circular,
            mode: LoadingMode.Indeterminate,
            color: 'accent',
        });
    }

    goBack(): void {
        this.loadingService.resolve(this._spinnerName);
        this.router.navigate(['/accounts']);
    }

    ngOnInit(): void {
        this.route.url.subscribe((url: any) => {
            this.logger.log('AccountFormComponent.ngOnInit: url=', url);
            this.action = (url.length > 1 ? url[1].path : 'add');
            if (this.action === 'add') {
                this.isActive = true;
                this.cardTitle = 'AccountEditor.CardTitleCreate';
            } else {
                this.initFromDatabase();
                this.cardTitle = 'AccountEditor.CardTitleModify';
            }
        });
    }

    ngAfterViewInit(): void {
        // broadcast to all listener observables when loading the page
        this.media.broadcast();
    }

    get dirty(): boolean {
        return this.myForm.touched && this.myForm.dirty && !this._isSaving;
    }

    save(): void {
        this.logger.log('name=', this.name, '   isActive=', this.isActive);
        this._isSaving = true;
        this.loadingService.register(this._spinnerName);

        let account: IAccount = <IAccount>{
            id: this.id,
            name: this.name,
            isActive: this.isActive,
        };
        if (this.action === 'add') {
            this.acctService.create(account).subscribe(
                (data: IRemoteReference<IAccount>) => this.goBack(),
                (err: any) => this.logger.error(err),
            );
        } else {
            this.acctService.update(account).subscribe(
                (data: void) => this.goBack(),
                (err: any) => this.logger.error(err),
            );
        }
    }

    private initFromDatabase(): void {
        this.route.params.subscribe((params: { id: string }) => {
            this.logger.log('AccountFormComponent.ngOnInit: params=', params);
            let accountID: string = params.id;
            this.acctService.getById(accountID).subscribe((account: IAccount) => {
                this.logger.log('service returned=', account);
                this.id = account.id;
                this.name = account.name;
                this.isActive = account.isActive;
            });
        });
    }
}
