import { Component, ViewChild, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { IPageChangeEvent } from '@covalent/core';

import { L10nService } from '../l10n/l10n.service';
import { ScreenService } from '../services';
import { ScreenListComponent } from '../lists/screen.list.component';
import { ChannelListComponent } from '../lists/channel.list.component';

@Component({
    selector: 'gk-screens',
    templateUrl: './screens.component.html',
    styleUrls: ['./screens.component.css'],
    viewProviders: [ScreenService],
    providers: [L10nService],
})

export class ScreensComponent {

    @ViewChild(ScreenListComponent)
    private screenList: ScreenListComponent;

    @ViewChild(ChannelListComponent)
    private channelList: ChannelListComponent;

    // NOTE: selectedIndex is cached so user can return to previous tab
    private _selectedIndex: number = -1;

    get selectedIndex(): number {
        if (this._selectedIndex === -1) {
            this._selectedIndex = Number(sessionStorage.getItem('ScreensComponent.selectedIndex'));
        }
        return this._selectedIndex;
    }

    set selectedIndex(n: number) {
        this._selectedIndex = n;
        sessionStorage.setItem('ScreensComponent.selectedIndex', String(n));
    }

    constructor(private activatedRoute: ActivatedRoute) { }

    onAccountChangeEvent(event: any): void {
        this.screenList.onAccountChangeEvent(event);
        this.channelList.onAccountChangeEvent(event);
    }
}
