import { TdLoadingService, LoadingType, LoadingMode } from '@covalent/core';
import { Router } from '@angular/router';

import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';

import { NGXLogger } from 'ngx-logger';

import { OperationFailedDialogComponent } from '../dialogs/operation-failed-dialog/operation-failed-dialog.component';
import { Observable } from 'rxjs';

/**
 *  Base class for all screens
 */
export class BaseScreen {

    protected readonly SPINNER_NAME: string = 'saving';
    protected backPath: string[] = ['/']; // dervied classes should set this to something else

    constructor(
        protected router: Router,
        protected dialog: MatDialog,
        protected snackBar: MatSnackBar,
        protected loadingService: TdLoadingService,
        protected logger: NGXLogger,
    ) {
        this.loadingService.create({
            name: this.SPINNER_NAME,
            type: LoadingType.Circular,
            mode: LoadingMode.Indeterminate,
            color: 'accent',
        });
    }

    showSpinner(): void {
        this.loadingService.register(this.SPINNER_NAME);
    }

    goBack(): void {
        this.loadingService.resolve(this.SPINNER_NAME);
        this.router.navigate(this.backPath);
    }

    showError(err: any): Observable<any> {
        let msg: string;
        if (typeof err === 'string') {
            msg = <string>err;
        } else if (err instanceof Error) {
            msg = err.message;
        }

        this.loadingService.resolve(this.SPINNER_NAME);
        this.logger.error(msg);

        let dialogRef: MatDialogRef<OperationFailedDialogComponent> = this.dialog.open(OperationFailedDialogComponent, { data: { details: msg } });
        return dialogRef.afterClosed();
    }
}
