import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEvent, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { NGXLogger } from 'ngx-logger';

interface ITokenMsg {
    token: string;
}

@Injectable()
export class AuthService {

    private static token: string = undefined;

    private AUTH_KEY: string = 'auth_key';

    constructor(
        private http: HttpClient,
        private logger: NGXLogger,
    ) {
    }

    /*
	 * Assumes the token is valid. If not, then some other service should call logout() here.
	 */
    isLoggedIn(): boolean {
        if (this.hasCurrentToken()) {
            return true;
        } else if (this.hasSavedToken()) {
            AuthService.token = window.localStorage.getItem(this.AUTH_KEY);
            return true;
        } else {
            return false;
        }
    }

    public getToken(): string {
        return AuthService.token;
    }

    /*
	 * Revokes token.
	 */
    public logout(): void {
        AuthService.token = undefined;
        window.localStorage.removeItem(this.AUTH_KEY);
    }

    public login(usercreds: any, record: boolean): Observable<boolean> {
        let url: string = environment.apiEndpoint + '/token';
        let body: any = { 'email': usercreds.email, 'password': usercreds.password };
        let headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

        return this.http.post<ITokenMsg>(url, body, { headers: headers })
            .pipe(
                map((value: ITokenMsg): boolean => { return this.handleAuthResponse(value.token, record); }),
                catchError((err: any) => { return this.handleAuthError(err); }),
        );
    }

    /*
	 * Called by other services since the token is not directly available to them.
	 */
    public addAuthHttpHeaders(headers: HttpHeaders): HttpHeaders {
        if (!headers) {
            headers = new HttpHeaders();
        }
        headers = headers.set('Authorization', 'Bearer ' + AuthService.token);
        headers = headers.set('Content-Type', 'application/json');
        return headers;
    }

    private hasCurrentToken(): boolean {
        console.log( window.localStorage.getItem(this.AUTH_KEY))
       
        return !!AuthService.token;
    }

    private hasSavedToken(): boolean {
        return !!window.localStorage.getItem(this.AUTH_KEY);
    }

    /*
	 * Returns a function to handle response because we need to know whether to remember
	 * the token or not.
	 */
    private handleAuthResponse(token: string, record: boolean): boolean {
        if (!!token) {
            // successful auth response sets the token
            AuthService.token = token;
            if (record) {
                window.localStorage.setItem(this.AUTH_KEY, token);
            }
            return true;
        } else {
            this.logout();
            return false;
        }
    }

    private handleAuthError(error: any): Observable<boolean> {
        let errMsg: string = error.toString();
        this.logger.error(errMsg);
        return Observable.throw(new Error(errMsg));
    }
}
