import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';

@Component({
    selector: 'gk-repeat-weekly',
    templateUrl: './repeat-weekly.component.html',
    styleUrls: ['./repeat-weekly.component.scss'],
})
export class RepeatWeeklyComponent {

    @Input() parent: FormGroup;

    daysOfWeek: any[] = [
        { controlName: 'onSunday', label: 'Sunday' },
        { controlName: 'onMonday', label: 'Monday' },
        { controlName: 'onTuesday', label: 'Tuesday' },
        { controlName: 'onWednesday', label: 'Wednesday' },
        { controlName: 'onThursday', label: 'Thursday' },
        { controlName: 'onFriday', label: 'Friday' },
        { controlName: 'onSaturday', label: 'Saturday' },
    ];

    get nWeeks(): AbstractControl {
        return this.parent.get('nWeeks');
    }
}
