import { Injectable, Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router, CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MatSnackBar, MatDialog, MatDialogRef } from '@angular/material';
import { NgForm } from '@angular/forms';

import { Observable } from 'rxjs';

import { TdMediaService, TdLoadingService, LoadingType, LoadingMode } from '@covalent/core';

import { NGXLogger } from 'ngx-logger';

import { BaseEditor } from '../../base/base-editor';
import { ILocation } from '../../models/location.model';
import { IChannel } from '../../models/channel.model';
import { IRemoteReference } from '../../models/data-array.model';
import { ChannelService, LocationService } from '../../services';
import { L10nService } from '../../l10n/l10n.service';
import { LeaveWithoutSaveDialogComponent } from '../../dialogs/leave-without-save-dialog.component';

@Injectable()
export class CanDeactivateChannelEditor implements CanDeactivate<ChannelEditorComponent> {

    constructor(private dialog: MatDialog) {
    }

    canDeactivate(
        component: ChannelEditorComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot,
    ): Observable<boolean> | Promise<boolean> | boolean {

        if (component.dirty) {
            let dialogRef: MatDialogRef<LeaveWithoutSaveDialogComponent> = this.dialog.open(LeaveWithoutSaveDialogComponent);
            return dialogRef.afterClosed();
        } else {
            return true;
        }
    }
}

@Component({
    selector: 'gk-channel-editor',
    templateUrl: './channel.editor.component.html',
    styleUrls: ['./channel.editor.component.css'],
    viewProviders: [ChannelService, LocationService],
    providers: [L10nService],
})
export class ChannelEditorComponent extends BaseEditor implements OnInit, AfterViewInit {

    @ViewChild('myForm') myForm: NgForm;

    id: string;
    name: string;
    description: string;

    channel: IChannel;
    location: ILocation;

    action: string;
    cardTitle: string;

    get dirty(): boolean {
        return this.myForm.dirty;
    }

    get valid(): boolean {
        return this.myForm.valid;
    }

    constructor(
        private channelService: ChannelService,
        private locationService: LocationService,
        private route: ActivatedRoute,
        public media: TdMediaService,
        protected router: Router,
        protected dialog: MatDialog,
        protected snackBar: MatSnackBar,
        protected loadingService: TdLoadingService,
        protected logger: NGXLogger,
    ) {
        super(router, dialog, snackBar, loadingService, logger);
        this.backPath = ['/screens'];
    }

    ngOnInit(): void {
        this.route.url.subscribe(
            (url: any) => {
                this.logger.log('ChannelEditorComponent.ngOnInit: url=', url);
                this.action = (url.length > 1 ? url[1].path : 'add');
                if (this.action === 'add') {
                    this.cardTitle = 'Screens.ChannelEditor.CardTitle.Create';
                } else {
                    this.loadData();
                    this.cardTitle = 'Screens.ChannelEditor.CardTitle.Modify';
                }
            },
            (err: any) => {
                this.snackBar.open('Routing Error', 'Ok');
            },
        );
    }

    ngAfterViewInit(): void {
        // broadcast to all listener observables when loading the page
        this.media.broadcast();
    }

    onSaveClick(): void {
        this.myForm.control.markAsPending();
        this.showSpinner();
        let channel: IChannel = <IChannel>{
            id: this.id,
            name: this.name,
            description: this.description,
        };
        if (this.action === 'add') {
            this.channelService.create(channel).subscribe(
                (data: IRemoteReference<IChannel>) => {
                    this.logger.log(data);
                    this.myForm.form.markAsPristine();
                },
                (err: any) => {
                    this.logger.error(err);
                    this.myForm.form.markAsDirty();
                    this.showError(err);
                },
                () => {
                    this.goBack();
                },
            );
        } else {
            this.channelService.update(channel).subscribe(
                (data: void) => {
                    this.logger.log(data);
                    this.myForm.form.markAsPristine();
                },
                (err: any) => {
                    this.logger.error(err);
                    this.showError(err);
                    this.myForm.form.markAsDirty();
                },
                () => {
                    this.goBack();
                },
            );
        }
    }

    onDeleteClick(): void {
        // do something?
    }

    private loadData(): void {
        this.route.params.subscribe((params: { id: string }) => {
            this.logger.log('ChannelEditorComponent.ngOnInit: params=', params);
            let channelID: string = params.id;
            this.channelService.getById(channelID).subscribe((channel: IChannel) => {
                this.logger.log('service returned=', channel);
                this.id = channel.id;
                this.name = channel.name;
                this.description = channel.description;
            });
        });
    }

}
