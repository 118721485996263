/*
 *  Brewer Palettes
 *
 * Brewer palettes are color combinations selected for their special properties for use in data visualization and information design.
 * Such palettes were created by Cynthia Brewer for the purpose in cartography, but have found use in other fields.
 *
 * There are three types of Brewer palettes:
 *     'qualitative' -- colors do not have a perceived order.
 *     'sequential' -- colors ahve a perceived order and the perceived difference between successive colors is uniform
 *     'diverging' -- to back-to-back sequential palettes starting from a common color
 *
 *  See
 *   http://www.colorbrewer.org
 *   http://mkweb.bcgsc.ca/brewer/
 */

export class ColorUtils {
    static knockout(i: number, a: string[]): string[] {
        if (i > a.length) {
            return a;
        } else {
            const first: number = Math.floor((a.length - i) / 2);
            const second: number = first + i;
            const firstChunk: string[] = a.splice(first, i);
            return a;
        }
    }
}

export let palettes: Object = {

    qualitative: {

        pastel1: {
            '3': ['#FBB4AE', '#B3CDE3', '#CCEBC5'],
            '4': ['#FBB4AE', '#B3CDE3', '#CCEBC5', '#DECBE4'],
            '5': ['#FBB4AE', '#B3CDE3', '#CCEBC5', '#DECBE4', '#FED9A6'],
            '6': ['#FBB4AE', '#B3CDE3', '#CCEBC5', '#DECBE4', '#FED9A6', '#FFFFCC'],
            '7': ['#FBB4AE', '#B3CDE3', '#CCEBC5', '#DECBE4', '#FED9A6', '#FFFFCC', '#E5D8BD'],
            '8': ['#FBB4AE', '#B3CDE3', '#CCEBC5', '#DECBE4', '#FED9A6', '#FFFFCC', '#E5D8BD', '#FDDAEC'],
            '9': ['#FBB4AE', '#B3CDE3', '#CCEBC5', '#DECBE4', '#FED9A6', '#FFFFCC', '#E5D8BD', '#FDDAEC', '#F2F2F2'],
        },
        pastel2: {
            '3': ['#B3E2CD', '#FDCDAC', '#CBD5E8'],
            '4': ['#B3E2CD', '#FDCDAC', '#CBD5E8', '#F4CAE4'],
            '5': ['#B3E2CD', '#FDCDAC', '#CBD5E8', '#F4CAE4', '#E6F5C9'],
            '6': ['#B3E2CD', '#FDCDAC', '#CBD5E8', '#F4CAE4', '#E6F5C9', '#FFF2AE'],
            '7': ['#B3E2CD', '#FDCDAC', '#CBD5E8', '#F4CAE4', '#E6F5C9', '#FFF2AE', '#F1E2CC'],
            '8': ['#B3E2CD', '#FDCDAC', '#CBD5E8', '#F4CAE4', '#E6F5C9', '#FFF2AE', '#F1E2CC', '#CCCCCC'],
        },
        dark2: {
            '3': ['#1B9E77', '#D95F02', '#7570B3'],
            '4': ['#1B9E77', '#D95F02', '#7570B3', '#E7298A'],
            '5': ['#1B9E77', '#D95F02', '#7570B3', '#E7298A', '#66A61E'],
            '6': ['#1B9E77', '#D95F02', '#7570B3', '#E7298A', '#66A61E', '#E6AB02'],
            '7': ['#1B9E77', '#D95F02', '#7570B3', '#E7298A', '#66A61E', '#E6AB02', '#A6761D'],
            '8': ['#1B9E77', '#D95F02', '#7570B3', '#E7298A', '#66A61E', '#E6AB02', '#A6761D', '#666666'],
        },
        accent: {
            '3': ['#7FC97F', '#BEAED4', '#FDC086'],
            '4': ['#7FC97F', '#BEAED4', '#FDC086', '#FFFF99'],
            '5': ['#7FC97F', '#BEAED4', '#FDC086', '#FFFF99', '#386CB0'],
            '6': ['#7FC97F', '#BEAED4', '#FDC086', '#FFFF99', '#386CB0', '#F0027F'],
            '7': ['#7FC97F', '#BEAED4', '#FDC086', '#FFFF99', '#386CB0', '#F0027F', '#BF5B17'],
            '8': ['#7FC97F', '#BEAED4', '#FDC086', '#FFFF99', '#386CB0', '#F0027F', '#BF5B17', '#666666'],
        },
        paired: {
            '3': ['#A6CEE3', '#1F78B4', '#B2DF8A'],
            '4': ['#A6CEE3', '#1F78B4', '#B2DF8A', '#33A02C'],
            '5': ['#A6CEE3', '#1F78B4', '#B2DF8A', '#33A02C', '#FB9A99'],
            '6': ['#A6CEE3', '#1F78B4', '#B2DF8A', '#33A02C', '#FB9A99', '#E31A1C'],
            '7': ['#A6CEE3', '#1F78B4', '#B2DF8A', '#33A02C', '#FB9A99', '#E31A1C', '#FDBF6F'],
            '8': ['#A6CEE3', '#1F78B4', '#B2DF8A', '#33A02C', '#FB9A99', '#E31A1C', '#FDBF6F', '#FF7F00'],
            '9': ['#A6CEE3', '#1F78B4', '#B2DF8A', '#33A02C', '#FB9A99', '#E31A1C', '#FDBF6F', '#FF7F00', '#CAB2D6'],
            '10': ['#A6CEE3', '#1F78B4', '#B2DF8A', '#33A02C', '#FB9A99', '#E31A1C', '#FDBF6F', '#FF7F00', '#CAB2D6', '#6A3D9A'],
            '11': ['#A6CEE3', '#1F78B4', '#B2DF8A', '#33A02C', '#FB9A99', '#E31A1C', '#FDBF6F', '#FF7F00', '#CAB2D6', '#6A3D9A', '#FFFF99'],
            '12': ['#A6CEE3', '#1F78B4', '#B2DF8A', '#33A02C', '#FB9A99', '#E31A1C', '#FDBF6F', '#FF7F00', '#CAB2D6', '#6A3D9A', '#FFFF99',
            '#B15928'],
        },
        set1: {
            '3': ['#E41A1C', '#377EB8', '#4DAF4A'],
            '4': ['#E41A1C', '#377EB8', '#4DAF4A', '#984EA3'],
            '5': ['#E41A1C', '#377EB8', '#4DAF4A', '#984EA3', '#FF7F00'],
            '6': ['#E41A1C', '#377EB8', '#4DAF4A', '#984EA3', '#FF7F00', '#FFFF33'],
            '7': ['#E41A1C', '#377EB8', '#4DAF4A', '#984EA3', '#FF7F00', '#FFFF33', '#A65628'],
            '8': ['#E41A1C', '#377EB8', '#4DAF4A', '#984EA3', '#FF7F00', '#FFFF33', '#A65628', '#F781BF'],
            '9': ['#E41A1C', '#377EB8', '#4DAF4A', '#984EA3', '#FF7F00', '#FFFF33', '#A65628', '#F781BF', '#999999'],
        },
        set2: {
            '3': ['#66C2A5', '#FC8D62', '#8DA0CB'],
            '4': ['#66C2A5', '#FC8D62', '#8DA0CB', '#E78AC3'],
            '5': ['#66C2A5', '#FC8D62', '#8DA0CB', '#E78AC3', '#A6D854'],
            '6': ['#66C2A5', '#FC8D62', '#8DA0CB', '#E78AC3', '#A6D854', '#FFD92F'],
            '7': ['#66C2A5', '#FC8D62', '#8DA0CB', '#E78AC3', '#A6D854', '#FFD92F', '#E5C494'],
            '8': ['#66C2A5', '#FC8D62', '#8DA0CB', '#E78AC3', '#A6D854', '#FFD92F', '#E5C494', '#B3B3B3'],
        },
        set3: {
            '3': ['#8DD3C7', '#FFFFB3', '#BEBADA'],
            '4': ['#8DD3C7', '#FFFFB3', '#BEBADA', '#FB8072'],
            '5': ['#8DD3C7', '#FFFFB3', '#BEBADA', '#FB8072', '#80B1D3'],
            '6': ['#8DD3C7', '#FFFFB3', '#BEBADA', '#FB8072', '#80B1D3', '#FDB462'],
            '7': ['#8DD3C7', '#FFFFB3', '#BEBADA', '#FB8072', '#80B1D3', '#FDB462', '#B3DE69'],
            '8': ['#8DD3C7', '#FFFFB3', '#BEBADA', '#FB8072', '#80B1D3', '#FDB462', '#B3DE69', '#FCCDE5'],
            '9': ['#8DD3C7', '#FFFFB3', '#BEBADA', '#FB8072', '#80B1D3', '#FDB462', '#B3DE69', '#FCCDE5', '#D9D9D9'],
            '10': ['#8DD3C7', '#FFFFB3', '#BEBADA', '#FB8072', '#80B1D3', '#FDB462', '#B3DE69', '#FCCDE5', '#D9D9D9', '#BC80BD'],
            '11': ['#8DD3C7', '#FFFFB3', '#BEBADA', '#FB8072', '#80B1D3', '#FDB462', '#B3DE69', '#FCCDE5', '#D9D9D9', '#BC80BD', '#CCEBC5'],
            '12': ['#8DD3C7', '#FFFFB3', '#BEBADA', '#FB8072', '#80B1D3', '#FDB462', '#B3DE69', '#FCCDE5', '#D9D9D9', '#BC80BD', '#CCEBC5',
            '#FFED6F'],
        },
        // ngx-color schemes
        vivid: {
            '10': ['#647c8a', '#3f51b5', '#2196f3', '#00b862', '#afdf0a', '#a7b61a', '#f3e562', '#ff9800', '#ff5722', '#ff4514'],
        },
        natural: {
            '10': ['#bf9d76', '#e99450', '#d89f59', '#f2dfa7', '#a5d7c6', '#7794b1', '#afafaf', '#707160', '#ba9383', '#d9d5c3'],
        },
        cool: {
            '10': ['#a8385d', '#7aa3e5', '#a27ea8', '#aae3f5', '#adcded', '#a95963', '#8796c0', '#7ed3ed', '#50abcc', '#ad6886'],
        },
        fire: {
            '8': ['#ff3d00', '#bf360c', '#ff8f00', '#ff6f00', '#ff5722', '#e65100', '#ffca28', '#ffab00'],
        },
        solar: {
            '10': ['#fff8e1', '#ffecb3', '#ffe082', '#ffd54f', '#ffca28', '#ffc107', '#ffb300', '#ffa000', '#ff8f00', '#ff6f00'],
        },
        flame: {
            '10': ['#A10A28', '#D3342D', '#EF6D49', '#FAAD67', '#FDDE90', '#DBED91', '#A9D770', '#6CBA67', '#2C9653', '#146738'],
        },
        ocean: {
            '10': ['#1D68FB', '#33C0FC', '#4AFFFE', '#AFFFFF', '#FFFC63', '#FDBD2D', '#FC8A25', '#FA4F1E', '#FA141B', '#BA38D1'],
        },
        forest: {
            '10': ['#55C22D', '#C1F33D', '#3CC099', '#AFFFFF', '#8CFC9D', '#76CFFA', '#BA60FB', '#EE6490', '#C42A1C', '#FC9F32'],
        },
        horizon: {
            '10': ['#2597FB', '#65EBFD', '#99FDD0', '#FCEE4B', '#FEFCFA', '#FDD6E3', '#FCB1A8', '#EF6F7B', '#CB96E8', '#EFDEE0'],
        },
        neons: {
            '10': ['#FF3333', '#FF33FF', '#CC33FF', '#0000FF', '#33CCFF', '#33FFFF', '#33FF66', '#CCFF33', '#FFCC00', '#FF6600'],
        },
        picnic: {
            '10': ['#FAC51D', '#66BD6D', '#FAA026', '#29BB9C', '#E96B56', '#55ACD2', '#B7332F', '#2C83C9', '#9166B8', '#92E7E8'],
        },
        night: {
            '15': ['#2B1B5A', '#501356', '#183356', '#28203F', '#391B3C', '#1E2B3C', '#120634',
                '#2D0432', '#051932', '#453080', '#75267D', '#2C507D', '#4B3880', '#752F7D', '#35547D'],
        },
        nightLights: {
            '15': ['#4e31a5', '#9c25a7', '#3065ab', '#57468b', '#904497', '#46648b',
                '#32118d', '#a00fb3', '#1052a2', '#6e51bd', '#b63cc3', '#6c97cb', '#8671c1', '#b455be', '#7496c3'],
        },

        // teradata
        covalent: {
            '3': ['#0834C0', '#EF5300', '#E8E305'],
            '4': ['#0834C0', '#EF5300', '#2196F3', '#FF9800'],
            '5': ['#0834C0', '#EF5300', '#2196F3', '#EF6C00', '#E8E305'],
            '6': ['#0834C0', '#EF5300', '#81D4FA', '#1565C0', '#E8E305', '#EF6C00'],
            '8': ['#0834C0', '#EF5300', '#1565C0', '#E8E305', '#2196F3', '#EF6C00', '#81D4FA', '#FF9800'],
        },

        addulate: {
            '3': ['#F55D00', '#0D47A1', '#FFD600'],
            '4': ['#F55D00', '#0D47A1', '#FFD600', '#D50000'],
            '5': ['#F55D00', '#0D47A1', '#FFD600', '#D50000', '#00C853'],
            '6': ['#F55D00', '#0D47A1', '#FFD600', '#D50000', '#00C853', '#AA00FF'],
            '7': ['#F55D00', '#0D47A1', '#FFD600', '#D50000', '#00C853', '#AA00FF', '#00B8D4'],
            '8': ['#F55D00', '#0D47A1', '#FFD600', '#D50000', '#00C853', '#AA00FF', '#00B8D4', '#FF4081'],
            '9': ['#F55D00', '#0D47A1', '#FFD600', '#D50000', '#00C853', '#AA00FF', '#00B8D4', '#FF4081', '#1A237E'],
            '10': ['#F55D00', '#0D47A1', '#FFD600', '#D50000', '#00C853', '#AA00FF', '#00B8D4', '#FF4081', '#1A237E', '#FFCCBC'],
            '11': ['#F55D00', '#0D47A1', '#FFD600', '#D50000', '#00C853', '#AA00FF', '#00B8D4', '#FF4081', '#1A237E', '#FFCCBC', '#795548'],
            '12': ['#F55D00', '#0D47A1', '#FFD600', '#D50000', '#00C853', '#AA00FF', '#00B8D4', '#FF4081', '#1A237E', '#FFCCBC', '#795548',
             '#FFFF8D'],
        },
    },

    diverging: {

        spectral: {
            '3': ['#FC8D59', '#FFFFBF', '#99D594'],
            '4': ['#D7191C', '#FDAE61', '#ABDDA4', '#2B83BA'],
            '5': ['#D7191C', '#FDAE61', '#FFFFBF', '#ABDDA4', '#2B83BA'],
            '6': ['#D53E4F', '#FC8D59', '#FEE08B', '#E6F598', '#99D594', '#3288BD'],
            '7': ['#D53E4F', '#FC8D59', '#FEE08B', '#FFFFBF', '#E6F598', '#99D594', '#3288BD'],
            '8': ['#D53E4F', '#F46D43', '#FDAE61', '#FEE08B', '#E6F598', '#ABDDA4', '#66C2A5', '#3288BD'],
            '9': ['#D53E4F', '#F46D43', '#FDAE61', '#FEE08B', '#FFFFBF', '#E6F598', '#ABDDA4', '#66C2A5', '#3288BD'],
            '10': ['#9E0142', '#D53E4F', '#F46D43', '#FDAE61', '#FEE08B', '#E6F598', '#ABDDA4', '#66C2A5', '#3288BD', '#5E4FA2'],
            '11': ['#9E0142', '#D53E4F', '#F46D43', '#FDAE61', '#FEE08B', '#FFFFBF', '#E6F598', '#ABDDA4', '#66C2A5', '#3288BD', '#5E4FA2'],
            '15': ['#9E0142', '#D53E4F', '#D7191C', '#F46D43', '#FC8D59', '#FDAE61', '#FEE08B', '#FFFFBF', '#E6F598', '#ABDDA4', '#99D594',
                '#66C2A5', '#2B83BA', '#3288BD', '#5E4FA2'],
        },
        rdylgn: {
            '3': ['#FC8D59', '#FFFFBF', '#91CF60'],
            '4': ['#D7191C', '#FDAE61', '#A6D96A', '#1A9641'],
            '5': ['#D7191C', '#FDAE61', '#FFFFBF', '#A6D96A', '#1A9641'],
            '6': ['#D73027', '#FC8D59', '#FEE08B', '#D9EF8B', '#91CF60', '#1A9850'],
            '7': ['#D73027', '#FC8D59', '#FEE08B', '#FFFFBF', '#D9EF8B', '#91CF60', '#1A9850'],
            '8': ['#D73027', '#F46D43', '#FDAE61', '#FEE08B', '#D9EF8B', '#A6D96A', '#66BD63', '#1A9850'],
            '9': ['#D73027', '#F46D43', '#FDAE61', '#FEE08B', '#FFFFBF', '#D9EF8B', '#A6D96A', '#66BD63', '#1A9850'],
            '10': ['#A50026', '#D73027', '#F46D43', '#FDAE61', '#FEE08B', '#D9EF8B', '#A6D96A', '#66BD63', '#1A9850', '#006837'],
            '11': ['#A50026', '#D73027', '#F46D43', '#FDAE61', '#FEE08B', '#FFFFBF', '#D9EF8B', '#A6D96A', '#66BD63', '#1A9850', '#006837'],
            '15': ['#A50026', '#D73027', '#D7191C', '#F46D43', '#FC8D59', '#FDAE61', '#FEE08B', '#FFFFBF', '#D9EF8B', '#A6D96A', '#91CF60',
                '#66BD63', '#1A9641', '#1A9850', '#006837'],
        },
        prgn: {
            '3': ['#AF8DC3', '#F7F7F7', '#7FBF7B'],
            '4': ['#7B3294', '#C2A5CF', '#A6DBA0', '#008837'],
            '5': ['#7B3294', '#C2A5CF', '#F7F7F7', '#A6DBA0', '#008837'],
            '6': ['#762A83', '#AF8DC3', '#E7D4E8', '#D9F0D3', '#7FBF7B', '#1B7837'],
            '7': ['#762A83', '#AF8DC3', '#E7D4E8', '#F7F7F7', '#D9F0D3', '#7FBF7B', '#1B7837'],
            '8': ['#762A83', '#9970AB', '#C2A5CF', '#E7D4E8', '#D9F0D3', '#A6DBA0', '#5AAE61', '#1B7837'],
            '9': ['#762A83', '#9970AB', '#C2A5CF', '#E7D4E8', '#F7F7F7', '#D9F0D3', '#A6DBA0', '#5AAE61', '#1B7837'],
            '10': ['#40004B', '#762A83', '#9970AB', '#C2A5CF', '#E7D4E8', '#D9F0D3', '#A6DBA0', '#5AAE61', '#1B7837', '#00441B'],
            '11': ['#40004B', '#762A83', '#9970AB', '#C2A5CF', '#E7D4E8', '#F7F7F7', '#D9F0D3', '#A6DBA0', '#5AAE61', '#1B7837', '#00441B'],
            '15': ['#40004B', '#762A83', '#7B3294', '#9970AB', '#AF8DC3', '#C2A5CF', '#E7D4E8', '#F7F7F7', '#D9F0D3', '#A6DBA0', '#7FBF7B',
                '#5AAE61', '#008837', '#1B7837', '#00441B'],
        },
        rdbu: {
            '3': ['#EF8A62', '#F7F7F7', '#67A9CF'],
            '4': ['#CA0020', '#F4A582', '#92C5DE', '#0571B0'],
            '5': ['#CA0020', '#F4A582', '#F7F7F7', '#92C5DE', '#0571B0'],
            '6': ['#B2182B', '#EF8A62', '#FDDBC7', '#D1E5F0', '#67A9CF', '#2166AC'],
            '7': ['#B2182B', '#EF8A62', '#FDDBC7', '#F7F7F7', '#D1E5F0', '#67A9CF', '#2166AC'],
            '8': ['#B2182B', '#D6604D', '#F4A582', '#FDDBC7', '#D1E5F0', '#92C5DE', '#4393C3', '#2166AC'],
            '9': ['#B2182B', '#D6604D', '#F4A582', '#FDDBC7', '#F7F7F7', '#D1E5F0', '#92C5DE', '#4393C3', '#2166AC'],
            '10': ['#67001F', '#B2182B', '#D6604D', '#F4A582', '#FDDBC7', '#D1E5F0', '#92C5DE', '#4393C3', '#2166AC', '#053061'],
            '11': ['#67001F', '#B2182B', '#D6604D', '#F4A582', '#FDDBC7', '#F7F7F7', '#D1E5F0', '#92C5DE', '#4393C3', '#2166AC', '#053061'],
            '15': ['#67001F', '#B2182B', '#CA0020', '#D6604D', '#EF8A62', '#F4A582', '#FDDBC7', '#F7F7F7', '#D1E5F0', '#92C5DE', '#67A9CF',
                '#4393C3', '#0571B0', '#2166AC', '#053061'],
        },
        rdgy: {
            '3': ['#EF8A62', '#FFFFFF', '#999999'],
            '4': ['#CA0020', '#F4A582', '#BABABA', '#404040'],
            '5': ['#CA0020', '#F4A582', '#FFFFFF', '#BABABA', '#404040'],
            '6': ['#B2182B', '#EF8A62', '#FDDBC7', '#E0E0E0', '#999999', '#4D4D4D'],
            '7': ['#B2182B', '#EF8A62', '#FDDBC7', '#FFFFFF', '#E0E0E0', '#999999', '#4D4D4D'],
            '8': ['#B2182B', '#D6604D', '#F4A582', '#FDDBC7', '#E0E0E0', '#BABABA', '#878787', '#4D4D4D'],
            '9': ['#B2182B', '#D6604D', '#F4A582', '#FDDBC7', '#FFFFFF', '#E0E0E0', '#BABABA', '#878787', '#4D4D4D'],
            '10': ['#67001F', '#B2182B', '#D6604D', '#F4A582', '#FDDBC7', '#E0E0E0', '#BABABA', '#878787', '#4D4D4D', '#1A1A1A'],
            '11': ['#67001F', '#B2182B', '#D6604D', '#F4A582', '#FDDBC7', '#FFFFFF', '#E0E0E0', '#BABABA', '#878787', '#4D4D4D', '#1A1A1A'],
            '15': ['#67001F', '#B2182B', '#CA0020', '#D6604D', '#EF8A62', '#F4A582', '#FDDBC7', '#FFFFFF', '#E0E0E0', '#BABABA', '#999999',
                '#878787', '#404040', '#4D4D4D', '#1A1A1A'],
        },
        rdylbu: {
            '3': ['#FC8D59', '#FFFFBF', '#91BFDB'],
            '4': ['#D7191C', '#FDAE61', '#ABD9E9', '#2C7BB6'],
            '5': ['#D7191C', '#FDAE61', '#FFFFBF', '#ABD9E9', '#2C7BB6'],
            '6': ['#D73027', '#FC8D59', '#FEE090', '#E0F3F8', '#91BFDB', '#4575B4'],
            '7': ['#D73027', '#FC8D59', '#FEE090', '#FFFFBF', '#E0F3F8', '#91BFDB', '#4575B4'],
            '8': ['#D73027', '#F46D43', '#FDAE61', '#FEE090', '#E0F3F8', '#ABD9E9', '#74ADD1', '#4575B4'],
            '9': ['#D73027', '#F46D43', '#FDAE61', '#FEE090', '#FFFFBF', '#E0F3F8', '#ABD9E9', '#74ADD1', '#4575B4'],
            '10': ['#A50026', '#D73027', '#F46D43', '#FDAE61', '#FEE090', '#E0F3F8', '#ABD9E9', '#74ADD1', '#4575B4', '#313695'],
            '11': ['#A50026', '#D73027', '#F46D43', '#FDAE61', '#FEE090', '#FFFFBF', '#E0F3F8', '#ABD9E9', '#74ADD1', '#4575B4', '#313695'],
            '15': ['#A50026', '#D73027', '#D7191C', '#F46D43', '#FC8D59', '#FDAE61', '#FEE090', '#FFFFBF', '#E0F3F8', '#ABD9E9', '#91BFDB',
                '#74ADD1', '#2C7BB6', '#4575B4', '#313695'],
        },
        piyg: {
            '3': ['#E9A3C9', '#F7F7F7', '#A1D76A'],
            '4': ['#D01C8B', '#F1B6DA', '#B8E186', '#4DAC26'],
            '5': ['#D01C8B', '#F1B6DA', '#F7F7F7', '#B8E186', '#4DAC26'],
            '6': ['#C51B7D', '#E9A3C9', '#FDE0EF', '#E6F5D0', '#A1D76A', '#4D9221'],
            '7': ['#C51B7D', '#E9A3C9', '#FDE0EF', '#F7F7F7', '#E6F5D0', '#A1D76A', '#4D9221'],
            '8': ['#C51B7D', '#DE77AE', '#F1B6DA', '#FDE0EF', '#E6F5D0', '#B8E186', '#7FBC41', '#4D9221'],
            '9': ['#C51B7D', '#DE77AE', '#F1B6DA', '#FDE0EF', '#F7F7F7', '#E6F5D0', '#B8E186', '#7FBC41', '#4D9221'],
            '10': ['#8E0152', '#C51B7D', '#DE77AE', '#F1B6DA', '#FDE0EF', '#E6F5D0', '#B8E186', '#7FBC41', '#4D9221', '#276419'],
            '11': ['#8E0152', '#C51B7D', '#DE77AE', '#F1B6DA', '#FDE0EF', '#F7F7F7', '#E6F5D0', '#B8E186', '#7FBC41', '#4D9221', '#276419'],
            '15': ['#8E0152', '#C51B7D', '#D01C8B', '#DE77AE', '#E9A3C9', '#F1B6DA', '#FDE0EF', '#F7F7F7', '#E6F5D0', '#B8E186', '#A1D76A',
                '#7FBC41', '#4DAC26', '#4D9221', '#276419'],
        },
        puor: {
            '3': ['#F1A340', '#F7F7F7', '#998EC3'],
            '4': ['#E66101', '#FDB863', '#B2ABD2', '#5E3C99'],
            '5': ['#E66101', '#FDB863', '#F7F7F7', '#B2ABD2', '#5E3C99'],
            '6': ['#B35806', '#F1A340', '#FEE0B6', '#D8DAEB', '#998EC3', '#542788'],
            '7': ['#B35806', '#F1A340', '#FEE0B6', '#F7F7F7', '#D8DAEB', '#998EC3', '#542788'],
            '8': ['#B35806', '#E08214', '#FDB863', '#FEE0B6', '#D8DAEB', '#B2ABD2', '#8073AC', '#542788'],
            '9': ['#B35806', '#E08214', '#FDB863', '#FEE0B6', '#F7F7F7', '#D8DAEB', '#B2ABD2', '#8073AC', '#542788'],
            '10': ['#7F3B08', '#B35806', '#E08214', '#FDB863', '#FEE0B6', '#D8DAEB', '#B2ABD2', '#8073AC', '#542788', '#2D004B'],
            '11': ['#7F3B08', '#B35806', '#E08214', '#FDB863', '#FEE0B6', '#F7F7F7', '#D8DAEB', '#B2ABD2', '#8073AC', '#542788', '#2D004B'],
            '15': ['#7F3B08', '#B35806', '#E66101', '#E08214', '#F1A340', '#FDB863', '#FEE0B6', '#F7F7F7', '#D8DAEB', '#B2ABD2', '#998EC3',
                '#8073AC', '#5E3C99', '#542788', '#2D004B'],
        },
        brbg: {
            '3': ['#D8B365', '#F5F5F5', '#5AB4AC'],
            '4': ['#A6611A', '#DFC27D', '#80CDC1', '#018571'],
            '5': ['#A6611A', '#DFC27D', '#F5F5F5', '#80CDC1', '#018571'],
            '6': ['#8C510A', '#D8B365', '#F6E8C3', '#C7EAE5', '#5AB4AC', '#01665E'],
            '7': ['#8C510A', '#D8B365', '#F6E8C3', '#F5F5F5', '#C7EAE5', '#5AB4AC', '#01665E'],
            '8': ['#8C510A', '#BF812D', '#DFC27D', '#F6E8C3', '#C7EAE5', '#80CDC1', '#35978F', '#01665E'],
            '9': ['#8C510A', '#BF812D', '#DFC27D', '#F6E8C3', '#F5F5F5', '#C7EAE5', '#80CDC1', '#35978F', '#01665E'],
            '10': ['#543005', '#8C510A', '#BF812D', '#DFC27D', '#F6E8C3', '#C7EAE5', '#80CDC1', '#35978F', '#01665E', '#003C30'],
            '11': ['#543005', '#8C510A', '#BF812D', '#DFC27D', '#F6E8C3', '#F5F5F5', '#C7EAE5', '#80CDC1', '#35978F', '#01665E', '#003C30'],
            '15': ['#543005', '#8C510A', '#A6611A', '#BF812D', '#D8B365', '#DFC27D', '#F6E8C3', '#F5F5F5', '#C7EAE5', '#80CDC1', '#5AB4AC',
                '#35978F', '#018571', '#01665E', '#003C30'],
        },

        // Teradata's covalent
        covalent: {
            '5': ['#1565C0', '#2196F3', '#81D4FA', '#FF9800', '#EF6C00'],
            '8': ['#0834C0', '#1565C0', '#2196F3', '#81D4FA', '#E8E305', '#FF9800', '#EF6C00', '#EF5300'],
        },

        addulate: {
            '3': ['#2196F6', '#F0F4C3', '#FFB74D'],
            '4': ['#1565C0', '#64B5F6', '#FFD600', '#BF4300'],
            '5': ['#1565C0', '#64B5F6', '#F0F4C3', '#FFD600', '#BF4300'],
            '6': ['#0D47A1', '#2196F3', '#BDBEFB', '#FFFF00', '#FFB74D', '#BF4300'],
            '7': ['#0D47A1', '#2196F3', '#BDBEFB', '#F0F4C3', '#FFFF00', '#FFB74D', '#BF4300'],
            '8': ['#0D47A1', '#1976D2', '#64B5F6', '#BDBEFB', '#FFFF00', '#FFD600', '#FB8C00', '#BF4300'],
            '9': ['#0D47A1', '#1976D2', '#64B5F6', '#BDBEFB', '#F0F4C3', '#FFFF00', '#FFD600', '#FB8C00', '#BF4300'],
            '10': ['#1A237E', '#0D47A1', '#1976D2', '#64B5F6', '#BDBEFB', '#FFFF00', '#FFD600', '#FB8C00', '#BF4300', '#5D4037'],
            '11': ['#1A237E', '#0D47A1', '#1976D2', '#64B5F6', '#BDBEFB', '#F0F4C3', '#FFFF00', '#FFD600', '#FB8C00', '#BF4300', '#5D4037'],
        },
    },

    sequential: {

        reds: {
            '3': ['#FEE0D2', '#FC9272', '#DE2D26'],
            '4': ['#FEE5D9', '#FCAE91', '#FB6A4A', '#CB181D'],
            '5': ['#FEE5D9', '#FCAE91', '#FB6A4A', '#DE2D26', '#A50F15'],
            '6': ['#FEE5D9', '#FCBBA1', '#FC9272', '#FB6A4A', '#DE2D26', '#A50F15'],
            '7': ['#FEE5D9', '#FCBBA1', '#FC9272', '#FB6A4A', '#EF3B2C', '#CB181D', '#99000D'],
            '8': ['#FFF5F0', '#FEE0D2', '#FCBBA1', '#FC9272', '#FB6A4A', '#EF3B2C', '#CB181D', '#99000D'],
            '9': ['#FFF5F0', '#FEE0D2', '#FCBBA1', '#FC9272', '#FB6A4A', '#EF3B2C', '#CB181D', '#A50F15', '#67000D'],
            '13': ['#FFF5F0', '#FEE5D9', '#FEE0D2', '#FCBBA1', '#FCAE91', '#FC9272', '#FB6A4A', '#EF3B2C', '#DE2D26', '#CB181D', '#A50F15',
                '#99000D', '#67000D'],
        },
        ylorrd: {
            '3': ['#FFEDA0', '#FEB24C', '#F03B20'],
            '4': ['#FFFFB2', '#FECC5C', '#FD8D3C', '#E31A1C'],
            '5': ['#FFFFB2', '#FECC5C', '#FD8D3C', '#F03B20', '#BD0026'],
            '6': ['#FFFFB2', '#FED976', '#FEB24C', '#FD8D3C', '#F03B20', '#BD0026'],
            '7': ['#FFFFB2', '#FED976', '#FEB24C', '#FD8D3C', '#FC4E2A', '#E31A1C', '#B10026'],
            '8': ['#FFFFCC', '#FFEDA0', '#FED976', '#FEB24C', '#FD8D3C', '#FC4E2A', '#E31A1C', '#B10026'],
            '9': ['#FFFFCC', '#FFEDA0', '#FED976', '#FEB24C', '#FD8D3C', '#FC4E2A', '#E31A1C', '#BD0026', '#800026'],
            '13': ['#FFFFCC', '#FFFFB2', '#FFEDA0', '#FED976', '#FECC5C', '#FEB24C', '#FD8D3C', '#FC4E2A', '#F03B20',
                '#E31A1C', '#BD0026', '#B10026', '#800026'],
        },
        rdpu: {
            '3': ['#FDE0DD', '#FA9FB5', '#C51B8A'],
            '4': ['#FEEBE2', '#FBB4B9', '#F768A1', '#AE017E'],
            '5': ['#FEEBE2', '#FBB4B9', '#F768A1', '#C51B8A', '#7A0177'],
            '6': ['#FEEBE2', '#FCC5C0', '#FA9FB5', '#F768A1', '#C51B8A', '#7A0177'],
            '7': ['#FEEBE2', '#FCC5C0', '#FA9FB5', '#F768A1', '#DD3497', '#AE017E', '#7A0177'],
            '8': ['#FFF7F3', '#FDE0DD', '#FCC5C0', '#FA9FB5', '#F768A1', '#DD3497', '#AE017E', '#7A0177'],
            '9': ['#FFF7F3', '#FDE0DD', '#FCC5C0', '#FA9FB5', '#F768A1', '#DD3497', '#AE017E', '#7A0177', '#49006A'],
            '13': ['#FFF7F3', '#FEEBE2', '#FDE0DD', '#FCC5C0', '#FBB4B9', '#FA9FB5', '#F768A1', '#DD3497', '#C51B8A',
                '#AE017E', '#7A0177', '#7A0177', '#49006A'],
        },
        ylorbr: {
            '3': ['#FFF7BC', '#FEC44F', '#D95F0E'],
            '4': ['#FFFFD4', '#FED98E', '#FE9929', '#CC4C02'],
            '5': ['#FFFFD4', '#FED98E', '#FE9929', '#D95F0E', '#993404'],
            '6': ['#FFFFD4', '#FEE391', '#FEC44F', '#FE9929', '#D95F0E', '#993404'],
            '7': ['#FFFFD4', '#FEE391', '#FEC44F', '#FE9929', '#EC7014', '#CC4C02', '#8C2D04'],
            '8': ['#FFFFE5', '#FFF7BC', '#FEE391', '#FEC44F', '#FE9929', '#EC7014', '#CC4C02', '#8C2D04'],
            '9': ['#FFFFE5', '#FFF7BC', '#FEE391', '#FEC44F', '#FE9929', '#EC7014', '#CC4C02', '#993404', '#662506'],
            '13': ['#FFFFE5', '#FFFFD4', '#FFF7BC', '#FEE391', '#FED98E', '#FEC44F', '#FE9929', '#EC7014', '#D95F0E',
                '#CC4C02', '#993404', '#8C2D04', '#662506'],
        },
        greens: {
            '3': ['#E5F5E0', '#A1D99B', '#31A354'],
            '4': ['#EDF8E9', '#BAE4B3', '#74C476', '#238B45'],
            '5': ['#EDF8E9', '#BAE4B3', '#74C476', '#31A354', '#006D2C'],
            '6': ['#EDF8E9', '#C7E9C0', '#A1D99B', '#74C476', '#31A354', '#006D2C'],
            '7': ['#EDF8E9', '#C7E9C0', '#A1D99B', '#74C476', '#41AB5D', '#238B45', '#005A32'],
            '8': ['#F7FCF5', '#E5F5E0', '#C7E9C0', '#A1D99B', '#74C476', '#41AB5D', '#238B45', '#005A32'],
            '9': ['#F7FCF5', '#E5F5E0', '#C7E9C0', '#A1D99B', '#74C476', '#41AB5D', '#238B45', '#006D2C', '#00441B'],
            '13': ['#F7FCF5', '#EDF8E9', '#E5F5E0', '#C7E9C0', '#BAE4B3', '#A1D99B', '#74C476', '#41AB5D', '#31A354',
                '#238B45', '#006D2C', '#005A32', '#00441B'],
        },
        gnbu: {
            '3': ['#E0F3DB', '#A8DDB5', '#43A2CA'],
            '4': ['#F0F9E8', '#BAE4BC', '#7BCCC4', '#2B8CBE'],
            '5': ['#F0F9E8', '#BAE4BC', '#7BCCC4', '#43A2CA', '#0868AC'],
            '6': ['#F0F9E8', '#CCEBC5', '#A8DDB5', '#7BCCC4', '#43A2CA', '#0868AC'],
            '7': ['#F0F9E8', '#CCEBC5', '#A8DDB5', '#7BCCC4', '#4EB3D3', '#2B8CBE', '#08589E'],
            '8': ['#F7FCF0', '#E0F3DB', '#CCEBC5', '#A8DDB5', '#7BCCC4', '#4EB3D3', '#2B8CBE', '#08589E'],
            '9': ['#F7FCF0', '#E0F3DB', '#CCEBC5', '#A8DDB5', '#7BCCC4', '#4EB3D3', '#2B8CBE', '#0868AC', '#084081'],
            '13': ['#F7FCF0', '#F0F9E8', '#E0F3DB', '#CCEBC5', '#BAE4BC', '#A8DDB5', '#7BCCC4', '#4EB3D3', '#43A2CA',
                '#2B8CBE', '#0868AC', '#08589E', '#084081'],
        },
        ylgnbu: {
            '3': ['#EDF8B1', '#7FCDBB', '#2C7FB8'],
            '4': ['#FFFFCC', '#A1DAB4', '#41B6C4', '#225EA8'],
            '5': ['#FFFFCC', '#A1DAB4', '#41B6C4', '#2C7FB8', '#253494'],
            '6': ['#FFFFCC', '#C7E9B4', '#7FCDBB', '#41B6C4', '#2C7FB8', '#253494'],
            '7': ['#FFFFCC', '#C7E9B4', '#7FCDBB', '#41B6C4', '#1D91C0', '#225EA8', '#0C2C84'],
            '8': ['#FFFFD9', '#EDF8B1', '#C7E9B4', '#7FCDBB', '#41B6C4', '#1D91C0', '#225EA8', '#0C2C84'],
            '9': ['#FFFFD9', '#EDF8B1', '#C7E9B4', '#7FCDBB', '#41B6C4', '#1D91C0', '#225EA8', '#253494', '#081D58'],
            '13': ['#FFFFD9', '#FFFFCC', '#EDF8B1', '#C7E9B4', '#A1DAB4', '#7FCDBB', '#41B6C4', '#1D91C0', '#2C7FB8',
                '#225EA8', '#253494', '#0C2C84', '#081D58'],
        },
        bupu: {
            '3': ['#E0ECF4', '#9EBCDA', '#8856A7'],
            '4': ['#EDF8FB', '#B3CDE3', '#8C96C6', '#88419D'],
            '5': ['#EDF8FB', '#B3CDE3', '#8C96C6', '#8856A7', '#810F7C'],
            '6': ['#EDF8FB', '#BFD3E6', '#9EBCDA', '#8C96C6', '#8856A7', '#810F7C'],
            '7': ['#EDF8FB', '#BFD3E6', '#9EBCDA', '#8C96C6', '#8C6BB1', '#88419D', '#6E016B'],
            '8': ['#F7FCFD', '#E0ECF4', '#BFD3E6', '#9EBCDA', '#8C96C6', '#8C6BB1', '#88419D', '#6E016B'],
            '9': ['#F7FCFD', '#E0ECF4', '#BFD3E6', '#9EBCDA', '#8C96C6', '#8C6BB1', '#88419D', '#810F7C', '#4D004B'],
            '13': ['#F7FCFD', '#EDF8FB', '#E0ECF4', '#BFD3E6', '#B3CDE3', '#9EBCDA', '#8C96C6', '#8C6BB1', '#8856A7',
                '#88419D', '#810F7C', '#6E016B', '#4D004B'],
        },
        greys: {
            '3': ['#F0F0F0', '#BDBDBD', '#636363'],
            '4': ['#F7F7F7', '#CCCCCC', '#969696', '#525252'],
            '5': ['#F7F7F7', '#CCCCCC', '#969696', '#636363', '#252525'],
            '6': ['#F7F7F7', '#D9D9D9', '#BDBDBD', '#969696', '#636363', '#252525'],
            '7': ['#F7F7F7', '#D9D9D9', '#BDBDBD', '#969696', '#737373', '#525252', '#252525'],
            '8': ['#FFFFFF', '#F0F0F0', '#D9D9D9', '#BDBDBD', '#969696', '#737373', '#525252', '#252525'],
            '9': ['#FFFFFF', '#F0F0F0', '#D9D9D9', '#BDBDBD', '#969696', '#737373', '#525252', '#252525', '#000000'],
            '13': ['#FFFFFF', '#F7F7F7', '#F0F0F0', '#D9D9D9', '#CCCCCC', '#BDBDBD', '#969696', '#737373', '#636363',
                '#525252', '#252525', '#252525', '#000000'],
        },
        oranges: {
            '3': ['#FEE6CE', '#FDAE6B', '#E6550D'],
            '4': ['#FEEDDE', '#FDBE85', '#FD8D3C', '#D94801'],
            '5': ['#FEEDDE', '#FDBE85', '#FD8D3C', '#E6550D', '#A63603'],
            '6': ['#FEEDDE', '#FDD0A2', '#FDAE6B', '#FD8D3C', '#E6550D', '#A63603'],
            '7': ['#FEEDDE', '#FDD0A2', '#FDAE6B', '#FD8D3C', '#F16913', '#D94801', '#8C2D04'],
            '8': ['#FFF5EB', '#FEE6CE', '#FDD0A2', '#FDAE6B', '#FD8D3C', '#F16913', '#D94801', '#8C2D04'],
            '9': ['#FFF5EB', '#FEE6CE', '#FDD0A2', '#FDAE6B', '#FD8D3C', '#F16913', '#D94801', '#A63603', '#7F2704'],
            '13': ['#FFF5EB', '#FEEDDE', '#FEE6CE', '#FDD0A2', '#FDBE85', '#FDAE6B', '#FD8D3C', '#F16913', '#E6550D',
                '#D94801', '#A63603', '#8C2D04', '#7F2704'],
        },
        orrd: {
            '13': ['#FFF7EC', '#FEF0D9', '#FEE8C8', '#FDD49E', '#FDCC8A', '#FDBB84', '#FC8D59', '#EF6548', '#E34A33',
                '#D7301F', '#B30000', '#990000', '#7F0000'],
            '3': ['#FEE8C8', '#FDBB84', '#E34A33'],
            '5': ['#FEF0D9', '#FDCC8A', '#FC8D59', '#E34A33', '#B30000'],
            '4': ['#FEF0D9', '#FDCC8A', '#FC8D59', '#D7301F'],
            '7': ['#FEF0D9', '#FDD49E', '#FDBB84', '#FC8D59', '#EF6548', '#D7301F', '#990000'],
            '6': ['#FEF0D9', '#FDD49E', '#FDBB84', '#FC8D59', '#E34A33', '#B30000'],
            '9': ['#FFF7EC', '#FEE8C8', '#FDD49E', '#FDBB84', '#FC8D59', '#EF6548', '#D7301F', '#B30000', '#7F0000'],
            '8': ['#FFF7EC', '#FEE8C8', '#FDD49E', '#FDBB84', '#FC8D59', '#EF6548', '#D7301F', '#990000'],
        },
        bugn: {
            '3': ['#E5F5F9', '#99D8C9', '#2CA25F'],
            '4': ['#EDF8FB', '#B2E2E2', '#66C2A4', '#238B45'],
            '5': ['#EDF8FB', '#B2E2E2', '#66C2A4', '#2CA25F', '#006D2C'],
            '6': ['#EDF8FB', '#CCECE6', '#99D8C9', '#66C2A4', '#2CA25F', '#006D2C'],
            '7': ['#EDF8FB', '#CCECE6', '#99D8C9', '#66C2A4', '#41AE76', '#238B45', '#005824'],
            '8': ['#F7FCFD', '#E5F5F9', '#CCECE6', '#99D8C9', '#66C2A4', '#41AE76', '#238B45', '#005824'],
            '9': ['#F7FCFD', '#E5F5F9', '#CCECE6', '#99D8C9', '#66C2A4', '#41AE76', '#238B45', '#006D2C', '#00441B'],
            '13': ['#F7FCFD', '#EDF8FB', '#E5F5F9', '#CCECE6', '#B2E2E2', '#99D8C9', '#66C2A4', '#41AE76', '#2CA25F',
                '#238B45', '#006D2C', '#005824', '#00441B'],
        },
        pubu: {
            '3': ['#ECE7F2', '#A6BDDB', '#2B8CBE'],
            '4': ['#F1EEF6', '#BDC9E1', '#74A9CF', '#0570B0'],
            '5': ['#F1EEF6', '#BDC9E1', '#74A9CF', '#2B8CBE', '#045A8D'],
            '6': ['#F1EEF6', '#D0D1E6', '#A6BDDB', '#74A9CF', '#2B8CBE', '#045A8D'],
            '7': ['#F1EEF6', '#D0D1E6', '#A6BDDB', '#74A9CF', '#3690C0', '#0570B0', '#034E7B'],
            '8': ['#FFF7FB', '#ECE7F2', '#D0D1E6', '#A6BDDB', '#74A9CF', '#3690C0', '#0570B0', '#034E7B'],
            '9': ['#FFF7FB', '#ECE7F2', '#D0D1E6', '#A6BDDB', '#74A9CF', '#3690C0', '#0570B0', '#045A8D', '#023858'],
            '13': ['#FFF7FB', '#F1EEF6', '#ECE7F2', '#D0D1E6', '#BDC9E1', '#A6BDDB', '#74A9CF', '#3690C0', '#2B8CBE',
                '#0570B0', '#045A8D', '#034E7B', '#023858'],
        },
        purd: {
            '3': ['#E7E1EF', '#C994C7', '#DD1C77'],
            '4': ['#F1EEF6', '#D7B5D8', '#DF65B0', '#CE1256'],
            '5': ['#F1EEF6', '#D7B5D8', '#DF65B0', '#DD1C77', '#980043'],
            '6': ['#F1EEF6', '#D4B9DA', '#C994C7', '#DF65B0', '#DD1C77', '#980043'],
            '7': ['#F1EEF6', '#D4B9DA', '#C994C7', '#DF65B0', '#E7298A', '#CE1256', '#91003F'],
            '8': ['#F7F4F9', '#E7E1EF', '#D4B9DA', '#C994C7', '#DF65B0', '#E7298A', '#CE1256', '#91003F'],
            '9': ['#F7F4F9', '#E7E1EF', '#D4B9DA', '#C994C7', '#DF65B0', '#E7298A', '#CE1256', '#980043', '#67001F'],
            '13': ['#F7F4F9', '#F1EEF6', '#E7E1EF', '#D4B9DA', '#D7B5D8', '#C994C7', '#DF65B0', '#E7298A', '#DD1C77',
                '#CE1256', '#980043', '#91003F', '#67001F'],
        },
        blues: {
            '3': ['#DEEBF7', '#9ECAE1', '#3182BD'],
            '4': ['#EFF3FF', '#BDD7E7', '#6BAED6', '#2171B5'],
            '5': ['#EFF3FF', '#BDD7E7', '#6BAED6', '#3182BD', '#08519C'],
            '6': ['#EFF3FF', '#C6DBEF', '#9ECAE1', '#6BAED6', '#3182BD', '#08519C'],
            '7': ['#EFF3FF', '#C6DBEF', '#9ECAE1', '#6BAED6', '#4292C6', '#2171B5', '#084594'],
            '8': ['#F7FBFF', '#DEEBF7', '#C6DBEF', '#9ECAE1', '#6BAED6', '#4292C6', '#2171B5', '#084594'],
            '9': ['#F7FBFF', '#DEEBF7', '#C6DBEF', '#9ECAE1', '#6BAED6', '#4292C6', '#2171B5', '#08519C', '#08306B'],
            '13': ['#F7FBFF', '#EFF3FF', '#DEEBF7', '#C6DBEF', '#BDD7E7', '#9ECAE1', '#6BAED6', '#4292C6', '#3182BD',
                '#2171B5', '#08519C', '#084594', '#08306B'],
        },
        pubugn: {
            '3': ['#ECE2F0', '#A6BDDB', '#1C9099'],
            '4': ['#F6EFF7', '#BDC9E1', '#67A9CF', '#02818A'],
            '5': ['#F6EFF7', '#BDC9E1', '#67A9CF', '#1C9099', '#016C59'],
            '6': ['#F6EFF7', '#D0D1E6', '#A6BDDB', '#67A9CF', '#1C9099', '#016C59'],
            '7': ['#F6EFF7', '#D0D1E6', '#A6BDDB', '#67A9CF', '#3690C0', '#02818A', '#016450'],
            '8': ['#FFF7FB', '#ECE2F0', '#D0D1E6', '#A6BDDB', '#67A9CF', '#3690C0', '#02818A', '#016450'],
            '9': ['#FFF7FB', '#ECE2F0', '#D0D1E6', '#A6BDDB', '#67A9CF', '#3690C0', '#02818A', '#016C59', '#014636'],
            '13': ['#FFF7FB', '#F6EFF7', '#ECE2F0', '#D0D1E6', '#BDC9E1', '#A6BDDB', '#67A9CF', '#3690C0', '#1C9099',
                '#02818A', '#016C59', '#016450', '#014636'],
        },
        ylgn: {
            '3': ['#F7FCB9', '#ADDD8E', '#31A354'],
            '4': ['#FFFFCC', '#C2E699', '#78C679', '#238443'],
            '5': ['#FFFFCC', '#C2E699', '#78C679', '#31A354', '#006837'],
            '6': ['#FFFFCC', '#D9F0A3', '#ADDD8E', '#78C679', '#31A354', '#006837'],
            '7': ['#FFFFCC', '#D9F0A3', '#ADDD8E', '#78C679', '#41AB5D', '#238443', '#005A32'],
            '8': ['#FFFFE5', '#F7FCB9', '#D9F0A3', '#ADDD8E', '#78C679', '#41AB5D', '#238443', '#005A32'],
            '9': ['#FFFFE5', '#F7FCB9', '#D9F0A3', '#ADDD8E', '#78C679', '#41AB5D', '#238443', '#006837', '#004529'],
            '13': ['#FFFFE5', '#FFFFCC', '#F7FCB9', '#D9F0A3', '#C2E699', '#ADDD8E', '#78C679', '#41AB5D', '#31A354',
                '#238443', '#006837', '#005A32', '#004529'],
        },
        purples: {
            '3': ['#EFEDF5', '#BCBDDC', '#756BB1'],
            '4': ['#F2F0F7', '#CBC9E2', '#9E9AC8', '#6A51A3'],
            '5': ['#F2F0F7', '#CBC9E2', '#9E9AC8', '#756BB1', '#54278F'],
            '6': ['#F2F0F7', '#DADAEB', '#BCBDDC', '#9E9AC8', '#756BB1', '#54278F'],
            '7': ['#F2F0F7', '#DADAEB', '#BCBDDC', '#9E9AC8', '#807DBA', '#6A51A3', '#4A1486'],
            '8': ['#FCFBFD', '#EFEDF5', '#DADAEB', '#BCBDDC', '#9E9AC8', '#807DBA', '#6A51A3', '#4A1486'],
            '9': ['#FCFBFD', '#EFEDF5', '#DADAEB', '#BCBDDC', '#9E9AC8', '#807DBA', '#6A51A3', '#54278F', '#3F007D'],
            '13': ['#FCFBFD', '#F2F0F7', '#EFEDF5', '#DADAEB', '#CBC9E2', '#BCBDDC', '#9E9AC8', '#807DBA', '#756BB1',
                '#6A51A3', '#54278F', '#4A1486', '#3F007D'],
        },

        // ngx-charts colors
        air: {
            '10': ['#e1f5fe', '#b3e5fc', '#81d4fa', '#4fc3f7', '#29b6f6', '#03a9f4', '#039be5', '#0288d1', '#0277bd', '#01579b'],
        },
        aqua: {
            '10': ['#e0f7fa', '#b2ebf2', '#80deea', '#4dd0e1', '#26c6da', '#00bcd4', '#00acc1', '#0097a7', '#00838f', '#006064'],
        },

        addulate: {
            '3': ['#FAD53E', '#F57C00', '#C62828'],
            '4': ['#FFF59D', '#FF9800', '#F55D00', '#8E24AA'],
            '5': ['#FFF59D', '#FF9800', '#F55D00', '#C62828', '#6A1B9A'],
            '6': ['#FFF59D', '#FFB74D', '#F57C00', '#F55D00', '#C62828', '#6A1B9A'],
            '7': ['#FFF59D', '#FFB74D', '#F57C00', '#F55D00', '#E53935', '#8E24AA', '#4A148C'],
            '8': ['#FFFDE7', '#FAD53E', '#FFB74D', '#F57C00', '#F55D00', '#E53935', '#8E24AA', '#4A148C'],
            '9': ['#FFFDE7', '#FAD53E', '#FFB74D', '#F57C00', '#F55D00', '#E53935', '#8E24AA', '#6A1B9A', '#311B92'],
        },
    },
};
