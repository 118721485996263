import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';

@Component({
    selector: 'gk-repeat-monthly',
    templateUrl: './repeat-monthly.component.html',
    styleUrls: ['./repeat-monthly.component.scss'],
})
export class RepeatMonthlyComponent {

    @Input() parent: FormGroup;

    get nMonths(): AbstractControl {
        return this.parent.get('nMonths');
    }

    get dayOf(): AbstractControl {
        return this.parent.get('dayOf');
    }
}
