import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { environment } from '../../environment';

@Component({
    selector: 'gk-debug-form',
    templateUrl: './debug-form.component.html',
    styleUrls: ['./debug-form.component.scss'],
})
export class DebugFormComponent {
    @Input()
    public formControl: AbstractControl;

    @Input()
    public title: String;

    public get notProduction(): boolean {
        return !environment.production;
    }
}
