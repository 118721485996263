import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import * as AWS from 'aws-sdk';

import { NGXLogger } from 'ngx-logger';

@Injectable({
    providedIn: 'root',
})
export class AwsIoTService {

    constructor(
        private logger: NGXLogger,
    ) {
    }
}
