/// <reference types="googlemaps" />

import { Component, OnInit, Input, ElementRef, ViewChild, NgZone } from '@angular/core';
import { FormControl } from '@angular/forms';
import { HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

import { MapsAPILoader, GoogleMapsAPIWrapper } from '@agm/core';

import { NGXLogger } from 'ngx-logger';

import { ILocation } from '../models/location.model';
import { IRemoteReference } from '../models/data-array.model';
import { LocationService } from '../services/location.service';
import { GoogleMap } from '@agm/core/services/google-maps-types';

@Component({
    selector: 'gk-location-editor',
    templateUrl: './location.editor.component.html',
    styleUrls: ['./location.editor.component.scss'],
})
export class LocationEditorComponent implements OnInit {

    private autocomplete: google.maps.places.Autocomplete;

    private selectedPlace: google.maps.places.PlaceResult;

    private _dirty: boolean = false;

    public location: ILocation;

    public chosenSearchType: string = 'establishment';

    public liveGoogleSearch: boolean = true;

    @ViewChild('search')
    public searchElementRef: ElementRef;

    constructor(
        private mapsAPILoader: MapsAPILoader,
        private ngZone: NgZone,
        private locations: LocationService,
        private logger: NGXLogger,
    ) { }

    ngOnInit(): void {
        try {
            this.mapsAPILoader.load().then(() => {
                if (!this.liveGoogleSearch) {
                    return; // ngIf="liveGoogleSearch" removes key elements below from the DOM
                }
                this.autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
                    types: [this.chosenSearchType],
                });
                this.autocomplete.addListener('place_changed', () => {
                    this.ngZone.run(() => {
                        // get the place result
                        let place: google.maps.places.PlaceResult = this.autocomplete.getPlace();

                        // verify result
                        if (!place.geometry) {
                            return;
                        }

                        this.logger.log(place);
                        this.selectedPlace = place;
                        this.locations.getByGooglePlaceId(place.place_id)
                            .subscribe(
                                (item: ILocation) => { this.location = item; },
                                (error: any) => { this.location = this.convertFromPlace(place); },
                            );
                    });
                });
            });
        } catch (e) {
            //
        }
    }

    get dirty(): boolean {
        return this._dirty;
    }

    get valid(): boolean {
        return !!this.location;
    }

    public markAsPending(): void {
        this._dirty = false;
    }

    onSearchModified($event: any): void {
        this._dirty = true;
    }

    onSearchTypeChange($event: any): void {
        this.autocomplete.setTypes([this.chosenSearchType]);
    }

    convertFromPlace(place: google.maps.places.PlaceResult): ILocation {
        return <ILocation>{
            name: place.name,
            description: place.types.join(', '),
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng(),
            country: place.address_components.find((item: google.maps.GeocoderAddressComponent) => {
                return item.types.includes('country');
            }).short_name,
            formattedAddress: place.formatted_address,
            googlePlaceId: place.place_id,
        };
    }

    // turn off the google search bar and just show the other stuff
    // used when editing a location rather than creating one.
    public setLocation(location: ILocation): void {
        this.liveGoogleSearch = false;
        this.location = location;
    }

    // if location, saves it
    public save(): Observable<ILocation> {
        if (!this.location) { // nothing to save
            return throwError(new Error('Invalid location to save.'));
        } else if (this.location.id) { // update
            return this.locations.update(this.location)
                .pipe(map(() => { return this.location; }));
        } else { // save new
            delete this.location.id;
            return this.locations.create(this.location)
                .pipe(map((response: HttpResponse<IRemoteReference<ILocation>>) => {
                    this.logger.log('return value for location ', response.body.id);
                    this.location.id = response.body.id;
                    // TODO: this.location.id = 'TODO: item id';
                    return this.location;
                }));
        }
    }
}
