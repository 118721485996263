import { MatDialogRef } from '@angular/material';
import { Component } from '@angular/core';

@Component({
    selector: 'gk-password-dialog',
    templateUrl: './password-dialog.component.html',
    styleUrls: ['./password-dialog.component.scss'],
})
export class PasswordDialogComponent {

    public password: string;
    public repassword: string;
    public title: string;
    public message: string;

    constructor(public dialogRef: MatDialogRef<PasswordDialogComponent>) {

    }
}
