import { Component } from '@angular/core';

import { MatDialogRef } from '@angular/material';

import { IColorInfo, ColorsService } from '../../services/colors.service';

@Component({
    selector: 'gk-color-dialog',
    templateUrl: './color-dialog.component.html',
    styleUrls: ['./color-dialog.component.scss'],
})
export class ColorDialogComponent {

    private _startingColorName: string;
    public chosenColorName: string;

    public get startingColorName(): string {
        return this._startingColorName;
    }
    public set startingColorName(colorName: string) {
        this._startingColorName = colorName;
        this.chosenColorName = colorName;
    }

    constructor(
        public dialogRef: MatDialogRef<ColorDialogComponent>,
        public colors: ColorsService,
    ) { }

}
