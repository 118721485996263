import { Pipe, PipeTransform } from '@angular/core';
/*
 * Pad a number with leading zeros to make fullWidth
 * Takes an exponent argument that defaults to 2.
 * Usage:
 *   value | padZeros:fullWidth
 * Example:
 *   {{ 2 | padZeros: 3 }}
 *   formats to: 002
 *   {{ 1234 | padZeros: 3 }}
 *    formats to 1234
*/
@Pipe({ name: 'padZeros' })
export class ZeroPaddingPipe implements PipeTransform {
    transform(value: number, fullWidth: string): string {
        let width: number = parseInt(fullWidth, 10);
        let rawString: string = '' + Math.floor(value);
        if (rawString.length >= width) {
            return '' + value;
        } else {
            return Array(width - rawString.length + 1).join('0') + value;
        }
    }
}
