import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material';

import {
    TdDataTableComponent,
    TdDataTableService,
    TdDataTableSortingOrder,
    ITdDataTableSortChangeEvent,
    ITdDataTableColumn,
    TdDialogService,
    IPageChangeEvent,
} from '@covalent/core';

import { NGXLogger } from 'ngx-logger';

import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

// import { L10nService } from '../l10n/l10n.service';
import { ChannelService, AwsS3Service } from '../services';
import { IChannel } from '../models/channel.model';
import { IDataArray, IRemoteReference } from '../models/data-array.model';

@Component({
    selector: 'gk-channel-list',
    templateUrl: './channel.list.component.html',
    styleUrls: ['./channel.list.component.scss'],
})
export class ChannelListComponent implements OnInit, OnDestroy {

    private subscription: any;

    @ViewChild(TdDataTableComponent) dataTable: TdDataTableComponent;

    data: IChannel[] = [];

    columns: ITdDataTableColumn[] = [
        { name: 'name', label: 'Name', tooltip: 'Channel Title' },
        { name: 'description', label: 'Description', tooltip: 'Channel Title' },
        { name: 'id', label: '' },
    ];

    lookup: Object = {
        name: 'ChannelList.ColHeader.Name',
        description: 'ChannelList.ColHeader.Description',
    };

    filteredData: IChannel[] = this.data;
    filteredTotal: number = this.data.length;

    selectedRows: IChannel[] = [];

    searchTerm: string = '';
    fromRow: number = 1;
    currentPage: number = 1;
    pageSize: number = 50;
    sortBy: string = 'name';
    sortOrder: TdDataTableSortingOrder = TdDataTableSortingOrder.Descending;

    selectedIndexIntoTabGroup: number = 0;

    constructor(private _dataTableService: TdDataTableService,
        private _dialogService: TdDialogService,
        private _channelService: ChannelService,
        private _s3: AwsS3Service,
        private _snackBarService: MatSnackBar,
        private _translate: TranslateService,
        private logger: NGXLogger,
    ) { }

    ngOnInit(): void {
        this.subscription = this._translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.retranslateColumnHeaders();
        });
        this.retranslateColumnHeaders();
        this.filter();
        this.loadChannels();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    onAccountChangeEvent(event: any): void {
        this.logger.log('channel.list: onAccountChangeEvent()');
        this.data = [];
        this.loadChannels();
    }

    onSortEvent(sortEvent: ITdDataTableSortChangeEvent): void {
        this.sortBy = sortEvent.name;
        this.sortOrder = sortEvent.order;
        this.filter();
    }

    onSearchTermEvent(searchTerm: string): void {
        this.searchTerm = searchTerm;
        this.filter();
    }

    onPageChangeEvent(pagingEvent: IPageChangeEvent): void {
        this.fromRow = pagingEvent.fromRow;
        this.currentPage = pagingEvent.page;
        this.pageSize = pagingEvent.pageSize;
        this.filter();
    }

    filter(): void {
        let newData: any[] = this.data;
        newData = this._dataTableService.filterData(newData, this.searchTerm, true);
        this.filteredTotal = newData.length;
        newData = this._dataTableService.sortData(newData, this.sortBy, this.sortOrder);
        newData = this._dataTableService.pageData(newData, this.fromRow, this.currentPage * this.pageSize);
        this.filteredData = newData;
    }

    loadChannels(): void {
        this._channelService.getAll().subscribe(
            (dataArray: IDataArray<IChannel>) => {
                this.data = dataArray.data;
            },
            (err: any) => {
                this.logger.error(err);
            },
            () => {
                this.logger.log('loaded channels', this.data);
                this.filter();
            },
        );
    }

    reload(): void {
        this.data = [];
        this.filter();
        this.loadChannels();
    }

    onDeleteClick(id: string): void {

        let channelName: string = this.data.find((x: IChannel) => x.id === id).name;

        this._dialogService
            .openConfirm({ title: 'Confirmation Required', message: 'Are you sure you want to delete ' + channelName + '?' })
            .afterClosed().subscribe((confirm: boolean) => {
                if (confirm) {
                    this._channelService.delete(id).subscribe(
                        (data: void) => {
                            this.data = this.data.filter((channel: IChannel) => {
                                return channel.id !== id;
                            });
                            this.filteredData = this.filteredData.filter((channel: IChannel) => {
                                return channel.id !== id;
                            });
                            this._snackBarService.open('Channel deleted', 'Ok', { duration: 3000 });
                        }, (error: Error) => {
                            this._dialogService.openAlert({ message: 'There was an error=' + error });
                        },
                    );
                }
            });
    }

    retranslateColumnHeaders(): void {
        this.columns.forEach(async (col: ITdDataTableColumn) => {
            if (col.label) {
                col.label = await this._translate.get(this.lookup[col.name]).toPromise();
            }
        });
        this.dataTable.refresh();
    }
}
