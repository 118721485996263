import { Injectable } from '@angular/core';

export interface IColorInfo {
    name: string;
    primary: string;
    light: string;
    dark: string;
    text: string;
    light_text: string;
    dark_text: string;
}

@Injectable()
export class ColorsService {

    private _opacityDarkTextOnLight: number[] = [0.87, 0.54, 0.38];

    private _opacityLightTextOnDark: number[] = [1.0, 0.7, 0.5];

    private _opacityDarkActiveIcon: number = 0.54;
    private _opacityDarkInactiveIcon: number = 0.38;
    private _opacityLightActiveIcon: number = 1.0;
    private _opacityLightInactiveIcon: number = 0.5;

    private _colors: IColorInfo[] = [
        { name: 'red', primary: '#f44336', light: '#ff7961', dark: '#ba000d', text: '#000000', light_text: '#000000', dark_text: '#ffffff' },
        { name: 'pink', primary: '#e91e63', light: '#ff6090', dark: '#b0003a', text: '#000000', light_text: '#000000', dark_text: '#ffffff' },
        { name: 'purple', primary: '#9c27b0', light: '#d05ce3', dark: '#6a0080', text: '#ffffff', light_text: '#000000', dark_text: '#ffffff' },
        { name: 'deep_purple', primary: '#673ab7', light: '#9a67ea', dark: '#320b86', text: '#ffffff', light_text: '#000000', dark_text: '#ffffff' },
        { name: 'indigo', primary: '#3f51b5', light: '#757de8', dark: '#002984', text: '#ffffff', light_text: '#000000', dark_text: '#ffffff' },
        { name: 'blue', primary: '#2196f3', light: '#6ec6ff', dark: '#0069c0', text: '#000000', light_text: '#000000', dark_text: '#ffffff' },
        /* light blue looks too close to blue
        { name: 'light_blue', primary: '#03a9f4', light: '#67daff', dark: '#007ac1', text: '#000000', light_text: '#000000', dark_text: '#ffffff' },
        */
        { name: 'cyan', primary: '#00bcd4', light: '#62efff', dark: '#008ba3', text: '#000000', light_text: '#000000', dark_text: '#000000' },
        { name: 'teal', primary: '#009688', light: '#52c7b8', dark: '#00675b', text: '#000000', light_text: '#000000', dark_text: '#ffffff' },
        { name: 'green', primary: '#4caf50', light: '#80e27e', dark: '#087f23', text: '#000000', light_text: '#000000', dark_text: '#ffffff' },
        { name: 'light_green', primary: '#8bc34a', light: '#bef67a', dark: '#5a9216', text: '#000000', light_text: '#000000', dark_text: '#000000' },
        { name: 'lime', primary: '#cddc39', light: '#f5fd67', dark: '#99aa00', text: '#000000', light_text: '#000000', dark_text: '#000000' },
        { name: 'yellow', primary: '#ffeb3b', light: '#ffff72', dark: '#c8b900', text: '#000000', light_text: '#000000', dark_text: '#000000' },
        { name: 'amber', primary: '#ffc107', light: '#fff350', dark: '#c79100', text: '#000000', light_text: '#000000', dark_text: '#000000' },
        { name: 'orange', primary: '#ff9800', light: '#ffc947', dark: '#c66900', text: '#000000', light_text: '#000000', dark_text: '#000000' },
        { name: 'deep_orange', primary: '#ff5722', light: '#ff8a50', dark: '#c41c00', text: '#000000', light_text: '#000000', dark_text: '#ffffff' },
        { name: 'brown', primary: '#795548', light: '#a98274', dark: '#4b2c20', text: '#ffffff', light_text: '#000000', dark_text: '#ffffff' },
        { name: 'grey', primary: '#9e9e9e', light: '#cfcfcf', dark: '#707070', text: '#000000', light_text: '#000000', dark_text: '#ffffff' },
        { name: 'bluegrey', primary: '#607d8b', light: '#8eacbb', dark: '#34515e', text: '#000000', light_text: '#000000', dark_text: '#ffffff' },
        { name: 'custom', primary: '#273339', light: '#8eacbb', dark: '#34515e', text: '#000000', light_text: '#000000', dark_text: '#ffffff' },
    ];

    get colors(): IColorInfo[] {
        return this._colors;
    }

    constructor() { }

    colorByName(name: string): IColorInfo {
        return this._colors.find((color: IColorInfo) => color.name === name);
    }

}
