/**
 * video.model.ts
 */

export interface IVideo {
    id?: string;
    title: string;
    description: string;
    thumbnailUrl: string;
    contentUrl: string;
    width: number;
    height: number;
    durationSecs: number;
    bitrateMbps: number;    // deprecated in v0.2
    fileSizeBytes: number; // added in v0.2
    mimeType: string;       // added in v0.2
}

export class VideoUtils {
    static avgBitrateMbps(vid: IVideo): number {
        if (vid.durationSecs > 0 && vid.fileSizeBytes > 0) {
            return vid.fileSizeBytes * 8 / (1024 * 1024 * vid.durationSecs);
        } else {
            return 0;
        }
    }

    static isLandscape(vid: IVideo): boolean {
        return vid.width > vid.height;
    }

    static isPortrait(vid: IVideo): boolean {
        return vid.width < vid.height;
    }

    static aspectRatio(vid: IVideo): string {
        if (VideoUtils.isPortrait(vid) || VideoUtils.isLandscape(vid)) {
            let g: number = VideoUtils.gcd(vid.width, vid.height);
            return '' + (vid.width / g) + ':' + (vid.height / g);
        } else {
            return 'unknown';
        }
    }

    static gcd(a: number, b: number): number {
        a = Math.floor(a);
        b = Math.floor(b);
        while (b !== 0) {
            let t: number = b;
            b = a % b;
            a = t;
        }
        return a;
    }
}
