import { Component, Injectable, OnInit } from '@angular/core';
import { L10nService } from './l10n.service';

@Injectable()
@Component({
    selector: 'gk-l10n',
    templateUrl: './l10n.component.html',
    styleUrls: ['./l10n.component.css'],
})
export class L10nComponent {

    get targetLanguage(): string {
        return this.l10n.getLanguage();
    }

    set targetLanguage(language: string) {
        this.l10n.changeLanguage(language);
    }

    constructor(private l10n: L10nService) {
    }

    changeLanguage(lang: string): void {
        this.targetLanguage = lang;
    }

}
