// angular 4
import {
    NgModule,
    Type,
} from '@angular/core';

import {
    BrowserModule,
    Title,
} from '@angular/platform-browser';

import {
    BrowserAnimationsModule,
} from '@angular/platform-browser/animations';

import {
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';

import {
    FlexLayoutModule,
} from '@angular/flex-layout';

import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

// material
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatTreeModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatGridListModule,
    MatCommonModule,
} from '@angular/material';

// covalent
import {
    CovalentLayoutModule,
    CovalentStepsModule,
    CovalentFileModule,
    CovalentMenuModule,
    CovalentMediaModule,
    CovalentMessageModule,
    CovalentLoadingModule,
    CovalentDataTableModule,
    CovalentCommonModule,
    CovalentSearchModule,
    CovalentPagingModule,
    CovalentDialogsModule,
    CovalentNotificationsModule,
    CovalentExpansionPanelModule,
    TdBytesPipe,
} from '@covalent/core';

import { CovalentHighlightModule } from '@covalent/highlight';
import { CovalentMarkdownModule } from '@covalent/markdown';

// other libraries
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

import { AgmCoreModule } from '@agm/core';

import { NgxChartsModule } from '@swimlane/ngx-charts';

import { TranslateModule, TranslateLoader, MissingTranslationHandler } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { VgCoreModule } from 'videogular2/core';
import { VgControlsModule } from 'videogular2/controls';
import { VgOverlayPlayModule } from 'videogular2/overlay-play';
import { VgBufferingModule } from 'videogular2/buffering';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { DragAndDropModule } from 'angular-draggable-droppable';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

// components
import { WindowRef, DocumentRef } from './utils/browser-globals';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AccountsComponent } from './accounts/accounts.component';
import { AccountFormComponent, CanDeactivateAccountForm } from './accounts/+form/form.component';
import { UsersComponent } from './users/users.component';
import { UserFormComponent, CanDeactivateUserForm } from './users/+form/form.component';
import { DeusModusComponent } from './deus-modus/deus-modus.component';
import { DeusModusScreenComponent } from './deus-modus/screen/deus-modus-screen.component';

import { ScreensComponent } from './screens/screens.component';
import { ScreenEditorComponent, CanDeactivateScreenEditor } from './screens/editors/screen.editor.component';
import { ChannelEditorComponent, CanDeactivateChannelEditor } from './screens/editors/channel.editor.component';

import { UserListComponent } from './lists/user.list.component';
import { VideoListComponent } from './lists/video.list.component';
import { ImageListComponent } from './lists/image.list.component';
import { UrlListComponent } from './lists/url.list.component';
import { ScreenListComponent } from './lists/screen.list.component';
import { ChannelListComponent } from './lists/channel.list.component';
import { PlaylistListComponent } from './lists/playlist.list.component';

import { LocationEditorComponent } from './location/location.editor.component';
import { LocationFormViewComponent } from './location/location.formview.component';
import { LocationMapViewComponent } from './location/location.mapview.component';

import { MediaComponent } from './media/media.component';
import { HashtagFormComponent } from './media/+form/hashtag.form.component';

import { PlaylistsComponent } from './playlists/playlists.component';
import { PlaylistFormComponent, CanDeactivatePlaylistForm } from './playlists/+form/playlist.form.component';
import { ColorButtonComponent } from './widgets/color-button.component';

import { appRoutes, appRoutingProviders } from './app.routes';

import { PasswordDialogComponent } from './dialogs/password-dialog.component';
import { PasswordDialogService } from './dialogs/password-dialog.service';
import { EventDialogComponent } from './dialogs/event-dialog.component';
import { EventDialogService } from './dialogs/event-dialog.service';
import { AboutDialogComponent } from './dialogs/about-dialog.component';
import { LeaveWithoutSaveDialogComponent } from './dialogs/leave-without-save-dialog.component';
import { ScheduleDialogComponent } from './dialogs/schedule/schedule-dialog.component';
import { WhichRecurringEventsDialogComponent } from './dialogs/which-recurring-events-dialog.component';
import { DatetimeLocalComponent } from './dialogs/schedule/datetime-local.component';
import { DurationComponent } from './dialogs/schedule/duration.component';
import { RepeatDailyComponent } from './dialogs/schedule/repeat-daily.component';
import { RepeatWeeklyComponent } from './dialogs/schedule/repeat-weekly.component';
import { RepeatMonthlyComponent } from './dialogs/schedule/repeat-monthly.component';
import { RepeatAnnuallyComponent } from './dialogs/schedule/repeat-annually.component';
import { RepeatUntilComponent } from './dialogs/schedule/repeat-until.component';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { L10nComponent } from './l10n/l10n.component';
import { L10nMissingHandler } from './l10n/l10n.missing.handler';
import { AuthService } from './auth/auth.service';
import { ProfileService } from './auth/profile.service';
import { AuthGuard } from './auth/auth.guard';

import { ZeroPaddingPipe } from './pipes/zeropad.pipe';
import { UTF8Bytes } from './pipes/utf8bytes.pipe';

import {
    UserService,
    AccountService,
    ImageService,
    VideoService,
    UrlService,
    ChannelService,
    ScreenService,
    PlaylistService,
    AwsS3Service,
    AwsCognitoService,
    AwsIoTService,
    LocationService,
    AudienceService,
    ColorsService,
    ScheduledContentService,
    ThemingService,
    HandshakeService,
    PairingService,
} from './services';

import { environment } from '../environments/environment';

import { LayoutsComponent } from './layouts/layouts.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { FinanceComponent } from './finance/finance.component';
import { PlaylistItemEditorComponent } from './playlists/+form/playlistitem.editor.component';

import { DNDContainerDirective } from './dnd/dnd.container.directive';
import { DNDItemDirective } from './dnd/dnd.item.directive';
import { DNDService } from './dnd/dnd.service';
import { ElapsedTimePipe } from './pipes/elapsed-time.pipe';
import { DebounceDirective } from './directives/debounce.directive';
import { ColorDialogComponent } from './dialogs/colors/color-dialog.component';
import { PreviewDialogComponent } from './dialogs/preview-dialog/preview-dialog.component';
import { DomSafePipe } from './pipes/dom-safe.pipe';
import { IANATimezoneValidatorDirective } from './validators/iana-timezone.validator';
import { OperationFailedDialogComponent } from './dialogs/operation-failed-dialog/operation-failed-dialog.component';
import { MediaImageEditorComponent, CanDeactivateMediaImageEditor } from './media/editors/media-image-editor.component';
import { MediaVideoEditorComponent, CanDeactivateMediaVideoEditor } from './media/editors/media-video-editor.component';
import { MediaUrlEditorComponent, CanDeactivateMediaUrlEditor } from './media/editors/media-url-editor.component';
import { PairingDialogComponent } from './dialogs/pairing-dialog/pairing-dialog.component';
import { AdCampaignsComponent } from './ad-campaigns/ad-campaigns.component';
import { SysOpsComponent } from './sys-ops/sys-ops.component';
import { UserDetailComponent } from './users/user-detail/user-detail.component';
import { UserEditorComponent, CanDeactivateUserEditor } from './users/user-editor/user-editor.component';
import { DebugFormComponent } from './debug/debug-form/debug-form.component';
import { DebugResponsiveComponent } from './debug/debug-responsive/debug-responsive.component';
import { AccountTreeComponent } from './user-menu/account-tree/account-tree.component';
import { ScreenAudienceComponent } from './screens/screen-audience.component';
import { GenerateUrlDialogComponent } from './dialogs/generateUrl-dialog/generateUrl-dialog.component';
import { UrlComponent } from './URL/url.component';
// AoT requires an exported function for factories

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        //url
        UrlComponent,
        DashboardComponent,
        UserMenuComponent,
        AccountsComponent,
        AccountFormComponent,
        UsersComponent,
        UserFormComponent,
        LoginComponent,
        DeusModusComponent,
        DeusModusScreenComponent,
        L10nComponent,
        MediaComponent,
        PlaylistsComponent,
        HashtagFormComponent,
        ScreenEditorComponent,
        ChannelEditorComponent,
        LayoutsComponent,
        ScheduleComponent,
        ScreensComponent,
        FinanceComponent,
        UserListComponent,
        ImageListComponent,
        VideoListComponent,
        UrlListComponent,
        ScreenListComponent,
        ChannelListComponent,
        PlaylistListComponent,
        PlaylistFormComponent,
        PlaylistItemEditorComponent,
        ColorButtonComponent,
        LocationEditorComponent,
        LocationFormViewComponent,
        LocationMapViewComponent,
        DNDItemDirective,
        DNDContainerDirective,
        ElapsedTimePipe,
        PasswordDialogComponent,
        EventDialogComponent,
        AboutDialogComponent,
        LeaveWithoutSaveDialogComponent,
        ScheduleDialogComponent,
        ColorDialogComponent,
        WhichRecurringEventsDialogComponent,
        DatetimeLocalComponent,
        DurationComponent,
        RepeatDailyComponent,
        RepeatWeeklyComponent,
        RepeatMonthlyComponent,
        RepeatAnnuallyComponent,
        RepeatUntilComponent,
        DebounceDirective,
        ZeroPaddingPipe,
        UTF8Bytes,
        PreviewDialogComponent,
        DomSafePipe,
        IANATimezoneValidatorDirective,
        OperationFailedDialogComponent,
        MediaImageEditorComponent,
        MediaVideoEditorComponent,
        MediaUrlEditorComponent,
        PairingDialogComponent,
        GenerateUrlDialogComponent,
        AdCampaignsComponent,
        SysOpsComponent,
        UserDetailComponent,
        UserEditorComponent,
        DebugFormComponent,
        DebugResponsiveComponent,
        AccountTreeComponent,
        ScreenAudienceComponent,
    ], // directives, components, and pipes owned by this NgModule

    imports: [
        LoggerModule.forRoot({ serverLoggingUrl: '/api/logs', level: NgxLoggerLevel.DEBUG, serverLogLevel: NgxLoggerLevel.OFF }),
        // Angular
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        HttpClientModule,

        // Material
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatCommonModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatTreeModule,
        MatMenuModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatTabsModule,
        MatToolbarModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatTooltipModule,
        MatDialogModule,
        MatGridListModule,

        // Covalent
        CovalentLayoutModule,
        CovalentStepsModule,
        CovalentFileModule,
        CovalentMenuModule,
        CovalentMediaModule,
        CovalentMessageModule,
        CovalentLoadingModule,
        CovalentDataTableModule,
        CovalentCommonModule,
        CovalentSearchModule,
        CovalentPagingModule,
        CovalentDialogsModule,
        CovalentNotificationsModule,
        CovalentExpansionPanelModule,
        CovalentHighlightModule,
        CovalentMarkdownModule,

        // other
        AgmCoreModule.forRoot({
            apiKey: environment.googleKey,
            libraries: ['places'],
        }),

        // TranslateModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
            // prefix: '/assets/i18n', // opt
            // suffix: '.json' //opt
        }),
        NgxChartsModule,
        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
        DragAndDropModule,
        appRoutes,
    ], // modules needed to run this module

    providers: [
        TdBytesPipe,
        appRoutingProviders,
        Title,
        AuthGuard,
        AuthService,
        ProfileService,
        UserService,
        AccountService,
        ImageService,
        VideoService,
        UrlService,
        ScreenService,
        ChannelService,
        PlaylistService,
        AwsS3Service,
        AwsCognitoService,
        AwsIoTService,
        LocationService,
        AudienceService,
        DNDService,
        PasswordDialogService,
        ColorsService,
        ThemingService,
        HandshakeService,
        PairingService,
        ScheduledContentService,
        CanDeactivatePlaylistForm,
        CanDeactivateScreenEditor,
        CanDeactivateChannelEditor,
        CanDeactivateUserForm,
        CanDeactivateUserEditor,
        CanDeactivateAccountForm,
        CanDeactivateMediaImageEditor,
        CanDeactivateMediaVideoEditor,
        CanDeactivateMediaUrlEditor,
        EventDialogService,
    ], // additional providers needed for this module

    entryComponents: [
        PasswordDialogComponent,
        AboutDialogComponent,
        LeaveWithoutSaveDialogComponent,
        ScheduleDialogComponent,
        ColorDialogComponent,
        WhichRecurringEventsDialogComponent,
        PreviewDialogComponent,
        OperationFailedDialogComponent,
        PairingDialogComponent,
        GenerateUrlDialogComponent
    ],

    bootstrap: [AppComponent],
})
export class AppModule { }
