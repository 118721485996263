import { Component, Input, Output, EventEmitter } from '@angular/core';

import { MatDialog, MatDialogRef } from '@angular/material';

import { ColorDialogComponent } from '../dialogs/colors/color-dialog.component';
import { ColorsService, IColorInfo } from '../services/colors.service';

@Component({
    selector: 'gk-color-button',
    templateUrl: './color-button.component.html',
    styleUrls: ['./color-button.component.scss'],
})
export class ColorButtonComponent {
    private _colorName: string;
    public colorHex: string;
    public textHex: string;

    @Output() colorChanged: EventEmitter<string> = new EventEmitter<string>();

    @Input()
    get colorName(): string {
        return this._colorName;
    }
    set colorName(colorName: string) {
        this._colorName = colorName;
        let colorInfo: IColorInfo = this.colorService.colorByName(this.colorName);
        if (!!colorInfo) {
            this.colorHex = colorInfo.primary;
            this.textHex = colorInfo.dark_text;
            this.colorChanged.emit(this._colorName);
        }
    }

    constructor(
        private dialog: MatDialog,
        private colorService: ColorsService,
    ) { }

    chooseColor(): void {
        let dialogRef: MatDialogRef<ColorDialogComponent> = this.dialog.open(ColorDialogComponent);
        dialogRef.componentInstance.startingColorName = this.colorName;
        dialogRef.afterClosed().subscribe((resultingColorName: string) => {
            if (resultingColorName) {
                this.colorName = resultingColorName;
            }
        });
    }
}
