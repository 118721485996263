import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ILocation } from '../models/location.model';
import { IDataArray, IRemoteReference } from '../models/data-array.model';

import { AuthService } from '../auth/auth.service';
import { ProfileService } from '../auth/profile.service';
import { environment } from '../../environments/environment';

@Injectable()
export class LocationService {

    get headers(): HttpHeaders {
        let headers: HttpHeaders = new HttpHeaders();
        headers = this.auth.addAuthHttpHeaders(headers);
        headers = this.profile.addProfileHttpHeaders(headers);
        return headers;
    }

    constructor(
        private http: HttpClient,
        private auth: AuthService,
        private profile: ProfileService,
    ) { }

    getAll(): Observable<IDataArray<ILocation>> {
        let url: string = environment.apiEndpoint + '/locations';
        return this.http.get<IDataArray<ILocation>>(url, { headers: this.headers });
    }

    getById(id: string): Observable<ILocation> {
        let url: string = environment.apiEndpoint + '/locations/' + id;
        return this.http.get<ILocation>(url, { headers: this.headers });
    }

    getByGooglePlaceId(gid: string): Observable<ILocation> {
        let url: string = environment.apiEndpoint + '/locations/googlePlaceId/' + gid;
        return this.http.get<ILocation>(url, { headers: this.headers });
    }

    create(location: ILocation): Observable<HttpResponse<IRemoteReference<ILocation>>> {
        let url: string = environment.apiEndpoint + '/locations';
        return this.http.post<IRemoteReference<ILocation>>(url, location, { headers: this.headers, observe: 'response' });
    }

    update(location: ILocation): Observable<void> {
        let url: string = environment.apiEndpoint + `/locations/${location.id}`;
        return this.http.put<void>(url, location, { headers: this.headers });
    }

    delete(id: string): Observable<void> {
        let url: string = environment.apiEndpoint + '/locations/' + id;
        return this.http.delete<void>(url, { headers: this.headers });
    }
}
