import { TdBytesPipe } from '@covalent/core';
import * as AWS from 'aws-sdk';

export class FileUploadInfo {
    localFile: File;
    statusMessage: string;
    data: { Bucket: string, ETag: string, Key: string, Location: string };
    fractionUploaded: number = 0;
    nBytesLoadedRatio: string = '';
    dirty: boolean = false;
    extension: string;
    public managedUpload: AWS.S3.ManagedUpload;

    reset(message: string): void {
        this.localFile = undefined;
        this.statusMessage = message;
        this.data = undefined;
        this.fractionUploaded = 0;
        this.nBytesLoadedRatio = undefined;
        this.dirty = false;
        this.extension = undefined;
        this.managedUpload = undefined;
    }

    public abort(): void {
        if (this.managedUpload) {
            this.managedUpload.abort();
        }
    }

    selectFile(file: File, message: string): void {
        this.localFile = file;
        this.statusMessage = message;
        this.dirty = true;
        this.extension = '.' + file.name.split('.').pop();
    }

    progress(bytesLoaded: number, bytesTotal: number, bytesPipe: TdBytesPipe, message: string): void {
        this.nBytesLoadedRatio = bytesPipe.transform(bytesLoaded) + ' / ' + bytesPipe.transform(bytesTotal);
        this.statusMessage = message;
        let temp: number = (bytesTotal > 0) ? bytesLoaded / bytesTotal : 0.0;
        this.fractionUploaded = (temp > 1) ? 1.0 : temp;
        this.dirty = true;
    }

    error(err: any): void {
        this.statusMessage = err;
        this.dirty = true;
    }

    // data =
    //    Bucket: 'media.test.addulate.com'
    //    ETag: '{some digital receipt}'
    //    Key: 'account_N/user_M/file'
    //    Location: 'https://s3-uswest-2.amazonaws.com/{{Bucket}}/{{Key}}'
    complete(data: any, message: string): void {
        this.statusMessage = message;
        this.data = data;
        this.dirty = true;
    }
}
