import { Component } from '@angular/core';

import { L10nService } from '../l10n/l10n.service';

@Component({
    selector: 'gk-finance',
    templateUrl: './finance.component.html',
    styleUrls: ['./finance.component.css'],
    providers: [L10nService],
})
export class FinanceComponent {

}
