import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';

@Component({
    selector: 'gk-repeat-daily',
    templateUrl: './repeat-daily.component.html',
    styleUrls: ['./repeat-daily.component.scss'],
})
export class RepeatDailyComponent {

    @Input() parent: FormGroup;

    get nDays(): AbstractControl {
        return this.parent.get('nDays');
    }
}
