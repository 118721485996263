import { Component, OnInit, Input, ViewChild, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';

import { MatSnackBar } from '@angular/material';
import { MatDialog, MatDialogRef } from '@angular/material';

import {
    TdDataTableComponent,
    TdDataTableService,
    TdDataTableSortingOrder,
    ITdDataTableSortChangeEvent,
    ITdDataTableColumn,
    TdDialogService,
    IPageChangeEvent,
} from '@covalent/core';

import { NGXLogger } from 'ngx-logger';

import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { PlaylistService } from '../services';
import { IPlaylist } from '../models/playlist.model';
import { IDataArray, IRemoteReference } from '../models/data-array.model';
import { PreviewDialogComponent } from '../dialogs/preview-dialog/preview-dialog.component';
import { environment } from '../../environments/environment';
import { ProfileService } from '../auth/profile.service';
import { AuthService } from '../auth/auth.service';

@Component({
    selector: 'gk-playlist-list',
    templateUrl: './playlist.list.component.html',
    styleUrls: ['./playlist.list.component.scss'],
})
export class PlaylistListComponent implements OnInit, OnDestroy, OnChanges {

    private subscription: any;

    @Input()
    isEditable: boolean = true;

    @ViewChild(TdDataTableComponent) dataTable: TdDataTableComponent;

    data: IPlaylist[] = [];

    columnsWithEdit: ITdDataTableColumn[] = [
        { name: 'title', label: 'Title', tooltip: 'IPlaylist Title' },
        // { name: 'thumbnailUrl', label: 'Thumbnail' },
        { name: 'durationSecs', label: 'Duration' },
        { name: 'id', label: '' },
    ];

    columnsWithoutEdit: ITdDataTableColumn[] = [
        { name: 'title', label: 'Title', tooltip: 'IPlaylist Title' },
        // { name: 'thumbnailUrl', label: 'Thumbnail' },
        { name: 'durationSecs', label: 'Duration' },
    ];

    lookup: Object = {
        title: 'PlaylistList.ColHeader.Title',
        thumbnailUrl: 'PlaylistList.ColHeader.ThumbnailURL',
        durationSecs: 'PlaylistList.ColHeader.DurationSecs',
    };

    columns: ITdDataTableColumn[] = this.columnsWithEdit;

    filteredData: IPlaylist[] = this.data;
    filteredTotal: number = this.data.length;

    selectedRows: IPlaylist[] = [];

    searchTerm: string = '';
    fromRow: number = 1;
    currentPage: number = 1;
    pageSize: number = 50;
    sortBy: string = 'title';
    sortOrder: TdDataTableSortingOrder = TdDataTableSortingOrder.Descending;

    selectedIndexIntoTabGroup: number = 0;

    constructor(private _dataTableService: TdDataTableService,
        private _dialogService: TdDialogService,
        private _playlistService: PlaylistService,
        private _snackBarService: MatSnackBar,
        private _translate: TranslateService,
        private logger: NGXLogger,
        private dialog: MatDialog,
        private auth: AuthService,
    ) { }

    ngOnInit(): void {
        this.subscription = this._translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.retranslateColumnHeaders();
        });
        this.retranslateColumnHeaders();
        this.filter();
        this.loadPlaylists();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.isEditable) {
            if (changes.isEditable.currentValue) {
                this.columns = this.columnsWithEdit;
            } else {
                this.columns = this.columnsWithoutEdit;
            }
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
    onAccountChangeEvent(event: any): void {
        this.logger.log('playlist.list: onAccountChangeEvent()');
        this.data = [];
        this.loadPlaylists();
    }

    onSortEvent(sortEvent: ITdDataTableSortChangeEvent): void {
        this.sortBy = sortEvent.name;
        this.sortOrder = sortEvent.order;
        this.filter();
    }

    onSearchTermEvent(searchTerm: string): void {
        this.searchTerm = searchTerm;
        this.filter();
    }

    onPageChangeEvent(pagingEvent: IPageChangeEvent): void {
        this.fromRow = pagingEvent.fromRow;
        this.currentPage = pagingEvent.page;
        this.pageSize = pagingEvent.pageSize;
        this.filter();
    }

    onPreviewClick(id: string): void {
        let url: string = environment.screenUrl + '/#/playlists/' + id + '?' +
            't=' + this.auth.getToken();

        const effectiveAccount: string = ProfileService.profileSingleton().effectiveAccountID;
        if (effectiveAccount) {
            url += '&a=' + effectiveAccount;
        }
        const dialogRef: MatDialogRef<PreviewDialogComponent> = this.dialog.open(PreviewDialogComponent, {
            data: { url: url },
        });
        dialogRef.afterClosed().subscribe();
    }

    onDeleteEvent(id: string): void {

        let title: string = this.data.find((x: IPlaylist) => x.id === id).title;

        this._dialogService
            .openConfirm({ title: 'Confirmation Required', message: 'Are you sure you want to delete ' + title + '?' })
            .afterClosed().subscribe((confirm: boolean) => {
                if (confirm) {
                    this._playlistService.delete(id).subscribe(
                        (data: void) => {
                            this.logger.log(data);
                            this.data = this.data.filter((playlist: IPlaylist) => {
                                return playlist.id !== id;
                            });
                            this.filteredData = this.filteredData.filter((playlist: IPlaylist) => {
                                return playlist.id !== id;
                            });
                            this._snackBarService.open('Playlist deleted', 'Ok', { duration: 3000 });
                        },
                        (error: Error) => {
                            this._dialogService.openAlert({ message: 'There was an error=' + error });
                        },
                    );
                }
            });
    }

    filter(): void {
        let newData: any[] = this.data;
        newData = this._dataTableService.filterData(newData, this.searchTerm, true);
        this.filteredTotal = newData.length;
        newData = this._dataTableService.sortData(newData, this.sortBy, this.sortOrder);
        newData = this._dataTableService.pageData(newData, this.fromRow, this.currentPage * this.pageSize);
        this.filteredData = newData;
    }

    loadPlaylists(): void {
        this._playlistService.getAll().subscribe(
            (dataArray: IDataArray<IPlaylist>) => {
                this.data = dataArray.data;
            },
            (err: any) => {
                this.logger.error(err);
            },
            () => {
                this.logger.log('loaded playlists', this.data);
                this.filter();
            },
        );
    }

    reload(): void {
        this.data = [];
        this.filter();
        this.loadPlaylists();
    }

    onDeletePlaylistEvent(id: string): void {

        let playlistTitle: string = this.data.find((x: IPlaylist) => x.id === id).title;

        this._dialogService
            .openConfirm({ title: 'Confirmation Required', message: 'Are you sure you want to delete ' + playlistTitle + '?' })
            .afterClosed().subscribe((confirm: boolean) => {
                if (confirm) {
                    this._playlistService.delete(id).subscribe(
                        (data: any) => {
                            this.logger.log(data);
                            this.data = this.data.filter((playlist: IPlaylist) => {
                                return playlist.id !== id;
                            });
                            this.filteredData = this.filteredData.filter((playlist: IPlaylist) => {
                                return playlist.id !== id;
                            });
                            this._snackBarService.open('Playlist deleted', 'Ok', { duration: 3000 });
                        },
                        (error: Error) => {
                            this._dialogService.openAlert({ message: 'There was an error=' + error });
                        },
                    );
                }
            });
    }


    onDuplicateEvent(id){
        let playlistDuplicate: any ;
        
        console.log(id.id)
        this._playlistService.getById(id.id).subscribe(
            (playlist: IPlaylist) => {
                playlistDuplicate=playlist
                for(let i=0;i<playlistDuplicate.items.length;i++){
                    id=""
                    playlistDuplicate.items[i].id=id
                }
                const dp: IPlaylist = <IPlaylist>{
                    title: playlistDuplicate.title + " Copy",
                    description: playlistDuplicate.description,
                    thumbnailUrl: playlistDuplicate.thumbnailUrl,
                    durationSecs: playlistDuplicate.durationSecs,
                    items: playlistDuplicate.items,
                    
                };
                this._playlistService.create(dp).subscribe(
                    (data: IRemoteReference<IPlaylist>) =>{
                        this.ngOnInit()
                         this.goBack()
                        },
                    (err: any) => this.reportSaveError(err),
                );
            },
            (err: any) => {
                this.logger.error('Error: PlaylistService ', err.msg);
                this.goBack();
            },
        );
      
        this.ngOnInit();
    }
    goBack() {
        throw new Error('Method not implemented.');
    }
    reportSaveError(err: any): void {
        throw new Error('Method not implemented.');
    }

    retranslateColumnHeaders(): void {
        this.columns.forEach(async (col: ITdDataTableColumn) => {
            if (col.label) {
                col.label = await this._translate.get(this.lookup[col.name]).toPromise();
            }
        });
        this.dataTable.refresh();
    }
}
