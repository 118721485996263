import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { IImage } from '../models/image.model';
import { AuthService } from '../auth/auth.service';
import { ProfileService } from '../auth/profile.service';
import { IDataArray, IRemoteReference } from '../models/data-array.model';
import { environment } from '../../environments/environment';

@Injectable()
export class ImageService {

    get headers(): HttpHeaders {
        let headers: HttpHeaders = new HttpHeaders();
        headers = this.auth.addAuthHttpHeaders(headers);
        headers = this.profile.addProfileHttpHeaders(headers);
        return headers;
    }

    constructor(
        private http: HttpClient,
        private auth: AuthService,
        private profile: ProfileService,
    ) { }

    getAll(): Observable<IDataArray<IImage>> {
        let url: string = environment.apiEndpoint + '/images';
        return this.http.get<IDataArray<IImage>>(url, { headers: this.headers });
    }

    getById(id: string): Observable<IImage> {
        let url: string = environment.apiEndpoint + '/images/' + id;
        return this.http.get<IImage>(url, { headers: this.headers });
    }

    create(image: IImage): Observable<IRemoteReference<IImage>> {
        let url: string = environment.apiEndpoint + '/images';
        console.log("in create method")
        return this.http.post<IRemoteReference<IImage>>(url, image, { headers: this.headers });
    }

    update(image: IImage): Observable<void> {
        let url: string = environment.apiEndpoint + '/images/' + image.id;
        return this.http.put<void>(url, image, { headers: this.headers });
    }

    delete(id: string): Observable<void> {
        let url: string = environment.apiEndpoint + '/images/' + id;
        return this.http.delete<void>(url, { headers: this.headers });
    }
}
