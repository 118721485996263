import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { NGXLogger } from 'ngx-logger';

import { AuthService } from './auth.service';
import { ProfileService } from './profile.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private auth: AuthService,
        private logger: NGXLogger,
        private profile: ProfileService,
    ) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        let token: string = this.auth.getToken();
        console.log("token = ", token);

        if (next.url.length > 0 && next.url[0].path === 'login') {
            if (this.auth.isLoggedIn()) {
                this.logger.log('AuthGuard: Rerouting from /login to /. User already authenticated.');
                this.router.navigate(['']);
                return false;
            } else {
                return true;
            }
        }

        if (this.auth.isLoggedIn()) {
            return true;
        } else {
            this.logger.log('AuthGuard: Rerouting to /login. User needs to be authenticated.');
            console.log("here login")
            this.router.navigate(['/login']);
            return false;
        }
    }
}
