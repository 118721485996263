import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { IPlaylist } from '../models/playlist.model';
import { AuthService } from '../auth/auth.service';
import { ProfileService } from '../auth/profile.service';
import { IDataArray, IRemoteReference } from '../models/data-array.model';
import { environment } from '../../environments/environment';

@Injectable()
export class PlaylistService {

    get headers(): HttpHeaders {
        let headers: HttpHeaders = new HttpHeaders();
        headers = this.auth.addAuthHttpHeaders(headers);
        headers = this.profile.addProfileHttpHeaders(headers);
        return headers;
    }

    get params(): HttpParams {
        return new HttpParams().set('expand', 'items');
    }

    constructor(
        private http: HttpClient,
        private auth: AuthService,
        private profile: ProfileService,
    ) { }

    getAll(): Observable<IDataArray<IPlaylist>> {
        let url: string = environment.apiEndpoint + '/playlists';
        return this.http.get<IDataArray<IPlaylist>>(url, { headers: this.headers });
    }

    getById(id: string): Observable<IPlaylist> {
        let url: string = environment.apiEndpoint + '/playlists/' + id;
        return this.http.get<IPlaylist>(url, { headers: this.headers, params: this.params });
    }

    create(playlist: IPlaylist): Observable<IRemoteReference<IPlaylist>> {
        let url: string = environment.apiEndpoint + '/playlists';
        return this.http.post<IRemoteReference<IPlaylist>>(url, playlist, { headers: this.headers });
    }

    update(playlist: IPlaylist): Observable<void> {
        let url: string = environment.apiEndpoint + '/playlists/' + playlist.id;
        return this.http.put<void>(url, playlist, { headers: this.headers });
    }

    delete(id: string): Observable<void> {
        let url: string = environment.apiEndpoint + '/playlists/' + id;
        return this.http.delete<void>(url, { headers: this.headers });
    }
}
