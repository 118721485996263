import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { IScreen } from '../models/screen.model';
import { AuthService } from '../auth/auth.service';
import { ProfileService } from '../auth/profile.service';
import { IDataArray, IRemoteReference } from '../models/data-array.model';
import { environment } from '../../environments/environment';

@Injectable()
export class ScreenService {

    get headers(): HttpHeaders {
        let headers: HttpHeaders = new HttpHeaders();
        headers = this.auth.addAuthHttpHeaders(headers);
        headers = this.profile.addProfileHttpHeaders(headers);
        return headers;
    }

    get params(): HttpParams {
        return new HttpParams().set('expand', 'location');
    }

    constructor(
        private http: HttpClient,
        private auth: AuthService,
        private profile: ProfileService,
    ) { }

    getAll(): Observable<IDataArray<IScreen>> {
        let url: string = environment.apiEndpoint + '/screens';
        return this.http.get<IDataArray<IScreen>>(url, { headers: this.headers, params: this.params });
    }

    getById(id: string): Observable<IScreen> {
        let url: string = environment.apiEndpoint + '/screens/' + id;
        return this.http.get<IScreen>(url, { headers: this.headers, params: this.params });
    }

    create(screen: IScreen): Observable<HttpResponse<IRemoteReference<IScreen>>> {
        let url: string = environment.apiEndpoint + '/screens';
        return this.http.post<IRemoteReference<IScreen>>(url, screen, { headers: this.headers, observe: 'response' });
    }

    update(screen: IScreen): Observable<void> {
        let url: string = environment.apiEndpoint + '/screens/' + screen.id;
        return this.http.put<void>(url, screen, { headers: this.headers });
    }

    delete(id: string): Observable<void> {
        let url: string = environment.apiEndpoint + '/screens/' + id;
        return this.http.delete<void>(url, { headers: this.headers });
    }

    // we intentionally don't store the password in the user model,
    // so there's a not-very-CRUD-like api specially for passwords
    pairScreen(id: string, hardwareId: string, password: string): Observable<void> {
        const url: string = environment.apiEndpoint + '/screens/' + id;
        const body: any = { hardwareId: hardwareId, password: password };
        return this.http.put<void>(url, body, { headers: this.headers });
    }

    unpairScreen(id: string): Observable<void> {
        // tslint:disable:no-null-keyword
        return this.pairScreen(id, null, null);
    }

    createScreenUrl(obj){
        const url: string = environment.apiEndpoint + '/screens-urls';

        return this.http.post(url,obj, { headers: this.headers});
    }
    getScreenUrl(id){
        const url: string = environment.apiEndpoint + '/screens-urls';

        return this.http.get(url+"/"+id, { headers: this.headers});
    }

}
