import { Component, OnInit } from '@angular/core';
import { ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';

import { MatIconModule, MatDialog, MatDialogRef } from '@angular/material';

import { NGXLogger } from 'ngx-logger';

import { L10nService } from '../l10n/l10n.service';
import { AuthService } from '../auth/auth.service';
import { ProfileService, ProfileSingleton } from '../auth/profile.service';
import { AboutDialogComponent } from '../dialogs/about-dialog.component';

@Component({
    selector: 'gk-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
    providers: [AuthService, L10nService],
})
export class MainComponent implements OnInit {

    username: string;

    email: string;

    public result: any;

    routes: Object[] = [
        {
            title: 'Main.Dashboard',
            description: '',
            icon: 'equalizer',
            route: '/',
        },
        {
            title: 'Main.Media',
            description: 'Images, Videos, and more',
            icon: 'perm_media',
            route: '/media',
        },
        {
            title: 'Main.Playlists',
            description: 'Collections of Stuff',
            icon: 'view_list',
            route: '/playlists',
        },
        /*
        {
            title: 'Main.Campaigns',
            description: 'Sign people use this',
            icon: 'shopping_cart',
            route: '/campaigns',
        },
        */
        {
            title: 'Main.Screens',
            description: 'Sign holders',
            icon: 'collections',
            route: '/screens',
        },
        {
            title: 'Main.Schedule',
            description: 'Sign people use this too',
            icon: 'schedule',
            route: '/schedule',
        },
        {
            title: 'Main.Accounts',
            description: 'Will be visible only to admins',
            icon: 'location_city',
            route: '/accounts',
        },
        {
            title: 'Main.Users',
            description: 'Will be visible only to admins',
            icon: 'group',
            route: '/users',
        },
    ];

    constructor(
        private _router: Router,
        private auth: AuthService,
        private profile: ProfileService,
        private dialog: MatDialog,
        private logger: NGXLogger,
    ) { }

    ngOnInit(): void {
        this.profile.getProfile().subscribe(
            (profile: ProfileSingleton) => {
                this.username = profile.username;
                this.email = profile.email;
                if (profile.hasDeusModus) {
                    this.routes.push({
                        title: 'Deus Modus',
                        description: 'Will be visible only to admins',
                        icon: 'headset_mic',
                        route: '/deusModus',
                    });
                }
            },
            (error: any) => {
                this.logger.error('error:main.component', error);
                this.logout();
            });
    }

    logout(): void {
        this.auth.logout();
        this._router.navigate(['/login']);
    }

    about(): void {
        let dialogRef: MatDialogRef<AboutDialogComponent> = this.dialog.open(AboutDialogComponent);
        dialogRef.afterClosed().subscribe((res: any) => this.result = res);
    }

}
