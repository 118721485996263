import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, ValidatorFn, AbstractControl, FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { NGXLogger } from 'ngx-logger';
import * as moment from 'moment-timezone';

const FORMAT_NO_TIMEZONE: string = 'YYYY-MM-DD[T]HH:mm:ss';

@Component({
    selector: 'gk-duration-component',
    template: `
    <form [formGroup]="localFormGroup">
        <div fxLayout="column">
            <!--
            <div fxLayout="column" fxFlex="20">
                <span> {{monthSlider.value }} months </span>
                <mat-slider #monthSlider tickInterval="1" min="0" max="11" step="1" formControlName="months" color="primary"
                 (change)="changeMonths($event)" (update)="updateMonths($event)" vertical></mat-slider>
            </div>
            <div fxLayout="column" fxFlex="20">
                <span> {{daySlider.value }} days </span>
                <mat-slider #daySlider tickInterval="1" min="0" max="31" step="1" formControlName="days" color="primary"
                (change)="changeDays($event)" (update)="updateDays($event)"  vertical></mat-slider>
            </div>
            -->
            <div fxLayout="row">
                <mat-slider #hourSlider thumbLabel tickInterval="1" min="0" max="23" step="1" formControlName="hours" color="primary"
                (change)="changeHours($event)" (update)="updateHours($event)" fxFlex="80"></mat-slider>
                <span> {{hourSlider.value | padZeros: 2}} hours</span>
            </div>
            <div fxLayout="row">
                <mat-slider #minuteSlider thumbLabel tickInterval="5" min="0" max="59" step="1" formControlName="minutes" color="primary"
                (change)="changeMinutes($event)" (update)="updateMinutes($event)" fxFlex="80"></mat-slider>
                <span> {{minuteSlider.value | padZeros: 2}} minutes</span>
            </div>
            <div fxLayout="row">
                <mat-slider #secondSlider thumbLabel tickInterval="5" min="0" max="59" step="1" formControlName="seconds" color="primary"
                (change)="changeSeconds($event)" (update)="updateSeconds($event)" fxFlex="80"></mat-slider>
                <span> {{secondSlider.value | padZeros: 2}} seconds</span>
            </div>
        </div>
    </form>`,
    styleUrls: ['./datetime-local.component.scss'],
})
export class DurationComponent implements AfterViewInit {

    duration: moment.Duration;

    @Input() parent: FormGroup;

    @Input() controlName: string;

    @Output() durationChangeEvent: EventEmitter<moment.Duration> = new EventEmitter<moment.Duration>();

    localFormGroup: FormGroup = this.fb.group({
        months: 0,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    get parentFormControlValue(): string {
        return this.parent.get(this.controlName).value;
    }

    set parentFormControlValue(value: string) {
        let patch: any = {};
        patch[this.controlName] = value;
        this.parent.patchValue(patch);
    }

    get months(): number {
        return this.localFormGroup.get('months').value;
    }

    set months(months: number) {
        this.localFormGroup.patchValue({
            months: months,
        });
    }

    get days(): number {
        return this.localFormGroup.get('days').value;
    }

    set days(days: number) {
        this.localFormGroup.patchValue({
            days: days,
        });
    }

    get hours(): number {
        return this.localFormGroup.get('hours').value;
    }

    set hours(hours: number) {
        this.localFormGroup.patchValue({
            hours: hours,
        });
    }

    get minutes(): number {
        return this.localFormGroup.get('minutes').value;
    }

    set minutes(minutes: number) {
        this.localFormGroup.patchValue({
            minutes: minutes,
        });
    }

    get seconds(): number {
        return this.localFormGroup.get('seconds').value;
    }

    set seconds(seconds: number) {
        this.localFormGroup.patchValue({
            seconds: seconds,
        });
    }

    constructor(
        private fb: FormBuilder,
        private logger: NGXLogger,
    ) {
    }

    ngAfterViewInit(): void {
        this.parent.get(this.controlName).valueChanges.forEach(
            (newDurationString: string): void => {
                this.refreshDisplay(newDurationString);
            },
        );
        let durationString: string = this.parent.get(this.controlName).value;
        this.refreshDisplay(durationString);
    }

    refreshDisplay(durationString: string): void {
        this.duration = moment.duration(durationString);
        if (this.duration) {
            setTimeout(() => this.localFormGroup.patchValue({
                months: this.duration.months(),
                days: this.duration.days(),
                hours: this.duration.hours(),
                minutes: this.duration.minutes(),
                seconds: this.duration.seconds(),
            }), 0);
        }
    }

    changeMonths($event: any): void {
        // this.logger.log('changeMonths', $event);
        this.update();
        this.writeBack();
    }

    changeDays($event: any): void {
        // this.logger.log('changeDays', $event);
        this.update();
        this.writeBack();
    }

    changeHours($event: any): void {
        // this.logger.log('changeHours', $event);
        this.update();
        this.writeBack();
    }

    changeMinutes($event: any): void {
        // this.logger.log('changeMinutes', $event);
        this.update();
        this.writeBack();
    }

    changeSeconds($event: any): void {
        // this.logger.log('changeSeconds', $event);
        this.update();
        this.writeBack();
    }

    updateMonths($event: any): void {
        this.logger.log('updateMonths', $event);
    }

    updateDays($event: any): void {
        this.logger.log('updateDays', $event);
    }

    updateHours($event: any): void {
        this.logger.log('updateHours', $event);
    }

    updateMinutes($event: any): void {
        this.logger.log('updateMinutes', $event);
    }

    updateSeconds($event: any): void {
        this.logger.log('updateSeconds', $event);
    }

    update(): void {
        this.duration = moment.duration({
            months: this.months,
            days: this.days,
            hours: this.hours,
            minutes: this.minutes,
            seconds: this.seconds,
        });
    }

    writeBack(): void {
        this.parentFormControlValue = this.duration.toISOString();
        this.durationChangeEvent.emit(this.duration);
    }
}
