import { Directive, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Directive({
    selector: '[ngModel][debounce]',
})
export class DebounceDirective implements OnInit {

    private isFirstChange: boolean = true;

    @Output()
    public onDebounce: EventEmitter<any> = new EventEmitter<any>();

    @Input('debounce')
    public debounceTime: number = 500;

    constructor(public model: NgControl) {
    }

    ngOnInit(): void {
        this.model.valueChanges
            .pipe(
                debounceTime(this.debounceTime),
                distinctUntilChanged(),
        )
            .subscribe((modelValue: any) => {
                if (this.isFirstChange) {
                    this.isFirstChange = false;
                } else {
                    this.onDebounce.emit(modelValue);
                }
            });
    }
}
