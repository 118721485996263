import { Component, OnInit, Inject } from '@angular/core';
import { Subscription } from 'rxjs';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { TdLoadingService } from '@covalent/core/loading/covalent-core-loading';
import { OnDestroy } from '@angular/core/src/metadata/lifecycle_hooks';

enum WorkflowState {
    init,
    setData,
    polling,
    pairingError,
    pairingCompleteSuccess,
    pairingCompleteFail,
    pollingError,
}
let LOADING: string = 'LOADING';

@Component({
    selector: 'gk-pairing-dialog',
    templateUrl: './pairing-dialog.component.html',
    styleUrls: ['./pairing-dialog.component.scss'],
})
export class PairingDialogComponent implements OnInit, OnDestroy {
    private _state: WorkflowState;

    public readonly STATE_INIT: WorkflowState = WorkflowState.init;
    public readonly STATE_SET_DATA: WorkflowState = WorkflowState.setData;
    public readonly STATE_POLLING: WorkflowState = WorkflowState.polling;
    public readonly STATE_ERR_PAIRING: WorkflowState = WorkflowState.pairingError;
    public readonly STATE_ERR_POLLING: WorkflowState = WorkflowState.pollingError;
    public readonly STATE_PAIRING_COMPLETE_SUCCESS: WorkflowState = WorkflowState.pairingCompleteSuccess;
    public readonly STATE_PAIRING_COMPLETE_FAIL: WorkflowState = WorkflowState.pairingCompleteFail;

    public readonly NUMBER_RETRIES: number = 20;
    public readonly POLLING_INTERVAL_MSECS: number = 2000;

    public get state(): WorkflowState {
        return this._state;
    }
    public set state(state: WorkflowState) {
        if (this._state !== state) {
            this._state = state;
            this.cancelButtonShown = (this.state === this.STATE_INIT ||
                this.state === this.STATE_POLLING ||
                this.state === this.STATE_PAIRING_COMPLETE_FAIL);
            this.pairButtonShown = (this.state === this.STATE_INIT);
            this.retryButtonShown = (this.state === this.STATE_PAIRING_COMPLETE_FAIL);
            this.okayButtonShown = (this.state !== this.STATE_INIT &&
                this.state !== this.STATE_PAIRING_COMPLETE_FAIL);
            this.okayButtonDisabled = (this.state === this.STATE_INIT || this.state === this.STATE_POLLING);
            this.success = (this.state === this.STATE_PAIRING_COMPLETE_SUCCESS);
        }
    }

    public testStates: any[] = [
        { label: 'init', value: WorkflowState.init },
        { label: 'setData', value: WorkflowState.setData },
        { label: 'polling', value: WorkflowState.polling },
        { label: 'pollingError', value: WorkflowState.pollingError },
        { label: 'pairingError', value: WorkflowState.pairingError },
        { label: 'pairingSuccess', value: WorkflowState.pairingCompleteSuccess },
        { label: 'pairingTimeout', value: WorkflowState.pairingCompleteFail },
    ];

    public success: boolean = false;
    public cancelButtonShown: boolean = false;
    public pairButtonShown: boolean = false;
    public retryButtonShown: boolean = false;
    public okayButtonShown: boolean = true;
    public okayButtonDisabled: boolean = true;
    public hardwareId: string;

    public pollingSubscription: Subscription;
    public pairingSubscription: Subscription;

    constructor(
        public dialogRef: MatDialogRef<PairingDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }

    ngOnInit(): void {
        this.state = WorkflowState.init;

    }

    ngOnDestroy(): void {
        if (!!this.pollingSubscription) {
            this.pollingSubscription.unsubscribe();
            this.pollingSubscription = undefined;
        }
        if (!!this.pairingSubscription) {
            this.pairingSubscription.unsubscribe();
            this.pairingSubscription = undefined;
        }
        if (this.state !== this.STATE_PAIRING_COMPLETE_SUCCESS) { // cleanup if we didn't succeed.
            this.data.pairingService.unpairDevice(this.data.screen.id, this.hardwareId).subscribe();
        }
    }

    onCancelButtonPressed(): void {
        this.dialogRef.close();
    }

    onKeyUp(value: string): void {
        const packed: string = value.replace(/[^0-9a-fA-F]/gi, '').toUpperCase();
        let formatted: string = '';
        for (let i: number = 0; i < packed.length; ++i) {
            formatted += packed[i];
            if (((i + 1) % 4 === 0) && ((i + 1) < packed.length)) {
                formatted += '-';
            }
        }
        this.hardwareId = formatted;
    }

    // PAIRING 1. Register the device and key with the pairing service
    //    This causes two side effects:
    //    a) The hardwareId is recorded in the main database under the screenId
    //    b) A handshake object is created in a global handshake database
    startPairing(): void {
        this.hardwareId = this.hardwareId.toUpperCase();
        this.pairingSubscription = this.data.pairingService.pairDevice(this.data.screen, this.hardwareId).subscribe(
            () => this.pollForDeleted(this.hardwareId, this.NUMBER_RETRIES, this.POLLING_INTERVAL_MSECS),
            (err: any) => this.pairingError(err),
            // () => this.pairingComplete(),
        );
    }

    retryPairing(): void {
        this.pollForDeleted(this.hardwareId, this.NUMBER_RETRIES, this.POLLING_INTERVAL_MSECS);
    }

    private pairingError(err: any): void {
        this.state = WorkflowState.pairingError;
    }

    // private pairingComplete(): void {
    //      this.state = WorkflowState.pairingCompleteSuccess;
    // }

    // PAIRING 2.  Now we poll the handshake database to see evidence that the device has completed the handshake
    private pollForHandshake(hardwareId: string, repetition: number, intervalMSecs: number): void {
        this.state = WorkflowState.polling;
        this.pollingSubscription = this.data.pairingService.pollForHandshake(hardwareId, repetition, intervalMSecs).subscribe(
            (success: boolean) => this.pairingResult(success),
            (err: any) => this.onPairingError(err),
        );
    }

    private pollForDeleted(hardwareId: string, repetition: number, intervalMSecs: number): void {
        this.state = WorkflowState.polling;
        this.pollingSubscription = this.data.pairingService.pollForDeleted(hardwareId, repetition, intervalMSecs).subscribe(
            (success: boolean) => this.pairingResult(success),
            (err: any) => this.onPairingError(err),
        );
    }

    private onPairingError(err: any): void {
        this.state = WorkflowState.pollingError;
    }

    // PAIRING 3. Handle the result
    private pairingResult(success: boolean): void {
        if (success) {
            this.state = WorkflowState.pairingCompleteSuccess;
            // console.log('onPairingStarted = true, device has paired');
        } else {
            this.state = WorkflowState.pairingCompleteFail;
            // console.log('onPairingStarted = false, network was fine, but no device pairing detected');
        }
        if (!!this.pollingSubscription) {
            this.pollingSubscription = undefined;
        }
        if (!!this.pairingSubscription) {
            this.pairingSubscription = undefined;
        }
    }

}
