import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'elapsedTime',
})
export class ElapsedTimePipe implements PipeTransform {

    transform(totalSeconds: number, args?: any): string {
        let hours: number = Math.floor(totalSeconds / 3600);
        let remainingSeconds: number = totalSeconds % 3600;
        let minutes: number = Math.floor(remainingSeconds / 60);
        let seconds: number = Math.floor(remainingSeconds % 60);

        let strHours: string = '' + (hours === 0 ? '' : hours + ':');  // "" or "07:"
        let strMinutes: string = '' + (minutes < 10 ? '0' : '') + minutes + ':'; // "00:" -> "59:"
        let strSeconds: string = '' + (seconds < 10 ? '0' : '') + seconds; // "00" -> "59"

        return strHours + strMinutes + strSeconds;
    }

}
