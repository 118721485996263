export let single: any = [
    {
        'name': 'Germany',
        'value': 8940000,
    },
    {
        'name': 'USA',
        'value': 5000000,
    },
    {
        'name': 'France',
        'value': 7200000,
    },
];

export let multi: any = [
    {
        'name': 'Container Apps',
        'series': [
            {
                'value': 2469,
                'name': '2016-09-15T19:25:07.773Z',
            },
            {
                'value': 3619,
                'name': '2016-09-17T17:16:53.279Z',
            },
            {
                'value': 3885,
                'name': '2016-09-15T10:34:32.344Z',
            },
            {
                'value': 4289,
                'name': '2016-09-19T14:33:45.710Z',
            },
            {
                'value': 3309,
                'name': '2016-09-12T18:48:58.925Z',
            },
        ],
    },
    {
        'name': 'Alerting Dashboard',
        'series': [
            {
                'value': 2452,
                'name': '2016-09-15T19:25:07.773Z',
            },
            {
                'value': 4938,
                'name': '2016-09-17T17:16:53.279Z',
            },
            {
                'value': 4110,
                'name': '2016-09-15T10:34:32.344Z',
            },
            {
                'value': 3828,
                'name': '2016-09-19T14:33:45.710Z',
            },
            {
                'value': 5772,
                'name': '2016-09-12T18:48:58.925Z',
            },
        ],
    },
    {
        'name': 'Streaming Data',
        'series': [
            {
                'value': 4022,
                'name': '2016-09-15T19:25:07.773Z',
            },
            {
                'value': 2345,
                'name': '2016-09-17T17:16:53.279Z',
            },
            {
                'value': 5148,
                'name': '2016-09-15T10:34:32.344Z',
            },
            {
                'value': 6868,
                'name': '2016-09-19T14:33:45.710Z',
            },
            {
                'value': 5415,
                'name': '2016-09-12T18:48:58.925Z',
            },
        ],
    },
    {
        'name': 'Database Queries',
        'series': [
            {
                'value': 6194,
                'name': '2016-09-15T19:25:07.773Z',
            },
            {
                'value': 6585,
                'name': '2016-09-17T17:16:53.279Z',
            },
            {
                'value': 6857,
                'name': '2016-09-15T10:34:32.344Z',
            },
            {
                'value': 2545,
                'name': '2016-09-19T14:33:45.710Z',
            },
            {
                'value': 5986,
                'name': '2016-09-12T18:48:58.925Z',
            },
        ],
    },
    {
        'name': 'NoSQL Database',
        'series': [
            {
                'value': 4260,
                'name': '2016-09-15T19:25:07.773Z',
            },
            {
                'value': 4810,
                'name': '2016-09-17T17:16:53.279Z',
            },
            {
                'value': 5087,
                'name': '2016-09-15T10:34:32.344Z',
            },
            {
                'value': 5941,
                'name': '2016-09-19T14:33:45.710Z',
            },
            {
                'value': 6427,
                'name': '2016-09-12T18:48:58.925Z',
            },
        ],
    },
];


export let impressions_per_second: any = [
    {
        "name": "Impressions Per Second",
        "series": [
            { 'name': 1, 'value': 1075 },
            { 'name': 2, 'value': 1187 },
            { 'name': 3, 'value': 1099 },
            { 'name': 4, 'value': 1231 },
            { 'name': 5, 'value': 1200 },
            { 'name': 6, 'value': 1230 },
            { 'name': 7, 'value': 1328 },
            { 'name': 8, 'value': 1258 },
            { 'name': 9, 'value': 1409 },
            { 'name': 10, 'value': 1364 },
            { 'name': 11, 'value': 1432 },
            { 'name': 12, 'value': 1511 },
            { 'name': 13, 'value': 1464 },
            { 'name': 14, 'value': 1623 },
            { 'name': 15, 'value': 1567 },
            { 'name': 16, 'value': 1669 },
            { 'name': 17, 'value': 1724 },
            { 'name': 18, 'value': 1704 },
            { 'name': 19, 'value': 1860 },
            { 'name': 20, 'value': 1797 },
            { 'name': 21, 'value': 1927 },
            { 'name': 22, 'value': 1954 },
            { 'name': 23, 'value': 1962 },
            { 'name': 24, 'value': 2106 },
            { 'name': 25, 'value': 2041 },
            { 'name': 26, 'value': 2189 },
            { 'name': 27, 'value': 2187 },
            { 'name': 28, 'value': 2223 },
            { 'name': 29, 'value': 2344 },
            { 'name': 30, 'value': 2282 },
            { 'name': 31, 'value': 2440 },
            { 'name': 32, 'value': 2408 },
            { 'name': 33, 'value': 2471 },
            { 'name': 34, 'value': 2561 },
            { 'name': 35, 'value': 2506 },
            { 'name': 36, 'value': 2662 },
            { 'name': 37, 'value': 2604 },
            { 'name': 38, 'value': 2689 },
            { 'name': 39, 'value': 2742 },
            { 'name': 40, 'value': 2699 },
            { 'name': 41, 'value': 2843 },
            { 'name': 42, 'value': 2763 },
            { 'name': 43, 'value': 2864 },
            { 'name': 44, 'value': 2877 },
            { 'name': 45, 'value': 2850 },
            { 'name': 46, 'value': 2970 },
            { 'name': 47, 'value': 2875 },
            { 'name': 48, 'value': 2985 },
            { 'name': 49, 'value': 2958 },
            { 'name': 50, 'value': 2947 },
            { 'name': 51, 'value': 3037 },
            { 'name': 52, 'value': 2932 },
            { 'name': 53, 'value': 3045 },
            { 'name': 54, 'value': 2978 },
            { 'name': 55, 'value': 2986 },
            { 'name': 56, 'value': 3039 },
            { 'name': 57, 'value': 2932 },
            { 'name': 58, 'value': 3039 },
            { 'name': 59, 'value': 2939 },
            { 'name': 60, 'value': 2964 },
            { 'name': 61, 'value': 2976 },
            { 'name': 62, 'value': 2875 },
            { 'name': 63, 'value': 2969 },
            { 'name': 64, 'value': 2841 },
            { 'name': 65, 'value': 2881 },
            { 'name': 66, 'value': 2852 },
            { 'name': 67, 'value': 2764 },
            { 'name': 68, 'value': 2837 },
            { 'name': 69, 'value': 2691 },
            { 'name': 70, 'value': 2744 },
            { 'name': 71, 'value': 2675 },
            { 'name': 72, 'value': 2605 },
            { 'name': 73, 'value': 2653 },
            { 'name': 74, 'value': 2498 },
            { 'name': 75, 'value': 2560 },
            { 'name': 76, 'value': 2456 },
            { 'name': 77, 'value': 2409 },
            { 'name': 78, 'value': 2428 },
            { 'name': 79, 'value': 2275 },
            { 'name': 80, 'value': 2341 },
            { 'name': 81, 'value': 2209 },
            { 'name': 82, 'value': 2188 },
            { 'name': 83, 'value': 2176 },
            { 'name': 84, 'value': 2036 },
            { 'name': 85, 'value': 2100 },
            { 'name': 86, 'value': 1949 },
            { 'name': 87, 'value': 1955 },
            { 'name': 88, 'value': 1913 },
            { 'name': 89, 'value': 1794 },
            { 'name': 90, 'value': 1853 },
            { 'name': 91, 'value': 1692 },
            { 'name': 92, 'value': 1724 },
            { 'name': 93, 'value': 1654 },
            { 'name': 94, 'value': 1566 },
            { 'name': 95, 'value': 1614 },
            { 'name': 96, 'value': 1455 },
            { 'name': 97, 'value': 1511 },
            { 'name': 98, 'value': 1418 },
            { 'name': 99, 'value': 1365 },
            { 'name': 100, 'value': 1087 },


        ]
    }
];

export let campaigns_by_age: any[] = [
    {
        "name": "Nike",
        "series": [
            { 'name': "12 – 17", 'value': 4500 },
            { 'name': "18 – 24", 'value': 900 },
            { 'name': "25 – 34", 'value': 345 },
            { 'name': "35 – 44", 'value': 2564 },
            { 'name': "45 – 54", 'value': 0 },
            { 'name': "55 – 64", 'value': 0 },
            { 'name': "65+", 'value': 0 }
        ]
    },
    {
        "name": "Apple",
        "series": [
            { 'name': "12 – 17", 'value': 345 },
            { 'name': "18 – 24", 'value': 2725 },
            { 'name': "25 – 34", 'value': 345 },
            { 'name': "35 – 44", 'value': 3453 },
            { 'name': "45 – 54", 'value': 234 },
            { 'name': "55 – 64", 'value': 54 },
            { 'name': "65+", 'value': 264 }
        ]
    },
    {
        "name": "Target",
        "series": [
            { 'name': "12 – 17", 'value': 234 },
            { 'name': "18 – 24", 'value': 2456 },
            { 'name': "25 – 34", 'value': 234 },
            { 'name': "35 – 44", 'value': 34 },
            { 'name': "45 – 54", 'value': 555 },
            { 'name': "55 – 64", 'value': 123 },
            { 'name': "65+", 'value': 1323 }
        ]
    },

    {
        "name": "Chanel",
        "series": [
            { 'name': "12 – 17", 'value': 0 },
            { 'name': "18 – 24", 'value': 0 },
            { 'name': "25 – 34", 'value': 3410 },
            { 'name': "35 – 44", 'value': 3253 },
            { 'name': "45 – 54", 'value': 0 },
            { 'name': "55 – 64", 'value': 0 },
            { 'name': "65+", 'value': 0 }
        ]
    },
    {
        "name": "Nintendo",
        "series": [

            { 'name': "12 – 17", 'value': 237 },
            { 'name': "18 – 24", 'value': 23 },
            { 'name': "25 – 34", 'value': 510 },
            { 'name': "35 – 44", 'value': 6 },
            { 'name': "45 – 54", 'value': 12 },
            { 'name': "55 – 64", 'value': 24 },
            { 'name': "65+", 'value': 23 }
        ]
    },
];

export let gender_demographics: any[] = [
    
            { 'name': 'Male', 'value': 23423 },
            { 'name': 'Female', 'value': 33289 },
            { 'name': 'Not Specified', 'value':380 }
            
];
export let age_demographics: any[] = [
    
            { 'name': "12 – 17", 'value': 2373 },
            { 'name': "18 – 24", 'value': 232 },
            { 'name': "25 – 34", 'value': 5120 },
            { 'name': "35 – 44", 'value': 9362 },
            { 'name': "45 – 54", 'value': 12192 },
            { 'name': "55 – 64", 'value': 242 },
            { 'name': "65+", 'value': 223 }
            
];
/*
      {
        "name": "Age",
        "series": [
        ]
    }
];
*/