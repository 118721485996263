
import { Injectable, Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router, CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NgForm, NgModel } from '@angular/forms';

import { MatSnackBar, MatDialog, MatDialogRef } from '@angular/material';

import { Observable } from 'rxjs';

import { TdMediaService, StepState, IStepChangeEvent, TdLoadingService, LoadingType, LoadingMode, TdBytesPipe } from '@covalent/core';

import { NGXLogger } from 'ngx-logger';

import { BaseEditor } from '../../base/base-editor';
import { FileUploadInfo } from './file-upload-info';
import { IUrl } from '../../models/url.model';
import { IRemoteReference } from '../../models/data-array.model';
import { UrlService, AwsS3Service } from '../../services';
import { L10nService } from '../../l10n/l10n.service';
import { LeaveWithoutSaveDialogComponent } from '../../dialogs/leave-without-save-dialog.component';

import { ProfileService, ProfileSingleton } from '../../auth/profile.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class CanDeactivateMediaUrlEditor implements CanDeactivate<MediaUrlEditorComponent> {
    constructor(private dialog: MatDialog) { }

    canDeactivate(
        component: MediaUrlEditorComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot,
    ): Observable<boolean> | Promise<boolean> | boolean {

        if (component.dirty) {
            let dialogRef: MatDialogRef<LeaveWithoutSaveDialogComponent> = this.dialog.open(LeaveWithoutSaveDialogComponent);
            return dialogRef.afterClosed();
        } else {
            return true;
        }
    }
}

@Component({
    selector: 'gk-media-url-editor',
    templateUrl: './media-url-editor.component.html',
    styleUrls: ['./media-url-editor.component.scss'],
})
export class MediaUrlEditorComponent extends BaseEditor implements OnInit, AfterViewInit {

    private readonly spinnerName: string = 'overlayLoadingSpinner';

    @ViewChild('imgElement') imgElement: ElementRef;
    @ViewChild('myForm') myForm: NgForm;
    @ViewChild('urlTitleControl') urlTitleControl: NgModel;

    // Determines if component is creating a new url, or editing existing one
    //  == 'add' for new urls
    //  == url.id for editing existing urls.
    //  @see ngOnInit()
    action: string;
    cardTitle: string;

    //
    // url model
    //
    url: IUrl;

    //
    // Form data, matches attributes of a url model
    //
    id: string;  // id of the url object
    title: string;  // title of the
    description: string;
    src: string;

    //
    // BaseEditor overrides
    //

    // true iff the editor has changed anything that needs saving
    get dirty(): boolean {
        return this.myForm.dirty;
    }

    // true iff the editor is in a valid state (pristine or dirty)
    get valid(): boolean {
        return this.myForm.valid;
    }

    constructor(
        private urlService: UrlService,
        private route: ActivatedRoute,
        public media: TdMediaService,
        protected router: Router,
        protected dialog: MatDialog,
        protected snackBar: MatSnackBar,
        protected loadingService: TdLoadingService,
        protected logger: NGXLogger,
    ) {
        super(router, dialog, snackBar, loadingService, logger);
        this.backPath = ['/media'];
    }

    //
    // establishes this.action, which remains constant for duration of view
    // Also loads url data for form (if appropriate).
    //
    ngOnInit(): void {
        this.route.url.subscribe(
            (url: any) => {
                this.logger.log('urlFormComponent.ngOnInit: url=', url);
                this.action = (url.length > 1 ? url[1].path : 'add');
                if (this.action === 'add') {
                    this.cardTitle = 'Media.UrlEditor.CardTitle.Create';
                } else {
                    this.id = url[1].path;
                    this.cardTitle = 'Media.UrlEditor.CardTitle.Modify';
                    this.loadData();
                }
            },
            (err: any) => {
                this.logger.warn('MediaUrlEditor.onInit()', err);
                this.snackBar.open('Routing Error', 'Ok');
            });
    }

    ngAfterViewInit(): void {
        // broadcast to all listener observables when loading the page
        this.media.broadcast();
    }

    //
    // These are the two main workflows
    //
    get isNewUrlWorkflow(): boolean {
        return this.action === 'add';
    }

    get isEditUrlWorkflow(): boolean {
        return this.action.indexOf('url_') === 0;
    }

    onLoadedMetadataEvent(data: any): void {
        this.logger.log(data);
    }

    onUserClickedSave(): void {
        this.logger.log('title=', this.title);
        // this.isSaving = true;
        this.myForm.control.markAsPending();
        this.showSpinner();

        let url: IUrl = <IUrl>{
            id: this.id,
            title: this.title,
            src: this.src,
            description: this.description,
        };
        if (this.action === 'add') {
            this.urlService.create(url).subscribe(
                (urlRef: IRemoteReference<IUrl>) => {
                    this.id = urlRef.id;
                    Object.keys(this.myForm.controls).forEach((control: string) => {
                        this.myForm.controls[control].markAsPristine();
                    });
                    this.goBack();
                },
                (err: any) => {
                    this.logger.warn('UrlService.create()', err);
                    this.myForm.control.markAsDirty();
                    this.showError(err);
                },
            );
        } else {
            this.urlService.update(url).subscribe(
                (data: void) => {
                    this.myForm.control.markAsPristine();
                    this.goBack();
                },
                (err: any) => {
                    this.logger.warn('UrlService.create()', err);
                    this.myForm.control.markAsDirty();
                    this.showError(err);
                },
            );
        }
    }

    onUserClickedCancel(): void {
        this.goBack();
    }

    private loadData(): void {
        this.loadingService.register(this.spinnerName);
        this.urlService.getById(this.id).subscribe(
            (url: IUrl) => {
                this.title = url.title;
                this.src = url.src;
                this.description = url.description;
                this.loadingService.resolve(this.spinnerName);
            },
            (err: any) => {
                this.logger.log('Error: UrlForm.loadData: Url ID not found.');
                this.goBack();
            },
        );
    }
}
