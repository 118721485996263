import { Pipe, PipeTransform } from '@angular/core';
/*
 * Count the number of bytes in a UTF8 string
 * Usage:
 *   value | utf8bytes
*/
@Pipe({ name: 'utf8bytes' })
export class UTF8Bytes implements PipeTransform {
    transform(value: string): number {
        let accumulator: number = 0;
        let char: number;
        for (let i: number = 0; i < value.length; ++i) {
            char = value.charCodeAt(i);
            accumulator += 1 + (char > 127 ? 1 : 0) + (char > 2047 ? 1 : 0);
        }
        return accumulator;
    }
}
